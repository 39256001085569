import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import NoteIcon from '@assets/icons/single-note-square.svg';

import { StyledCreditItem } from './CreditSelectorListItemStyles';

function CreditSelectorListItem({
  credit,
  disabled,
  ...restProps
}) {
  // TODO: LIKELY ADD USE EFFECT TO GET AN ICON FROM DICTIONARY

  return (
    <StyledCreditItem {...restProps} disabled={disabled}>
      <Box>
        <Icon pt="4px" mr="0.75rem" size="1.25rem"><NoteIcon /></Icon>
      </Box>
      <Text color="var(--text-primary)" variant="selectDisabled">{credit.displayName}</Text>
    </StyledCreditItem>
  );
}

CreditSelectorListItem.propTypes = {
  credit: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

CreditSelectorListItem.defaultProps = {
  disabled: false,
};

export default CreditSelectorListItem;
