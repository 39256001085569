/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useCallback, useState, useRef, useEffect,
} from 'preact/hooks';
import { Fragment } from 'preact';
import { QRCodeSVG } from 'qrcode.react';
import AppState from '@state';

import { getArtistRecentCollaborators } from '@api/restricted/artist-collaborators-api';
import { getArtistLookupWithEmail } from '@api/restricted/artist-lookup-api';
import { postWorkspaceAccount } from '@api/restricted/workspace-account-api';
import { postWorkspaceAccountInviteJoinToken } from '@api/restricted/workspace-account-invite-api';

import useDebounce from '@hooks/useDebounce';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';
import BaseButton from '@ui-kit/buttons/baseButton';
import Spinner from '@ui-kit/loaders/Spinner';
import BreakLine from '@ui-kit/dividers/breakLine';
import Avatar from '@ui-kit/avatar';
import AlertMessage from '@ui-kit/alert/Alert';
import MailWedge from '@animations/wedges/MailWedge';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import LinkButton from '@ui-kit/buttons/linkButton';
import IconQR from '@assets/icons/qr-outline.svg';
import Icon from '@ui-kit/icon';
import BaseUserCard from '@distinct-components/cards/baseUserCard';
import EmptyStateList from '@distinct-components/feedback/emptyStateList/EmptyStateList';

import { SOCIETIES } from '@constants/collectionSocieties';

import {
  StyledPercentageInput,
  StyledPercentBox,
  RecentWriterWrapper,
  QrWrapper,
  QrControlItem,
  QrButton,
} from './AddWriterFormStyles';

const ipiRegex = /^[0-9]{9,11}$/;

function AddWriterForm({
  workspaceId,
  activeUser,
  handleCloseFunction,
  ...restProps
}) {
  const [recentWriters, setRecentWriters] = useState([]);
  const [recentWriterQuery, setRecentWriterQuery] = useState('');
  const [recentWritersLimit, setRecentWritersLimit] = useState(3);
  const [showAllRecent, setShowAllRecent] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState();
  const [existingUser, setExistingUser] = useState(null);
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');

  const [writerContribution, setWriterContribution] = useState('Music and Lyrics');
  const [writerShare, setWriterShare] = useState(0);
  const [writerPermission, setWriterPermission] = useState('edit');
  const [suggestedSociety, setSuggestedSociety] = useState(null);
  const [suggestedIPI, setSuggestedIPI] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [qrCodeLink, setQrCodeLink] = useState(null);
  const [showRecentContributionContext, setShowRecentContributionContext] = useState(false);
  const [showRecentShareContext, setShowRecentShareContext] = useState(false);

  const searchRef = useRef(null);
  const inputIPI = useRef(null);

  const { width } = useWindowDimensions();

  const useToastAlert = (message, type) => {
    AppState.messages.toastMessage.value = { text: message, variant: type };
  };

  const WriterSocieties = SOCIETIES.filter((s) => s.societyType === 'pro');
  const getSocietyByName = (societyName) => {
    if (!societyName) {
      return null;
    }
    const found = WriterSocieties.find((s) => s.name === societyName);
    return found ? ({
      name: found.name,
      country: found.country,
      type: found.societyType,
    }) : null;
  };

  const loadAccountRecentWriters = async () => {
    try {
      const response = await getArtistRecentCollaborators(workspaceId, 50);
      if (response.status !== 200) {
        setRecentWriters([]);
      } else {
        const json = await response.json();
        setRecentWriters(json.recentCollaborators);
      }
    } catch (err) {
      setRecentWriters([]);
    }
  };

  const handleGetQrCodeLink = (isAdmin, isWriter, canEdit) => {
    postWorkspaceAccountInviteJoinToken(workspaceId, isAdmin, isWriter, canEdit)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setQrCodeLink(json.url);
          });
        } else {
          response.json()
            .then((json) => useToastAlert(json.error || response.statusText, 'error'))
            .catch(() => useToastAlert(response.statusText, 'error'));
        }
      })
      .catch((err) => {
        useToastAlert(err.message, 'error');
      });
  };

  useEffect(() => {
    loadAccountRecentWriters();
  }, []);

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleLookupApiCall = (email) => {
    getArtistLookupWithEmail(email, -1, -1)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setError('');
            const existing = json.map((user) => ({
              id: user.accountId,
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              imgURL: user.imgURL,
              isVerified: user.isVerified,
              email: user.email,
            }));
            setExistingUser(existing[0] || null);
            setIsSearching(false);
          });
        } else if (response.status === 202) {
          setExistingUser(null);
          setSuggestedSociety(null);
          setSuggestedIPI(null);
          setIsSearching(false);
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsSearching(false);
        }
        setGuestFirstName('');
        setGuestLastName('');
      })
      .catch((err) => {
        setError(err.message);
        setIsSearching(false);
      });
  };

  const handleChangeEmailToInvite = (event) => {
    event.persist();
    const userInput = event.target.value;

    setExistingUser(null);
    setGuestFirstName('');
    setGuestLastName('');
    setWriterContribution('Music and Lyrics');
    setWriterShare(0);
    setWriterPermission('edit');
    setSuggestedSociety(null);
    setSuggestedIPI('');

    if (validateEmail(userInput) != null) {
      setIsSearching(true);
      setEmailToInvite(userInput.toLowerCase());
      handleLookupApiCall(userInput.toLowerCase());
    } else {
      setEmailToInvite(null);
    }
  };

  const optimizedFn = useCallback(useDebounce(handleChangeEmailToInvite), []);

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ');
  };

  const onClickContinue = () => {
    setError('');
    if (suggestedIPI && (!ipiRegex.test(suggestedIPI))) {
      setError('IPI number must be 9-11 digits');
      return;
    }
    const societyData = getSocietyByName(suggestedSociety);
    if (suggestedSociety && !societyData) {
      setError('Society not found');
      return;
    }

    const cleanedGuestFirstName = cleanName(guestFirstName);
    const cleanedGuestLastName = cleanName(guestLastName);

    if (!existingUser && (!cleanedGuestFirstName || !cleanedGuestLastName)) {
      setError('Guest writer needs a first and last name');
      return;
    }

    if (writerShare < 1 || writerShare == null || Number.isNaN(writerShare) || writerShare > 100) {
      setError('Writer\'s share must be between 1 and 100%');
      return;
    }

    const accountToAdd = existingUser ? {
      id: existingUser.id,
    } : {
      email: emailToInvite,
    };

    const metadata = {
      share: writerShare,
      contribution: writerContribution,
    };

    const suggestions = existingUser ? {} : {
      ...(suggestedIPI && { ipi: suggestedIPI }),
      ...(societyData && { society: societyData }),
    };

    setIsSaving(true);

    postWorkspaceAccount(
      workspaceId,
      accountToAdd,
      existingUser ? null : cleanedGuestFirstName,
      existingUser ? null : cleanedGuestLastName,
      writerPermission,
      metadata,
      suggestions,
    )
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setError('');
            setIsSaving(false);
            handleCloseFunction();
          }, 2000);
        } else {
          response.json()
            .then((json) => setError(json.error ? json.error : response.statusText))
            .catch(() => setError(response.statusText));
          setIsSaving(false);
        }
      })
      .catch((err) => {
        setIsSaving(false);
        setError(err.message);
      });
  };

  const onClickViewAllWriters = () => {
    setRecentWriterQuery('');
    if (recentWritersLimit > 3) {
      setRecentWritersLimit(3);
    } else {
      setRecentWritersLimit(50);
    }
    setShowAllRecent(!showAllRecent);
  };

  const onClickRecentWriter = (writer) => {
    setShowRecentShareContext(true);
    setShowRecentContributionContext(true);
    setRecentWriterQuery('');
    setShowAllRecent(false);
    setEmailToInvite(writer.email);
    if (writer.id) {
      setExistingUser(writer);
    } else {
      setExistingUser(null);
      setGuestFirstName(writer.firstName);
      setGuestLastName(writer.lastName);
    }
    setWriterContribution(writer.contribution);
    setWriterShare(writer.share);
    setWriterPermission(writer.permission);
  };

  const handleQrPermissionChange = (event) => {
    const selection = event.target.value;
    if (selection === 'Admin') {
      handleGetQrCodeLink(true, true, true);
    } else if (selection === 'Edit') {
      handleGetQrCodeLink(false, true, true);
    } else if (selection === 'Restricted') {
      handleGetQrCodeLink(false, true, false);
    } else {
      useToastAlert('Invalid Permission Chosen', 'error');
    }
  };

  return (
    <Box {...restProps}>
      <ConversationPrompt
        primaryMessage="Add a Writer"
        promptLabel="Ownership"
        mb="1em"
      />
      <Box>
        {!qrCodeLink && (
          <Fragment>
            <InputLabel label="Add Writer by Email" />
            <Box display="flex" flexDirection="row-reverse" alignItems="center" mb="1.5em" position="relative">
              <BaseInput
                ref={searchRef}
                id="userTypeAhead"
                name="userTypeAhead"
                placeholder="songwriter@email.com"
                onChange={optimizedFn}
                value={emailToInvite}
                type="email"
                fluid
                required
                autoComplete="off"
              />
              <Box style={{ position: 'absolute', right: 16 }}>
                {(isSearching) && <Spinner variant="fieldLoader" size="1.125em" />}
              </Box>
            </Box>
          </Fragment>
        )}

        {recentWriters.length > 0 && !emailToInvite && !qrCodeLink
          && (
            <Fragment>
              <InputLabel label="Add a Recent Writer" />
              {showAllRecent && (
                <BaseInput
                  id="recentQuery"
                  name="recentQuery"
                  placeholder="Search Recent..."
                  onChange={(e) => setRecentWriterQuery(e.target.value)}
                  type="text"
                  fluid
                  autoComplete="off"
                  mb="0.75rem"
                />
              )}
              <RecentWriterWrapper>
                {recentWriterQuery === ''
                  ? (
                    <Fragment>
                      {recentWriters.slice(0, recentWritersLimit).map((writer) => (
                        <BaseUserCard user={writer} onClickCard={onClickRecentWriter} />
                      ))}
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      {recentWriters
                        .filter((writer) => (writer.firstName + writer.lastName).toLowerCase().includes(recentWriterQuery.toLowerCase())).slice(0, recentWritersLimit).map((writer) => (
                          <BaseUserCard user={writer} onClickCard={onClickRecentWriter} />
                        ))}
                    </Fragment>
                  )}
              </RecentWriterWrapper>

              {recentWriters.filter((writer) => (writer.firstName + writer.lastName).toLowerCase().includes(recentWriterQuery.toLowerCase())).length === 0 && (
                <EmptyStateList mb="0.875rem" message="No Writers Match that Name" />
              )}

              {(recentWriters.length > recentWritersLimit || showAllRecent)
                && (
                  <Box display="flex" justifyContent="flex-end">
                    <LinkButton btnText={showAllRecent ? 'View Less' : 'View More'} variant="small" color="var(--text-primary)" onClick={onClickViewAllWriters} />
                  </Box>
                )}
            </Fragment>
          )}

        {!emailToInvite
          && (
            <Box mt="1.25rem">
              <InputLabel label="Add Writer by QR Code" />

              {!qrCodeLink
                ? (
                  <QrButton onClick={() => handleGetQrCodeLink(false, true, true)}>
                    <Icon size="1.65rem" mr="0.875rem" pt="2px" color="var(--blue-400)">
                      <IconQR />
                    </Icon>
                    <Text>Generate QR Code</Text>
                  </QrButton>
                )
                : (
                  <Fragment>
                    <BreakLine mb="1.5em" mt="0.25rem" color="var(--panel-info-border)" />
                    <QrWrapper>
                      <QRCodeSVG
                        size={width > 780 ? '420px' : '320px'}
                        fgColor="#fcfcfc"
                        bgColor="#222222"
                        level="L"
                        value={qrCodeLink}
                      />
                      <QrControlItem>
                        <InputLabel fontSize="0.688rem" label="QR Code Permissions" />
                        <BaseSelect
                          fluid
                          variant="small"
                          mb="0.25rem"
                          defaultValue="Edit"
                          onChange={(event) => handleQrPermissionChange(event)}
                        >
                          <option value="Restricted">View Only</option>
                          <option value="Edit" default selected>Can Edit</option>
                          <option value="Admin">Moderator</option>
                        </BaseSelect>
                      </QrControlItem>
                      <Box display="flex" justifyContent="center">
                        <LinkButton btnText="Go Back" variant="small" color="var(--text-primary)" onClick={() => setQrCodeLink(null)} />
                      </Box>
                    </QrWrapper>
                  </Fragment>
                )}
            </Box>
          )}

      </Box>

      {(emailToInvite && !isSearching) && (
        <Fragment>
          {!existingUser?.id ? (
            <Fragment>
              <Box display="flex" mb="1.5em" alignItems="center">
                <Box mr="0.75em">
                  <MailWedge />
                </Box>
                <Box>
                  <Text fontWeight="600" fontSize="0.875rem">
                    Guest Writer
                  </Text>
                  {/* ONCE WE GET THINGS WIRED. ADD TEXT THAT EXPLAINS KNOWN GUEST VS NEW GUEST */}
                  <Text fontSize="0.813rem">They can join or just sign the split sheet.</Text>
                </Box>
              </Box>
              <BreakLine mb="1.5em" mt="1em" color="var(--panel-info-border)" />
              {!existingUser?.firstName && (
                <Box display="flex" mb="1.5em" alignItems="center">
                  <Box width="100%" mr="1.125rem">
                    <InputLabel label="First Name" />
                    <BaseInput
                      ref={inputIPI}
                      id="firstName"
                      name="firstName"
                      onChange={(e) => setGuestFirstName(e.target.value)}
                      value={guestFirstName}
                      fluid
                      type="text"
                      autoComplete="off"
                    />
                  </Box>
                  <Box width="100%">
                    <InputLabel label="Last Name" />
                    <BaseInput
                      ref={inputIPI}
                      id="lastName"
                      name="lastName"
                      onChange={(e) => setGuestLastName(e.target.value)}
                      value={guestLastName}
                      fluid
                      type="text"
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              )}
            </Fragment>
          ) : (
            <Box display="flex" mb="1.5em" alignItems="center">
              <Box mr="0.75em">
                <Avatar size="3em" mb="1em" imgURL={existingUser.imgURL} isVerified={existingUser.isVerified} checkSize="1em" checkY="4px" checkX="-2px" />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.75rem">
                  Registered User
                </Text>
                <Text>
                  {existingUser.firstName}
                  {' '}
                  {existingUser.lastName}
                </Text>
              </Box>
            </Box>
          )}

          <Box>
            <Fragment>
              <InputLabel label="Contribution to Composition" />
              <BaseSelect
                onChange={(e) => {
                  setWriterContribution(e.target.value);
                  setShowRecentContributionContext(false);
                }}
                value={writerContribution}
                fluid
                mb="0.25em"
              >
                <option value="Music and Lyrics" default selected>Music and Lyrics</option>
                <option value="Lyrics">Lyrics</option>
                <option value="Music">Music</option>
                <option value="Arrangement">Arrangement</option>
                <option value="Translation">Translation</option>
              </BaseSelect>
              {showRecentContributionContext
                ? <InputLabel mt="4px" mb="1.5rem" color="var(--text-medium-mute)" label="Based on Your Last Collaboration" />
                : <InputLabel mt="4px" mb="1.5em" color="var(--text-medium-mute)" label="Production / Beats is Considered Music" />}

              <InputLabel label="Ownership Share" />
              <Box display="flex" mb="0.25em">
                <StyledPercentageInput
                  onChange={(e) => {
                    setWriterShare(parseFloat(e.target.value));
                    setShowRecentShareContext(false);
                  }}
                  onFocus={(e) => e.target.select()}
                  value={writerShare}
                  id="share"
                  name="share"
                  placeholder={0.00}
                  type="number"
                  inputMode="decimal"
                  step="0.01"
                  max={100}
                  min={0.01}
                />
                <StyledPercentBox>%</StyledPercentBox>
              </Box>
              {showRecentShareContext
                ? <InputLabel mt="4px" mb="1.5rem" color="var(--text-medium-mute)" label="Based on Your Last Collaboration" />
                : <InputLabel mt="4px" mb="1.5rem" color="var(--text-medium-mute)" label="They can modify this if you give permission below" />}

              <InputLabel label="What can the writer edit?" />
              <BaseSelect
                onChange={(e) => setWriterPermission(e.target.value)}
                fluid
                mb="0.25em"
              >
                {[
                  { value: 'edit', text: 'Their Split, Lyrics, Files' },
                  { value: 'admin', text: 'Full Edit Permissions' },
                  { value: 'restricted', text: 'View & Sign Only' },
                ].map((o) => (
                  <option value={o.value} default={o.value === writerPermission} selected={o.value === writerPermission}>{o.text}</option>
                ))}
              </BaseSelect>
              <InputLabel mt="4px" mb="1.5rem" color="var(--text-medium-mute)" label="Only applies if they join to collaborate" />

            </Fragment>
          </Box>

          {!existingUser && (
            <Box>
              <BreakLine mb="1.5em" mt="1em" color="var(--panel-info-border)" />
              <Text mb="1.5em">
                Add the writer&apos;s society and IPI number if known.
              </Text>

              <BaseTypeAhead
                label="Writer Collection Society (Optional)"
                id="societies"
                data={WriterSocieties}
                nameValue="name"
                handleChange={(data) => setSuggestedSociety(data)}
                initialValue={suggestedSociety || ''}
              />

              <Fragment>
                <InputLabel label="Writer Name IPI (Optional)" />
                <BaseInput
                  ref={inputIPI}
                  id="ipi"
                  name="ipi"
                  placeholder="Enter 9-11 Digit IPI"
                  defaultValue={suggestedIPI || ''}
                  onChange={(e) => setSuggestedIPI(e.target.value)}
                  pattern="[0-9]+"
                  maxLength="11"
                  minLength="9"
                  fluid
                  type="text"
                  inputMode="decimal"
                  mb="1.5em"
                  autoComplete="off"
                />
              </Fragment>

            </Box>
          )}

          <Fragment>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />

            {error && (
              <AlertMessage
                variant="negative"
                message={error}
                mb="1.5em"
              />
            )}

            <Box display="flex" justifyContent="flex-end">
              <BaseButton
                mb="1.5em"
                btnText="Add Writer"
                onClick={onClickContinue}
                disabled={isSaving}
                isLoading={isSaving}
                fluid
              />
            </Box>
          </Fragment>
        </Fragment>
      )}
    </Box>
  );
}

AddWriterForm.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
  handleCloseFunction: PropTypes.func.isRequired,
};

export default AddWriterForm;
