import PropTypes from 'prop-types';

import Card from '@distinct-components/cards/baseCard';
import LinkButton from '@ui-kit/buttons/linkButton';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import EditIcon from '@assets/icons/edit-line.svg';
import InfoIcon from '@assets/icons/info-circle.svg';

import {
  CardHeaderWrapper,
  PanelCardHeader,
  CardActionsWrapper,
  CardBodyWrapper,
} from './PanelCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function PanelCard({
  children,
  title,
  titleCount,
  description,
  descriptionWidth,
  handleEditSection,
  handleInfoSection,
  actionText,
  action,
  darkPanel,
  ...restProps
}) {
  return (
    <Card variant={darkPanel ? '' : 'panel'} padding="0em" {...restProps}>
      <CardHeaderWrapper>
        <PanelCardHeader>
          {title}
          {' '}
          {titleCount && titleCount}
        </PanelCardHeader>
        <CardActionsWrapper>
          {handleEditSection
          && <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={handleEditSection}><EditIcon /></Icon>}
          {handleInfoSection
          && <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={handleInfoSection}><InfoIcon /></Icon>}
          {(action && actionText)
          && <LinkButton btnText={actionText} variant="small" color="var(--text-primary)" onClick={action} />}
        </CardActionsWrapper>
      </CardHeaderWrapper>
      <CardBodyWrapper>
        {description && <Text fontSize="0.75rem" color="var(--text-secondary)" maxWidth={descriptionWidth}>{description}</Text>}
        {children}
      </CardBodyWrapper>
    </Card>
  );
}

PanelCard.propTypes = {
  children: childrenPropTypes,
  title: PropTypes.string.isRequired,
  titleCount: PropTypes.number,
  description: PropTypes.string,
  descriptionWidth: PropTypes.string,
  handleEditSection: PropTypes.func,
  handleInfoSection: PropTypes.func,
  actionText: PropTypes.string,
  darkPanel: PropTypes.bool,
  action: PropTypes.func,
};

PanelCard.defaultProps = {
  titleCount: null,
  handleEditSection: null,
  handleInfoSection: null,
  description: null,
  descriptionWidth: '40em',
  actionText: null,
  action: null,
  children: null,
  darkPanel: false,
};

export default PanelCard;
