/* eslint-disable no-alert */
import PropTypes from 'prop-types';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import InfoIcon from '@assets/icons/question-circle.svg';

import { TooltipWrapper, MobileTooltipWrapper } from './TooltipStyles';

function Tooltip({
  prompt,
  overPanel,
  size,
  width,
  top,
  bottom,
  left,
  right,
  opacity,
}) {
  const onTapLockIcon = () => {
    alert(prompt);
  };
  return (
    <Box>
      <TooltipWrapper
        width={width}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        overPanel={overPanel}
      >
        <span className="toolTipText">{prompt}</span>
        <Icon size={size} pt="0.25em" pr="0.25em" style={{ opacity }} cursor><InfoIcon /></Icon>
      </TooltipWrapper>
      <MobileTooltipWrapper>
        <Icon size={size} pr="0.25em" style={{ opacity }} cursor onClick={onTapLockIcon}><InfoIcon /></Icon>
      </MobileTooltipWrapper>
    </Box>
  );
}

Tooltip.propTypes = {
  prompt: PropTypes.string.isRequired,
  width: PropTypes.string,
  size: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  opacity: PropTypes.string,
  overPanel: PropTypes.bool,
};

Tooltip.defaultProps = {
  width: '160px',
  size: '0.875rem',
  top: '',
  bottom: '10px',
  left: '',
  right: '4px',
  overPanel: false,
  opacity: '0.5',
};

export default Tooltip;
