import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import CountDown from '@distinct-components/feedback/countDown';
import TrashIcon from '@assets/icons/trash-outline.svg';
import Icon from '@ui-kit/icon';
import Spinner from '@ui-kit/loaders/Spinner';

import {
  StyledUtilityButton,
} from './ConfirmDeleteUtilityBtnStyles';

function ConfirmDeleteUtilityBtn({
  primaryText,
  confirmText,
  destructiveFunction,
  isPerformingDestructiveAction,
  ...restProps
}) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onClickBtn = () => {
    if (isPerformingDestructiveAction) {
      return;
    }
    if (showDeleteConfirmation) {
      setShowDeleteConfirmation(false);
      destructiveFunction();
    } else {
      setShowDeleteConfirmation(true);
      setTimeout(() => {
        setShowDeleteConfirmation(false);
      }, 5000);
    }
  };

  return (
    <StyledUtilityButton
      danger={showDeleteConfirmation || isPerformingDestructiveAction}
      isPerformingDestructiveAction={isPerformingDestructiveAction}
      onClick={onClickBtn}
      {...restProps}
    >
      {!isPerformingDestructiveAction
        && (
        <Box>
          {showDeleteConfirmation
            ? (
              <Box display="flex" alignItems="center">
                <CountDown mr="0.25em" mt="3px" timeInSeconds={5} size={15} color="var(--red-300)" />
                <Text fontSize="0.813rem">{confirmText}</Text>
              </Box>
            )
            : (
              <Box display="flex" alignItems="center">
                <Icon color="var(--red-300)" size="0.938rem" pt="3px" mr="0.5em" cursor><TrashIcon /></Icon>
                <Text fontSize="0.813rem">{primaryText}</Text>
              </Box>
            )}
        </Box>
        )}

      {(isPerformingDestructiveAction) && (
        <Box style={{ transform: 'translateX(2px)' }}>
          <Spinner size="1em" variant="danger" />
        </Box>
      )}

    </StyledUtilityButton>
  );
}

ConfirmDeleteUtilityBtn.propTypes = {
  primaryText: PropTypes.string,
  confirmText: PropTypes.string,
  destructiveFunction: PropTypes.func.isRequired,
  isPerformingDestructiveAction: PropTypes.bool,
};

ConfirmDeleteUtilityBtn.defaultProps = {
  primaryText: 'Delete',
  confirmText: 'Yes, Delete',
  isPerformingDestructiveAction: false,
};

export default ConfirmDeleteUtilityBtn;
