/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';

import ManagePublisherOverlay from '@shared-overlays/managePublisherOverlay/ManagePublisherOverlay';

import ManageSocietyOverlay from '@shared-overlays/manageSocietyOverlay/ManageSocietyOverlay';
import EditWriterIPI from '../../../../../../artistIdentityProfile/modes/profilePrimaryMode/sections/profileWriterSection/sections/editWriterIPI/EditWriterIPI';
import ManagePersona from './managePersona/ManagePersona';

import {
  WriterProfileWrapper,
  WriterProfileDetails,
  ProfileDetailRow,
  ProfileDetailItem,
  ProfileDataLabel,
  ProfileDataPoint,
  ProfileEditPrompt,
  SuccessDot,
  WarningDot,
} from './WriterListItemProfileStyles';

function WriterListItemProfile({
  activeUser,
  writer,
  refreshWriters,
}) {
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);
  const [isProfileMissingData, setIsProfileMissingData] = useState(false);
  const [isEditingSociety, setIsEditingSociety] = useState(false);
  const [isEditingIPI, setIsEditingIPI] = useState(false);
  const [isEditingAlias, setIsEditingAlias] = useState(false);
  const [isManagingPublisher, setIsManagingPublisher] = useState(false);

  useEffect(() => {
    if (writer.ipi && writer.societies.length > 0 && writer.pubPreset?.name) {
      setIsProfileMissingData(false);
    } else {
      setIsProfileMissingData(true);
    }
  }, [writer]);

  const onToggleProfileDetail = () => {
    setIsProfileExpanded(!isProfileExpanded);
  };

  const onToggleEditAlias = () => {
    setIsEditingAlias(!isEditingAlias);
  };

  const onClickEditMyIPI = () => {
    setIsEditingIPI(true);
  };

  const handleUpdateWriterIPI = () => {
    setIsEditingIPI(false);
    refreshWriters();
  };

  const onClickEditMySociety = () => {
    setIsEditingSociety(true);
  };

  const handleUpdateWriterSociety = () => {
    setIsEditingSociety(false);
    refreshWriters();
  };

  const onClickManagePublisherPreset = () => {
    setIsManagingPublisher(true);
  };

  const handleUpdateWriterPublisher = () => {
    setIsManagingPublisher(false);
    refreshWriters();
  };

  const handleWriterToManage = () => {
    AppState.workspace.writerToManage.value = writer;
  };

  const handleGuestToManage = () => {
    AppState.workspace.guestToManage.value = writer;
  };

  return (
    <Fragment>
      <WriterProfileWrapper>
        <Box p="0.5em 1em" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" className="cursor-p" onClick={onToggleProfileDetail}>
            {!isProfileMissingData ? (
              <Box display="flex" alignItems="center">
                <SuccessDot />
                <Text fontSize="0.813rem" fontWeight="400" color="var(--text-primary)" mr="1em">Profile Complete</Text>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <WarningDot />
                <Text fontSize="0.813rem" fontWeight="400" color="var(--text-primary)" mr="1em">Profile Incomplete</Text>
              </Box>
            )}
          </Box>
          <Box display="flex">
            {activeUser.isAdmin && activeUser.id !== writer.id && writer.isRegistered
            && <LinkButton color="var(--text-secondary)" btnText="Edit Permission or Remove" fontSize="0.813rem" onClick={handleWriterToManage} />}

            {activeUser.isAdmin && !writer.isRegistered
            && <LinkButton color="var(--text-secondary)" btnText="Edit Guest Writer" fontSize="0.813rem" onClick={handleGuestToManage} />}
          </Box>
        </Box>

        {isProfileExpanded && (
          <WriterProfileDetails>
            {(writer.alias || ((writer.id === AppState.accountId.value) && AppState.aliases.value.length > 0))
            && (
            <ProfileDetailRow>
              <ProfileDetailItem>
                <ProfileDataLabel>Writer Persona</ProfileDataLabel>
                {writer.id === AppState.accountId.value ? (
                  <PanelDataCard variant={!writer.alias ? 'centered' : ''} onClick={onToggleEditAlias}>
                    {writer.alias ? (
                      <Box>
                        <ProfileDataPoint>{writer.alias.name}</ProfileDataPoint>
                        {writer.alias.ipi && (
                        <ProfileDataPoint>
                          Persona IPI:
                          {' '}
                          {writer.alias.ipi}
                        </ProfileDataPoint>
                        )}
                        {writer.alias.isni && (
                        <ProfileDataPoint>
                          Persona ISNI:
                          {' '}
                          {writer.alias.isni}
                        </ProfileDataPoint>
                        )}
                      </Box>
                    )
                      : <ProfileDataPoint fontWeight="500">Apply a Persona</ProfileDataPoint>}
                  </PanelDataCard>
                ) : (
                  <Box>
                    <ProfileDataPoint>{writer.alias.name}</ProfileDataPoint>
                    {writer.alias.ipi && (
                      <ProfileDataPoint>
                        Persona IPI:
                        {' '}
                        {writer.alias.ipi}
                      </ProfileDataPoint>
                    )}
                    {writer.alias.isni && (
                      <ProfileDataPoint>
                        Persona ISNI:
                        {' '}
                        {writer.alias.isni}
                      </ProfileDataPoint>
                    )}
                  </Box>
                )}
              </ProfileDetailItem>
            </ProfileDetailRow>
            )}

            <ProfileDetailRow>
              <ProfileDetailItem>
                <ProfileDataLabel>Writer Society</ProfileDataLabel>
                {writer.id === AppState.accountId.value ? (
                  <PanelDataCard variant={!writer.societies.length > 0 ? 'centered' : ''} onClick={onClickEditMySociety}>
                    {writer.societies.length > 0
                      ? <ProfileDataPoint fontSize="0.875rem">{writer.societies.map((society) => society.name).join(', ')}</ProfileDataPoint>
                      : <ProfileDataPoint fontWeight="500" color="var(--orange-300)">Setup Society</ProfileDataPoint>}
                  </PanelDataCard>
                ) : (
                  <ProfileDataPoint
                    color={writer.societies.length === 0 && 'var(--orange-300)'}
                  >
                    {writer.societies.length > 0 ? (writer.societies.map((society) => society.name).join(', ')) : ('Not Setup in Profile')}
                  </ProfileDataPoint>
                )}
              </ProfileDetailItem>
              <ProfileDetailItem>
                <ProfileDataLabel>Writer IPI</ProfileDataLabel>
                {writer.id === AppState.accountId.value
                  ? (
                    <PanelDataCard variant={!writer.ipi ? 'centered' : ''} onClick={onClickEditMyIPI}>
                      {writer.ipi
                        ? <ProfileDataPoint>{writer.ipi}</ProfileDataPoint>
                        : <ProfileDataPoint fontWeight="500" color="var(--orange-300)">Setup IPI</ProfileDataPoint>}
                    </PanelDataCard>
                  )
                  : (
                    <ProfileDataPoint
                      color={!writer.ipi && 'var(--orange-300)'}
                    >
                      {writer.ipi || 'Not Setup in Profile'}
                    </ProfileDataPoint>
                  )}
              </ProfileDetailItem>
            </ProfileDetailRow>

            <ProfileDetailRow>
              <ProfileDetailItem>
                {writer.id === AppState.accountId.value ? (
                  <Fragment>
                    <ProfileDataLabel>{writer.pubPreset?.name ? 'Original Publisher' : 'Original Publisher and Administration'}</ProfileDataLabel>
                    <PanelDataCard minWidth="14em" variant={!writer.pubPreset?.owners?.length > 0 ? 'centered' : ''} onClick={() => onClickManagePublisherPreset()}>
                      {writer.pubPreset?.name ? (
                        <Box>
                          {writer.pubPreset.owners.map((po, index) => (
                            <Box mb={index + 1 !== writer.pubPreset.owners.length ? '0.5em' : '0'}>
                              <ProfileDataPoint fontWeight="600" fontSize="">{po.name}</ProfileDataPoint>
                              <Box display="flex">
                                <ProfileDataPoint mr="1em">
                                  Society:
                                  {' '}
                                  {po.society}
                                </ProfileDataPoint>
                                <ProfileDataPoint>
                                  IPI:
                                  {' '}
                                  {po.ipi}
                                </ProfileDataPoint>
                              </Box>
                              <ProfileDataPoint>
                                {po.address}
                              </ProfileDataPoint>
                              <ProfileDataPoint>
                                Owns:
                                {' '}
                                {po.ownedPercentage}
                                %
                              </ProfileDataPoint>
                            </Box>
                          ))}
                        </Box>
                      )
                        : <ProfileDataPoint fontWeight="500" color="var(--orange-300)">Setup Publishing Info</ProfileDataPoint>}
                    </PanelDataCard>
                  </Fragment>
                ) : (
                  <Box>
                    {writer.pubPreset?.name ? (
                      <Fragment>
                        <ProfileDataLabel>Original Publisher</ProfileDataLabel>
                        <Box>
                          {writer.pubPreset.owners.map((po, index) => (
                            <Box mb={index + 1 !== writer.pubPreset.owners.length ? '0.5em' : '0'}>
                              <ProfileDataPoint>{po.name}</ProfileDataPoint>
                              <Box display="flex">
                                <ProfileDataPoint mr="1em">
                                  Society:
                                  {' '}
                                  {po.society}
                                </ProfileDataPoint>
                                <ProfileDataPoint>
                                  IPI:
                                  {' '}
                                  {po.ipi}
                                </ProfileDataPoint>
                              </Box>
                              <ProfileDataPoint>
                                {po.address}
                              </ProfileDataPoint>
                              <ProfileDataPoint>
                                Owns:
                                {' '}
                                {po.ownedPercentage}
                                %
                              </ProfileDataPoint>
                            </Box>
                          ))}
                        </Box>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <ProfileDataLabel>Publishing Information</ProfileDataLabel>
                        <ProfileDataPoint color="var(--orange-300)">Not Setup in Profile</ProfileDataPoint>
                      </Fragment>
                    )}
                  </Box>
                )}
              </ProfileDetailItem>
              {writer.pubPreset?.name && (
                <ProfileDetailItem>
                  <ProfileDataLabel>Publishing Administration</ProfileDataLabel>
                  {writer.id === AppState.accountId.value ? (
                    <PanelDataCard
                      onClick={() => onClickManagePublisherPreset()}
                      minWidth="14em"
                      variant={!writer.pubPreset?.admins?.length > 0 ? 'centered' : ''}
                    >
                      <Box>
                        {writer.pubPreset.admins.map((pa, index) => (
                          <Box mb={index + 1 !== writer.pubPreset.admins.length ? '0.5em' : '0'}>
                            <ProfileDataPoint fontWeight="600">{pa.name}</ProfileDataPoint>
                            <Box display="flex">
                              <ProfileDataPoint mr="1em">
                                Society:
                                {' '}
                                {pa.society}
                              </ProfileDataPoint>
                              <ProfileDataPoint>
                                IPI:
                                {' '}
                                {pa.ipi}
                              </ProfileDataPoint>
                            </Box>
                            <ProfileDataPoint>
                              {pa.address}
                            </ProfileDataPoint>
                            <ProfileDataPoint>
                              Territory:
                              {' '}
                              {pa.territory}
                            </ProfileDataPoint>
                          </Box>
                        ))}
                      </Box>
                    </PanelDataCard>
                  ) : (
                    <Box>
                      {writer.pubPreset.admins.map((pa, index) => (
                        <Box mb={index + 1 !== writer.pubPreset.admins.length ? '0.5em' : '0'}>
                          <ProfileDataPoint>{pa.name}</ProfileDataPoint>
                          <Box display="flex">
                            <ProfileDataPoint mr="1em">
                              Society:
                              {' '}
                              {pa.society}
                            </ProfileDataPoint>
                            <ProfileDataPoint>
                              IPI:
                              {' '}
                              {pa.ipi}
                            </ProfileDataPoint>
                          </Box>
                          <ProfileDataPoint>
                            {pa.address}
                          </ProfileDataPoint>
                          <ProfileDataPoint>
                            Territory:
                            {' '}
                            {pa.territory}
                          </ProfileDataPoint>
                        </Box>
                      ))}
                    </Box>
                  )}
                </ProfileDetailItem>
              )}
            </ProfileDetailRow>

            {writer.id === AppState.accountId.value
              ? (
                <ProfileEditPrompt>You are the only one that can edit this information</ProfileEditPrompt>
              )
              : (
                <ProfileEditPrompt>Only the writer can edit this information</ProfileEditPrompt>
              )}
          </WriterProfileDetails>
        )}
      </WriterProfileWrapper>

      {isEditingSociety && (
      <ManageSocietyOverlay closeFunction={handleUpdateWriterSociety} />
      )}

      {isEditingIPI && (
      <EditWriterIPI closeFunction={handleUpdateWriterIPI} />
      )}

      {isManagingPublisher && (
      <ManagePublisherOverlay
        closeFunction={handleUpdateWriterPublisher}
        workspaceId={AppState.composition.id.value}
        initPresetId={writer.pubPreset?.id || null}
        showPresetList
      />
      )}

      {isEditingAlias && (
      <ManagePersona closeFunction={onToggleEditAlias} writer={writer} refreshWriters={refreshWriters} />
      )}
    </Fragment>
  );
}

WriterListItemProfile.propTypes = {
  activeUser: PropTypes.object.isRequired,
  writer: PropTypes.object.isRequired,
  refreshWriters: PropTypes.func.isRequired,
};

export default WriterListItemProfile;
