import PropTypes from 'prop-types';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import AppState from '@state';
import resetGlobalState from '@state/reset';

import { getRepTokens } from '@api/restricted/rep-tokens-api';
import loadAccountProfile from '@actions/loadAccountProfile';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import HomeIcon from '@assets/icons/home.svg';
import CompositionIcon from '@assets/icons/composition-note.svg';
import RecordingIcon from '@assets/icons/recordings-square.svg';
import UserHexIcon from '@assets/icons/user-hexagon.svg';
import ContractsIcon from '@assets/icons/contracts-scroll.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import SettingsIcon from '@assets/icons/settings-square.svg';
// import TutorialsIcon from '@assets/icons/tutorial-cards-outline.svg';
import TeamIcon from '@assets/icons/team-outline.svg';
import LinkButton from '@ui-kit/buttons/linkButton';
import Text from '@ui-kit/typography/text';

import { Fragment } from 'preact';
import {
  MenuItem,
  MenuName,
  MenuWrapper,
  IssueIndicator,
  IssueIndicatorWrapper,
  VerifiedSessionWrapper,
} from '../MenuStyles';

function ArtistMenu({
  handleMenuChange,
}) {
  const [currentRoute, setCurrentRoute] = useState(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));

  const isRepSession = AppState.repSession.isActive.value;
  const isRecordingPreview = AppState.artistRecordings.isPreviewActive.value;

  const handleRouteChange = (path) => {
    route(path);
    const trimmedRoute = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    setCurrentRoute(trimmedRoute);
    handleMenuChange();
  };

  const handleSignOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    resetGlobalState();
    route('/login');
  };

  const onClickEndRepSession = () => {
    getRepTokens()
      .then((response) => {
        if (response.status !== 200) {
          route('/error/invalidLink');
        } else {
          response.json().then((json) => {
            resetGlobalState();
            localStorage.setItem('accessToken', json.accessToken);
            localStorage.setItem('refreshToken', json.refreshToken);
            loadAccountProfile().then(() => {
              route('/rep/dashboard');
            });
          });
        }
      })
      .catch(() => {
        route('/error/invalidLink');
      });
  };

  return (
    <MenuWrapper>
      {isRepSession && (
        <VerifiedSessionWrapper>
          <Text>
            <span style={{ fontWeight: 600 }}>Rep:</span>
            {' '}
            {AppState.userProfile.firstName.value}
            {' '}
            {AppState.userProfile.lastName.value}
          </Text>
          <LinkButton btnText="End Verified Session" variant="small" color="var(--text-primary)" onClick={onClickEndRepSession} />
        </VerifiedSessionWrapper>
      )}
      <Box>
        <MenuItem onClick={() => handleRouteChange('/home')} isActive={currentRoute === 'home'}>
          <Icon cursor><HomeIcon /></Icon>
          <MenuName>Home</MenuName>
        </MenuItem>
        {isRecordingPreview
          && (
          <MenuItem onClick={() => handleRouteChange('/recordings')} isActive={currentRoute === 'recordings'}>
            <Icon cursor><RecordingIcon /></Icon>
            <MenuName>Recordings</MenuName>
          </MenuItem>
          )}
        <MenuItem onClick={() => handleRouteChange('/compositions')} isActive={currentRoute === 'compositions'}>
          <Icon cursor><CompositionIcon /></Icon>
          <MenuName>Songwriting</MenuName>
        </MenuItem>
        {(AppState.artistProfile.societyCritical.value || AppState.artistProfile.ipiCritical.value || AppState.artistProfile.publisherCritical.value)
          && <IssueIndicatorWrapper><IssueIndicator /></IssueIndicatorWrapper>}
        <MenuItem onClick={() => handleRouteChange('/profile')} isActive={currentRoute === 'profile'}>
          <Icon cursor><UserHexIcon /></Icon>
          <MenuName>My Identity</MenuName>
        </MenuItem>
        <MenuItem onClick={() => handleRouteChange('/contracts')} isActive={currentRoute === 'contracts'}>
          <Icon cursor><ContractsIcon /></Icon>
          <MenuName>Contracts</MenuName>
        </MenuItem>
        {!isRepSession
          && (
            <Fragment>
              <MenuItem onClick={() => handleRouteChange('/representation')} isActive={currentRoute === 'representation'}>
                <Icon cursor><TeamIcon /></Icon>
                <MenuName>Representation</MenuName>
              </MenuItem>
              <MenuItem onClick={() => handleRouteChange('/settings')} isActive={currentRoute === 'settings'}>
                <Icon cursor><SettingsIcon /></Icon>
                <MenuName>Settings</MenuName>
              </MenuItem>
            </Fragment>
          )}
      </Box>
      <Box>
        {/* <MenuItem onClick={() => handleRouteChange('/tutorials')} isActive={currentRoute === 'tutorials'}>
          <Icon cursor><TutorialsIcon /></Icon>
          <MenuName>Tips & Tutorials</MenuName>
        </MenuItem> */}
        <MenuItem mb="0em" onClick={handleSignOut}>
          <Icon cursor><LogoutIcon /></Icon>
          <MenuName>Sign Out</MenuName>
        </MenuItem>
      </Box>
    </MenuWrapper>
  );
}

ArtistMenu.propTypes = {
  handleMenuChange: PropTypes.func.isRequired,
};

export default ArtistMenu;
