/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useState, useEffect,
} from 'preact/hooks';

import {
  ActionMsg,
  ActionTitle,
  ActionWrapper,
  ActionBtnRow,
  StyledActionButton,
  // StyledSecondaryButton,
  // SecondaryActionRow,
} from './RecordingInstructionsSectionStyles';

function RecordingInstructionsSection({ recordingData, activeUser }) {
  // const [isLoading, setIsLoading] = useState(false);
  // const [secondaryAction, setSecondaryAction] = useState(null);
  const [primaryAction, setPrimaryAction] = useState(null);
  const [actionTitle, setActionTitle] = useState('');
  const [actionColor, setActionColor] = useState('');
  const [actionMsg, setActionMsg] = useState('');

  const handleInstructionContent = () => {
    //
    if (!recordingData.isFinalFile) {
      setActionTitle('Add Credits');
      setActionColor('purple');
      setActionMsg('Invite collaborators to document their credits. For the best anti fraud protection, we recommend getting all your credits added before exporting your final files.');
    } else if (recordingData.isFinalFile && recordingData.compositionLink == null) {
      setActionTitle('No Composition Link');
      setActionColor('orange');
      setActionMsg('The file is marked as Final. It is extremely important to document the written song and associate this recording to that copyright');
      setPrimaryAction('Fix It');
    } else {
      setActionTitle('Final File');
      setActionColor('green');
      setActionMsg('This file is distribution ready. It is best do have all credits documented now for anti fraud when the file is exported but you can continue to add credits even after exporting.');
    }
  };

  useEffect(() => {
    handleInstructionContent();
  }, [recordingData]);

  const handlePrimaryAction = () => {
    alert('primary action not configured');
  };

  return (
    <ActionWrapper actionColor={actionColor}>
      <ActionTitle>{actionTitle}</ActionTitle>
      <ActionMsg>{actionMsg}</ActionMsg>
      <ActionBtnRow>
        {/* {secondaryAction && (
        <StyledSecondaryButton actionColor={actionColor} btnText={secondaryAction} onClick={handleSecondaryAction} fluid />
        )} */}
        {primaryAction && activeUser.isAdmin && (
        <StyledActionButton actionColor={actionColor} onClick={handlePrimaryAction} btnText={primaryAction} fluid />
        )}
      </ActionBtnRow>

      {/* SAVING THIS CODE UNTIL ADMIN ACTIONS ARE REALIZED */}
      {/* {makeAConditionForThis && (
      <SecondaryActionRow actionColor={actionColor}>
        {conditionHere && <LinkButton color="var(--text-primary)" btnText="Do Admin Things" fontSize="0.813rem" onClick={() => alert('wire up')} />}
      </SecondaryActionRow>
      )} */}
    </ActionWrapper>
  );
}

RecordingInstructionsSection.propTypes = {
  recordingData: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default RecordingInstructionsSection;
