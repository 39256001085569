import { useEffect, useState } from 'preact/hooks';
import pointBreaks from '../styles/styleConstants';

export default function useTruncateText(text, textLengths, options) {
  const [truncatedText, setTruncatedText] = useState(text);

  const truncateText = () => {
    const width = window.innerWidth;
    let extensionString = '';
    let maxLength = text.length;

    if (width >= parseInt(pointBreaks.lg, 10)) {
      maxLength = textLengths.lg;
    } else if (width >= parseInt(pointBreaks.md, 10)) {
      maxLength = textLengths.md;
    } else if (width < parseInt(pointBreaks.md, 10)) {
      maxLength = textLengths.sm;
    }

    if (options && options.keepExtension && text.length > maxLength) {
      extensionString = text.slice(-4);
    }

    setTruncatedText(`${text.slice(0, maxLength)}${text.length > maxLength ? '...' : ''} ${extensionString}`);
  };

  const handleResize = () => {
    truncateText();
  };

  useEffect(() => {
    truncateText();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [text, textLengths]);

  return truncatedText;
}
