import anime from 'animejs/lib/anime.es';
import { useEffect } from 'preact/hooks';

function ActivePlayerAnimation() {
  const wrappersStyle = {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '2px',
    right: '-2px',
  };

  const pulseStyle = {
    width: '32px',
    height: '32px',
    borderRadius: '50px',
    border: '2px solid var(--white)',
    opacity: '0',
  };

  useEffect(() => {
    anime({
      targets: '.pulse',
      scale: [1, 1.2, 1.4],
      opacity: [0, 0.2, 0],
      duration: 3000,
      easing: 'easeOutCubic',
      loop: true,
    });
  }, []);

  return (
    <div id="square-animation-wrapper" style={wrappersStyle}>
      <div className="pulse" style={pulseStyle} />
    </div>
  );
}

export default ActivePlayerAnimation;
