/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Box from '@ui-kit/box';

export const EmptyStateWrapper = styled(Box)`
    display: flex;
    border: 1px solid var(--border);
    border-radius: 0.5rem;
    align-items: center;
    padding: 8px 10px 8px 10px;
    flex-direction: column;
    min-height: 90px;
    justify-content: center;
`;

export const IconWrapper = styled.div`
    height: 32px;
    width: 32px;
    background: var(--input-bg);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 4px;
    margin-top: 4px;
`;
