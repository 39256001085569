import styled from 'styled-components';
import { space } from 'styled-system';

export const DragAndDropWrapper = styled.div`
    ${space};
`;

export const DragAndDropContainer = styled.div`

    #form-file-upload {
        height: 6rem;
        width: 100%;
        max-width: 100%;
        text-align: center;
        position: relative;
      }
`;

export const DragMonitor = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;

export const StyledDropInput = styled.input`
    display: none;
`;

export const StyledDropLabel = styled.label(({
  background,
}) => `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 0.5em;
    border-style: dashed;
    border-color: var(--inactive);
    background: ${background};

    &.drag-active {
        border-color: var(--action-purple);
    }
`);
