/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';
import { useState } from 'preact/hooks';

import d from '@hooks/useIsoDateFormat';

import { deleteOrgCompositionHold } from '@api/restricted/org-composition-holds-api';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';
import PrimaryText from '@ui-kit/typography/primaryText';
import SecondaryText from '@ui-kit/typography/secondaryText';
import UtilityButton from '@ui-kit/buttons/utilityButton';

import ApplyHoldForm from './sections/applyHoldForm';

import {
  StyledHoldBtn,
  StyledHoldDetail,
  StyledHoldDetailHeader,
} from './ToolsHoldsStyles';

function ToolsHolds({ compositionId, loadInternalData, holds }) {
  const [isApplyingFirstUse, setIsApplyingFirstUse] = useState(false);
  const [isApplyingRegistration, setIsApplyingRegistration] = useState(false);

  const useToastAlert = (message, type) => {
    AppState.messages.toastMessage.value = { text: message, variant: type };
  };

  const onClickAddHold = (type) => {
    if (type === 'registration') {
      setIsApplyingRegistration(true);
      setIsApplyingFirstUse(false);
    } else {
      setIsApplyingFirstUse(true);
      setIsApplyingRegistration(false);
    }
  };

  const handleHoldStatusUpdated = (type) => {
    if (type === 'registration') {
      setIsApplyingRegistration(false);
      useToastAlert('Registration Hold Applied', 'success');
    } else {
      setIsApplyingFirstUse(false);
      useToastAlert('First Use Hold Applied', 'success');
    }
  };

  const onClickRemoveHold = (type) => {
    deleteOrgCompositionHold(AppState.pubOrganization.id.value, compositionId, type)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            if (type === 'registration') {
              useToastAlert('Registration Hold Cleared', 'success');
            } else {
              useToastAlert('First Use Hold Cleared', 'success');
            }
          });
        } else {
          response.json()
            .then((json) => useToastAlert(json.error || response.statusText, 'error'))
            .catch(() => useToastAlert(response.statusText, 'error'));
        }
      })
      .catch((err) => {
        useToastAlert(err.message, 'error');
      });
  };

  return (
    <Box p="1em 1em 0em 1em" mt="0.5em">
      {(holds.orgCanPutHold || holds.registration.isActive) && (
        <Box>
          <InputLabel mb="1em" label="Registration Hold" />
          {holds.registration.isActive ? (
            <StyledHoldDetail>
              <StyledHoldDetailHeader>
                <PrimaryText color="var(--alert-negative-segment)">Registration Hold</PrimaryText>
                {holds.orgCanPutHold && <UtilityButton ml="0.875em" icon="close" onClick={() => onClickRemoveHold('registration')} />}
              </StyledHoldDetailHeader>
              <Box p="0rem 2rem 0.875rem 0.875rem">
                <SecondaryText>
                  Reason:
                  {' '}
                  {holds.registration.message || 'N/A'}
                </SecondaryText>
                <Box display="flex" alignItems="center" mt="0.5rem">
                  <SecondaryText>
                    Activated by:
                    {' '}
                    {holds.registration.activatedBy}
                  </SecondaryText>
                  <SecondaryText>
                    &nbsp;
                    -
                    {' '}
                    {d(holds.registration.activatedOn)}
                  </SecondaryText>
                </Box>
              </Box>
            </StyledHoldDetail>
          ) : (
            <Box>
              {isApplyingRegistration
                ? (
                  <ApplyHoldForm
                    compositionId={compositionId}
                    holdType="registration"
                    handleCancel={() => setIsApplyingFirstUse(false)}
                    handleHoldStatusUpdated={() => handleHoldStatusUpdated('registration')}
                    loadInternalData={loadInternalData}
                  />
                ) : (
                  <StyledHoldBtn btnText="Add Registration Hold" onClick={() => onClickAddHold('registration')} />
                )}
            </Box>
          )}
          <BreakLine mt="1.5em" mb="1.5em" color="var(--border)" />
        </Box>
      )}

      {(holds.orgCanPutHold || holds.firstUse.isActive) && (
        <Box>
          <InputLabel mb="1em" label="First Use Hold" />
          {holds.firstUse.isActive ? (
            <StyledHoldDetail>
              <StyledHoldDetailHeader>
                <PrimaryText color="var(--alert-negative-segment)">Hold for First Use</PrimaryText>
                {holds.orgCanPutHold && <UtilityButton ml="0.875em" icon="close" onClick={() => onClickRemoveHold('firstUse')} />}
              </StyledHoldDetailHeader>
              <Box p="0rem 2rem 0.875rem 0.875rem">
                <SecondaryText>
                  Reason:
                  {' '}
                  {holds.firstUse.message || 'N/A'}
                </SecondaryText>
                <Box display="flex" alignItems="center" mt="0.5rem">
                  <SecondaryText>
                    Activated by:
                    {' '}
                    {holds.firstUse.activatedBy}
                  </SecondaryText>
                  <SecondaryText>
                    &nbsp;
                    -
                    {' '}
                    {d(holds.firstUse.activatedOn)}
                  </SecondaryText>
                </Box>
              </Box>
            </StyledHoldDetail>
          ) : (
            <Box>
              {isApplyingFirstUse
                ? (
                  <ApplyHoldForm
                    compositionId={compositionId}
                    holdType="firstUse"
                    handleCancel={() => setIsApplyingFirstUse(false)}
                    handleHoldStatusUpdated={() => handleHoldStatusUpdated('firstUse')}
                    loadInternalData={loadInternalData}
                  />
                ) : (
                  <StyledHoldBtn fontSize="0.875rem" btnText="Add First Use Hold" onClick={() => onClickAddHold('firstUse')} />
                )}
            </Box>
          )}
          <BreakLine mt="1.5em" color="var(--border)" />
        </Box>
      )}
    </Box>
  );
}

ToolsHolds.propTypes = {
  compositionId: PropTypes.number.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  holds: PropTypes.object,
};

ToolsHolds.defaultProps = {
  holds: null,
};

export default ToolsHolds;
