/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';

import useFileSize from '@hooks/useFileSize';
import useErrorOverlay from '@hooks/useErrorOverlay';
import {
  getWorkspaceRecordingFile,
} from '@api/restricted/workspace-recording-api';

import HeaderCard from '@distinct-components/cards/headerCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import FileCard from '@distinct-components/files/fileCard/FileCard';
import FileArtworkImage from '@distinct-components/files/fileArtworkImage';

function ViewCompositionRecordings({ recordings, compositionUUID }) {
  const handlePlayRecording = (rec) => {
    if (AppState.media.currentlyPlaying.value === rec && AppState.media.isPlaying.value === true) {
      AppState.media.currentlyPlayingUUID.value = null;
      AppState.media.currentlyPlaying.value = null;
      AppState.media.isPlaying.value = false;
    } else {
      // AppState.composition.uuid.value = compositionUUID;
      AppState.media.currentlyPlayingUUID.value = compositionUUID;
      AppState.media.currentlyPlaying.value = rec;
      AppState.media.isPlaying.value = true;
    }
  };

  const handleFileDownload = (file) => {
    getWorkspaceRecordingFile(compositionUUID, file.id)
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        } else {
          response.json().then((json) => {
            useErrorOverlay({ error: json.error || response.statusText });
          });
        }
      })
      .catch((err) => {
        useErrorOverlay({ errorMessage: err.message });
      });
  };

  return (
    <HeaderCard mb="1.5em" headerText="Early Recordings / Work Tapes">
      {recordings.length > 0
        ? (
          <Box id="box" mt="0.5em">
            {recordings.sort((a, b) => b.id - a.id).map((recording) => (
              <FileCard
                file={recording}
                primaryDetail={useFileSize(recording.size)}
                handleFileDelete={null}
                handleFileDownload={handleFileDownload}
                activeUser={null}
              >
                <FileArtworkImage
                  fileId={recording.id}
                  handleFilePlay={() => handlePlayRecording(recording)}
                  artworkURL={null}
                  isFileAvailable
                  toggleAction="stop"
                  mr="0.875rem"
                />

              </FileCard>
            ))}
          </Box>
        )
        : (
          <Text color="var(--text-medium-mute)">No Recordings Added</Text>
        )}
    </HeaderCard>
  );
}

ViewCompositionRecordings.propTypes = {
  recordings: PropTypes.array.isRequired,
  compositionUUID: PropTypes.string.isRequired,
};

export default ViewCompositionRecordings;
