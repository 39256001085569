/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const StyledArtistCard = styled.div`
    background: var(--panel-card);
    border-radius: 1rem;
    width: 100%;
    padding: 0.75rem;
    
    border: 0.8px solid var(--card-border);
    margin-bottom: 1rem;
    position: relative;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em;
    }
`;

export const StyledCardRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
`;

export const StyledCardDetail = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
    cursor: ${(props) => (props.isClickable === false ? 'default' : 'pointer')};
    flex-grow: 1;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const writerContent = styled.div`
    background: var(--panel-card);
    border-radius: 0.5em;
    width: 100%;
    min-height: 20em;
    padding: 2em;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 1em;
    }
`;

export const VerifiedDot = styled.div`
    height: 8px;
    width: 8px;
    background: var(--color-blue);
    border-radius: 50px;
    margin-right: 6px;
`;

export const UnverifiedDot = styled.div`
    height: 8px;
    width: 8px;
    background: var(--icon);
    border-radius: 50px;
    margin-right: 6px;
`;

export const StyledCreditChipWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 4px;
    flex-wrap: wrap;

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 8px;
    }
`;

export const StyledCreditChip = styled.div`
    padding: 4px 10px;
    background: var(--credit-pill-bg);
    border-radius: 4px;
    color: var(--text-primary);
    font-size: 0.673rem;
    font-weight: 400;
`;
