/* eslint-disable react/jsx-filename-extension */
import { Router, route } from 'preact-router';
import { useEffect } from 'preact/hooks';

import useAnalytics from '@hooks/useAnalytics';
import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';
import useWindowDimensions from '@hooks/useWindowDimensions';

import AppState from '@state';
import resetGlobalState from '@state/reset';

import Redirect from '@distinct-components/navigation/redirect/Redirect';
import Login from '@routes/public/auth/login';
import SignUp from '@routes/public/auth/sign-up';
import ForgotPassword from '@routes/public/auth/forgot-password';
import ResetPassword from '@routes/restricted/auth/resetPassword';

import ArtistIdentityPreview from '@routes/public/identity/artist';
import CompositionIdentityPreview from '@routes/public/identity/composition';
import PublisherIdentityPreview from '@routes/public/identity/publisher';

import Landing from '@routes/restricted/core/landing';

import Register from '@routes/restricted/auth/register';
import Compositions from '@routes/restricted/core/compositions';
import CreateComposition from '@routes/restricted/core/compositions/createComposition';
import ArtistIdentityProfile from '@routes/restricted/core/artistIdentityProfile';
import Reps from '@routes/restricted/core/reps';
import ArtistActivateRep from '@routes/restricted/core/reps/artistActivateRep/ArtistActivateRep';
import ArtistAddRep from '@routes/restricted/core/reps/artistAddRep/ArtistAddRep';
import Home from '@routes/restricted/core/home';
import Contracts from '@routes/restricted/core/contracts';
import ContractView from '@routes/restricted/core/contracts/contractView/ContractView';
import CompositionWorkspace from '@routes/restricted/core/compositionWorkspace';
import Settings from '@routes/restricted/core/settings';
// import Tutorials from '@routes/restricted/core/tutorials';
import Checkout from '@routes/restricted/core/checkout/Checkout';
import Error from '@routes/error';
import Personalization from '@routes/restricted/onboarding/personalization/Personalization';
import WriterGetStarted from '@routes/restricted/onboarding/getStarted/writer';
import WriterInvitation from '@routes/restricted/invitations/writer';
import ConnectPublisherRequest from '@routes/restricted/invitations/connectPublisherRequest';
import Notifications from '@routes/restricted/notifications/Notifications';
import Membership from '@routes/restricted/core/payments/membership';
import HumanVerificationStart from '@routes/restricted/core/verification/humanVerificationStart';
import HumanVerificationProcessing from '@routes/restricted/core/verification/humanVerificationProcessing';

import Recordings from '@routes/restricted/core/recordings';
import RecordingWorkspace from '@routes/restricted/core/recordingWorkspace';
import ArtistAddRecordingToProject from '@routes/restricted/core/recordings/artistAddRecordingToProject';
import ArtistAddInitialRecordingCredits from '@routes/restricted/core/recordings/artistAddInitialRecordingCredits';
import ActivateRecordingPreview from '@routes/restricted/auth/activateRecordingPreview';

import PublisherGetStarted from '@routes/restricted/onboarding/getStarted/publisher';
import PublisherDashboard from '@routes/restricted/corePublisher/publisherDashboard';
import PublisherCompositions from '@routes/restricted/corePublisher/publisherCompositions';
import PublisherViewComposition from '@routes/restricted/corePublisher/publisherCompositions/publisherViewComposition';
import PublisherLists from '@routes/restricted/corePublisher/publisherLists';
import PublisherViewList from '@routes/restricted/corePublisher/publisherLists/publisherViewList';
import PublisherSongwriters from '@routes/restricted/corePublisher/publisherSongwriters';
import PublisherAddSingleWriter from '@routes/restricted/corePublisher/publisherSongwriters/publisherAddSingleWriter';
import PublisherTeam from '@routes/restricted/corePublisher/publisherTeam';
import PublisherRegistrations from '@routes/restricted/corePublisher/publisherRegistrations';
import PublisherConnections from '@routes/restricted/corePublisher/publisherConnections';
import PublisherAddConnection from '@routes/restricted/corePublisher/publisherConnections/publisherAddConnection';
import PublisherSettings from '@routes/restricted/corePublisher/publisherSettings';
import PublisherExports from '@routes/restricted/corePublisher/publisherExports/PublisherExports';
import PublisherUserAccount from '@routes/restricted/corePublisher/publisherUserAccount';

import RepDashboard from '@routes/restricted/coreRep/repDashboard';
import RepUserAccount from '@routes/restricted/coreRep/repUserAccount';
import RepSubscribe from '@routes/restricted/coreRep/repSubscribe/RepSubscribe';

import AdminAuth from '@routes/admin/adminAuth';
import AdminOrganizations from '@routes/admin/core/adminOrganizations';
import AdminOrganizationsUsers from '@routes/admin/core/adminOrganizationsUsers';
import AdminActions from '@routes/admin/core/adminActions';
import AdminMetrics from '@routes/admin/core/adminMetrics';

import PublisherInvitation from '@routes/restricted/invitations/publisher/PublisherInvitation';
import RepInvitation from '@routes/restricted/invitations/rep/RepInvitation';

import AudioPlayer from '@distinct-components/audioPlayer';
import SimpleToast from './distinct-components/feedback/simpleToast/SimpleToast';
import ExceptionOverlay from './shared-overlays/exceptionOverlay/ExceptionOverlay';
import ArtistRecordingProjectView from '../routes/restricted/core/recordings/artistRecordingProjectView/ArtistRecordingProjectView';

function App() {
  const analytics = useAnalytics();
  const width = useWindowDimensions();
  const recordingPreviewToken = localStorage.getItem('soundRecordingPreview');

  useEffect(() => {
    // REMOVE AFTER SOUND RECORDING LAUNCH
    if (recordingPreviewToken) {
      AppState.artistRecordings.isPreviewActive.value = true;
    }
    if (width < 768) {
      AppState.isMobile.value = true;
    } else {
      AppState.isMobile.value = false;
    }
    if (useAuthTokensValidCheck() && AppState.accountId.value > 0 && analytics !== null) {
      const userObject = {
        $name: 'Switchchord',
        $email: 'User',
        artist: AppState.artistProfile.uuid.value || 'Non-Artist',
        account: AppState.accountId.value || 'undefined',
        isRep: AppState.isRepresentative.value,
        isRepSession: AppState.repSession.isActive.value,
        pub: AppState.pubOrganization.uuid.value,
      };
      const userId = AppState.accountId.value.toString();
      analytics.identify(userId, userObject);
    }
  }, [AppState.accountId.value, width]);

  if (typeof window !== 'undefined') {
    const currentTheme = localStorage.getItem('theme');
    if (!currentTheme) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', currentTheme);
      analytics.track(`user-theme-prefers-${currentTheme}`);
    }
  }

  const resetErrors = () => {
    AppState.exceptions.errorOverlay.display.value = false;
    AppState.exceptions.errorOverlay.errorTitle.value = null;
    AppState.exceptions.errorOverlay.userMessage.value = null;
    AppState.exceptions.errorOverlay.errorMessage.value = null;
    AppState.exceptions.errorOverlay.buttonText.value = null;
    AppState.exceptions.errorOverlay.buttonPath.value = null;
  };

  const handleRoute = async (e) => {
    const isRecordingURL = e.url.includes('recording') || e.url.includes('recordings');
    if (isRecordingURL && !recordingPreviewToken) {
      route('/landing');
    }
    analytics.page();
    if (AppState.exceptions.errorOverlay.display.value === true) {
      resetErrors();
    }
    if (e.current.props.restricted) {
      if (!useAuthTokensValidCheck()) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        resetGlobalState();
        AppState.deepLinkUrl.value = window.location.pathname + window.location.search;
        route('/login', true);
      }
    }
    if (e.current.props.admin) {
      const token = localStorage.getItem('accessToken');
      const expiry = token ? parseInt(JSON.parse(atob(token.split('.')[1])).exp, 10) : null;
      const auth = expiry && (expiry * 1000 - 10000 > Date.now());
      const role = token ? JSON.parse(window.atob(token.split('.')[1])).role : null;
      if (!auth || role !== 'admin') {
        route('/patchbay');
      }
    }
  };

  return (
    <div id="app">
      <Router onChange={handleRoute}>

        {/* AUTH */}
        <Login path="/login" />
        <Redirect path="/" to="/login" />
        <SignUp path="/signup" />
        <ForgotPassword path="/forgot" />
        <Register path="/register" />
        <ResetPassword path="/reset" />

        {/* IDENTITY PREVIEWS */}
        <CompositionIdentityPreview path="/identity-viewer/composition/:id/:version" />
        <ArtistIdentityPreview path="/identity-viewer/artist/:uuid" />
        <PublisherIdentityPreview path="/identity-viewer/publisher/:uuid" />

        {/* INVITATIONS */}
        <WriterInvitation path="/invitation" />
        <ConnectPublisherRequest path="/connect-publisher" />
        <PublisherInvitation path="/publisher/invitation" />
        <RepInvitation path="/rep/invitation" />

        {/* PUBLIC CONTRACTS */}
        <ContractView path="/public/contracts/contract/:id/:version" />

        {/* ONBOARDING AND PRE CORE ROUTING */}
        <Landing path="/landing" restricted />
        <Personalization path="/personalize" restricted />
        <WriterGetStarted path="/get-started" restricted />

        {/* ARTIST ROUTES */}
        {/* TODO: Add "Artist" naming across all components */}
        <Home path="/home" restricted />
        <Contracts path="/contracts" restricted />
        <ContractView path="/contracts/contract/:id/:version" restricted />
        <Compositions path="/compositions" restricted />
        <CompositionWorkspace path="/workspace/:id" restricted />
        <CreateComposition path="/compositions/create" restricted />
        <ArtistIdentityProfile path="/profile" restricted />
        <HumanVerificationStart path="verify/get-started" restricted />
        <HumanVerificationProcessing path="verify/processing" restricted />
        <Settings path="/settings" restricted />
        {/* <Tutorials path="/tutorials" restricted /> */}
        <Notifications path="/events/:tab" restricted />
        <Reps path="/representation" restricted />
        <ArtistActivateRep path="/representation/activate/:id" restricted />
        <ArtistAddRep path="/representation/invite" restricted />
        <Checkout path="/checkout/success" success restricted />
        <Checkout path="/checkout/cancel" success={false} restricted />
        <Membership path="/membership" restricted />

        {/* RECORDING ROUTES */}
        <ActivateRecordingPreview path="/activations/sound/preview" restricted />
        <Recordings path="/recordings" restricted />
        <RecordingWorkspace path="/recording/:id" restricted />
        <ArtistAddRecordingToProject path="/recordings/create" restricted />
        <ArtistAddInitialRecordingCredits path="/recordings/add-credits" restricted />
        <ArtistRecordingProjectView path="/recordings/project/:id" restricted />

        {/* PUBLISHER ROUTES */}
        <PublisherGetStarted path="/publisher/:uuid/get-started" restricted />
        <PublisherDashboard path="/publisher/:uuid/dashboard" restricted />
        <PublisherCompositions path="/publisher/:uuid/compositions" restricted />
        <PublisherViewComposition path="/publisher/:uuid/compositions/:compositionUUID" restricted />
        <PublisherLists path="/publisher/:uuid/lists" restricted />
        <PublisherViewList path="/publisher/:uuid/lists/:listId" restricted />
        <PublisherSongwriters path="/publisher/:uuid/songwriters" restricted />
        <PublisherAddSingleWriter path="/publisher/:uuid/songwriters/invite" restricted />
        <PublisherTeam path="/publisher/:uuid/team" restricted />
        <PublisherRegistrations path="/publisher/:uuid/pro-affiliations" restricted />
        <PublisherConnections path="/publisher/:uuid/connections" restricted />
        <PublisherAddConnection path="/publisher/:uuid/connections/invite" restricted />
        <PublisherSettings path="/publisher/:uuid/settings" restricted />
        <PublisherExports path="/publisher/:uuid/exports" restricted />
        <PublisherUserAccount path="/publisher/my-account" restricted />

        {/* REP ROUTES */}
        <RepDashboard path="/rep/dashboard" restricted />
        <RepUserAccount path="/rep/account" restricted />
        <RepSubscribe path="/rep/subscribe" restricted />

        {/* SHARED ROUTES */}
        <Error path="/error/:errorType" default />

        {/* ADMIN ROUTES */}
        <AdminAuth path="/patchbay" admin />
        <AdminOrganizations path="patchbay/organizations" admin />
        <AdminOrganizationsUsers path="patchbay/organizations-users" admin />
        <AdminActions path="patchbay/actions" admin />
        <AdminMetrics path="patchbay/metrics" admin />
      </Router>

      <AudioPlayer uuid={AppState.composition.uuid.value} />

      {/* GLOBAL COMPONENTS */}
      <SimpleToast />
      {AppState.exceptions.errorOverlay.display.value === true
        && <ExceptionOverlay />}
    </div>
  );
}

export default App;
