import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const StyledProjectViewWrapper = styled.div(({ isEditing, mobileScrollLimiter }) => `
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0em 2em;
    background: var(--background);
    -webkit-overflow-scrolling: touch;
    ${(isEditing || mobileScrollLimiter) && 'overflow: hidden; height: 100%'};

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 0em 1em;
    }
    
    ${space};
`);

export const HeaderWrapper = styled.div`
    padding: 1em 0em;
    border-bottom: 1px solid var(--border);
    position: sticky;
    top: 0;
    background: var(--background);
    z-index: 200;
    ${space};
`;

export const MobileHeaderContent = styled.div`
    display: none;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display:flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        min-height: 44px;
    }
`;

export const MobileTitleWrapper = styled.div`
    flex: 1;
    margin-left: 1em;
`;

export const HeaderContent = styled.div`
    display:flex;
    justify-content: space-between;
    min-height: 44px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display:none;
    }
`;

export const HeaderDetails = styled.div`
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    ${space};
`;

export const HeaderActions = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: center;
    ${space};
`;

export const PageWrapper = styled.div`
    display:flex;
    justify-content: center;
    ${space};
`;

export const PageContent = styled.div`
    width:100%;
    max-width: 1440px;
    ${space};
`;

export const StyledProjectHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 4rem;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
        margin-top: 2rem;
        align-items: flex-start;
        gap: 1rem;
    }
`;

export const StyledProjectImageTitleWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.875rem;

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const StyledProjectImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 0.5rem;
    height: 88px;
    width: 88px;
    flex-shrink: 0;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 56px;
        width: 56px;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        height: 104px;
        width: 104px;
    }
`;

export const StyledProjectImage = styled.img`
    height: 100%;
    width: 100%;
`;

export const StyledProjectImageEmpty = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--pill-base-bg);
`;

export const StyleProjectTitleText = styled(Text)`
    font-size: 1.35rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font-weight: 600;
    padding-top: 0.875rem;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.2rem;
        padding-top: 0.5rem;
    }
`;
