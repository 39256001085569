import {
  useCallback, useState, useRef, useEffect,
} from 'preact/hooks';
import { Fragment } from 'preact';
import { QRCodeSVG } from 'qrcode.react';
import AppState from '@state';
import PropTypes from 'prop-types';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';

import { getArtistRecentCollaborators } from '@api/restricted/artist-collaborators-api'; // needs to be updated to look at rec
import { getArtistLookupWithEmail } from '@api/restricted/artist-lookup-api';
// import { postWorkspaceAccount } from '@api/restricted/workspace-account-api'; // needs to be updated/versioned for rec
import { postWorkspaceAccountInviteJoinToken } from '@api/restricted/workspace-account-invite-api'; // needs to be updated/versioned for rec

import useDebounce from '@hooks/useDebounce';
import useWindowDimensions from '@hooks/useWindowDimensions';

import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseButton from '@ui-kit/buttons/baseButton';
import Spinner from '@ui-kit/loaders/Spinner';
import BreakLine from '@ui-kit/dividers/breakLine';
import Avatar from '@ui-kit/avatar';
import AlertMessage from '@ui-kit/alert/Alert';
import MailWedge from '@animations/wedges/MailWedge';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import LinkButton from '@ui-kit/buttons/linkButton';
import IconQR from '@assets/icons/qr-outline.svg';
import Icon from '@ui-kit/icon';
import BaseUserCard from '@distinct-components/cards/baseUserCard';
import EmptyStateList from '@distinct-components/feedback/emptyStateList/EmptyStateList';

import {
  QrWrapper,
  QrControlItem,
  QrButton,
  RecentWriterWrapper,
} from './ArtistAddNewRecordingParticipantStyles';

function ArtistAddNewRecordingParticipant({
  activeUser,
  recordingData,
  handleArtistAdded,
  ...restProps
}) {
  const [projectData, setProjectData] = useState(null);
  const [recentArtists, setRecentArtists] = useState([]);
  const [recentQuery, setRecentQuery] = useState('');
  const [recentLimit, setRecentLimit] = useState(3);
  const [showAllRecent, setShowAllRecent] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState();
  const [existingUser, setExistingUser] = useState(null);
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');
  const [inviteProjectAccessLevel, setInviteProjectAccessLevel] = useState('full');
  const [invitePermission, setInvitePermission] = useState('edit');
  const [isSearching, setIsSearching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [qrCodeLink, setQrCodeLink] = useState(null);

  const loadRecordingProjectData = () => {
    // use recording.projectId to fetch the existing project people.
    const mockData = [];
    setProjectData(mockData);
    console.log(projectData);
  };

  useEffect(async () => {
    // Get project id from the recording data.
    // present quick option to add people from project to this recording
    // allow add of new users to the recording, set of recordings, or the entire project
    loadRecordingProjectData();
  }, []);

  const searchRef = useRef(null);
  const inputIPI = useRef(null);

  const { width } = useWindowDimensions();

  const useToastAlert = (message, type) => {
    AppState.messages.toastMessage.value = { text: message, variant: type };
  };

  const loadAccountRecentWriters = async () => {
    try {
      const response = await getArtistRecentCollaborators(1, 50); // MOCK MOCK MOCK TODO: UPDATE THIS
      if (response.status !== 200) {
        setRecentArtists([]);
      } else {
        const json = await response.json();
        setRecentArtists(json.recentCollaborators);
      }
    } catch (err) {
      setRecentArtists([]);
    }
  };

  const handleGetQrCodeLink = (isAdmin, isWriter, canEdit) => {
    postWorkspaceAccountInviteJoinToken(1, isAdmin, isWriter, canEdit) // MOCK MOCK MOCK TODO: UPDATE THIS
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setQrCodeLink(json.url);
          });
        } else {
          response.json()
            .then((json) => useToastAlert(json.error || response.statusText, 'error'))
            .catch(() => useToastAlert(response.statusText, 'error'));
        }
      })
      .catch((err) => {
        useToastAlert(err.message, 'error');
      });
  };

  useEffect(() => {
    loadAccountRecentWriters();
  }, []);

  const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const handleLookupApiCall = (email) => {
    getArtistLookupWithEmail(email, -1, -1)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setError('');
            const existing = json.map((user) => ({
              id: user.accountId,
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              imgURL: user.imgURL,
              isVerified: user.isVerified,
              email: user.email,
            }));
            setExistingUser(existing[0] || null);
            setIsSearching(false);
          });
        } else if (response.status === 202) {
          setExistingUser(null);
          setIsSearching(false);
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsSearching(false);
        }
        setGuestFirstName('');
        setGuestLastName('');
      })
      .catch((err) => {
        setError(err.message);
        setIsSearching(false);
      });
  };

  const handleChangeEmailToInvite = (event) => {
    event.persist();
    const userInput = event.target.value;

    setExistingUser(null);
    setGuestFirstName('');
    setGuestLastName('');
    setInviteProjectAccessLevel('limited');
    setInvitePermission('edit');

    if (validateEmail(userInput) != null) {
      setIsSearching(true);
      setEmailToInvite(userInput.toLowerCase());
      handleLookupApiCall(userInput.toLowerCase());
    } else {
      setEmailToInvite(null);
    }
  };

  const optimizedFn = useCallback(useDebounce(handleChangeEmailToInvite), []);

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ');
  };

  const onClickContinue = () => {
    setError('');

    const cleanedGuestFirstName = cleanName(guestFirstName);
    const cleanedGuestLastName = cleanName(guestLastName);

    if (!existingUser && (!cleanedGuestFirstName || !cleanedGuestLastName)) {
      setError('Guest artist needs a first and last name');
      return;
    }

    setIsSaving(true);

    const mockArtistAdded = {
      id: 8,
      firstName: 'Frank',
      lastName: 'Harolds',
      isni: '0000 1111 2222 3333',
      imgExists: false,
      isVerified: false,
      isFeaturedArtist: false,
      credits: [],
      creditsPersona: null,
      creditsVerified: true,
      labelCredential: null,
    };

    // MOCK FOR TIME
    setTimeout(() => {
      handleArtistAdded(mockArtistAdded);
    }, 1400);

    // const accountToAdd = existingUser ? {
    //   id: existingUser.id,
    // } : {
    //   email: emailToInvite,
    // };

    // postWorkspaceAccount(
    //   1, // MOCK MO
    //   accountToAdd,
    //   existingUser ? null : cleanedGuestFirstName,
    //   existingUser ? null : cleanedGuestLastName,
    //   inviteProjectAccessLevel,
    //   invitePermission,
    //   metadata,
    //   suggestions,
    // )
    //   .then((response) => {
    //     if (response.status === 200) {
    //       setTimeout(() => {
    //         setError('');
    //         setIsSaving(false);
    //         handleCloseFunction();
    //       }, 2000);
    //     } else {
    //       response.json()
    //         .then((json) => setError(json.error ? json.error : response.statusText))
    //         .catch(() => setError(response.statusText));
    //       setIsSaving(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setIsSaving(false);
    //     setError(err.message);
    //   });
  };

  const onClickViewAllWriters = () => {
    setRecentQuery('');
    if (recentLimit > 3) {
      setRecentLimit(3);
    } else {
      setRecentLimit(50);
    }
    setShowAllRecent(!showAllRecent);
  };

  const onClickRecentWriter = (writer) => {
    setRecentQuery('');
    setShowAllRecent(false);
    setEmailToInvite(writer.email);
    if (writer.id) {
      setExistingUser(writer);
    } else {
      setExistingUser(null);
      setGuestFirstName(writer.firstName);
      setGuestLastName(writer.lastName);
    }
    setInviteProjectAccessLevel('limited');
    setInvitePermission('edit');
  };

  const handleQrPermissionChange = (event) => {
    const selection = event.target.value;
    if (selection === 'Admin') {
      handleGetQrCodeLink(true, true, true);
    } else if (selection === 'Edit') {
      handleGetQrCodeLink(false, true, true);
    } else if (selection === 'Restricted') {
      handleGetQrCodeLink(false, true, false);
    } else {
      useToastAlert('Invalid Permission Chosen', 'error');
    }
  };

  return (
    <Box {...restProps}>
      <Box>
        {!qrCodeLink && (
          <Fragment>
            <InputLabel label="Add Artist by Email" />
            <Box display="flex" flexDirection="row-reverse" alignItems="center" mb="1.5em" position="relative">
              <BaseInput
                ref={searchRef}
                id="userTypeAhead"
                name="userTypeAhead"
                placeholder="artist@email.com"
                onChange={optimizedFn}
                value={emailToInvite}
                type="email"
                fluid
                required
                autoComplete="off"
              />
              <Box style={{ position: 'absolute', right: 16 }}>
                {(isSearching) && <Spinner variant="fieldLoader" size="1.125em" />}
              </Box>
            </Box>
          </Fragment>
        )}

        {recentArtists.length > 0 && !emailToInvite && !qrCodeLink
          && (
            <Fragment>
              <InputLabel label="Add a Recent Artist" />
              {showAllRecent && (
                <BaseInput
                  id="recentQuery"
                  name="recentQuery"
                  placeholder="Search Recent..."
                  onChange={(e) => setRecentQuery(e.target.value)}
                  type="text"
                  fluid
                  autoComplete="off"
                  mb="0.75rem"
                />
              )}
              <RecentWriterWrapper>
                {recentQuery === ''
                  ? (
                    <Fragment>
                      {recentArtists.slice(0, recentLimit).map((writer) => (
                        <BaseUserCard user={writer} onClickCard={onClickRecentWriter} />
                      ))}
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      {recentArtists
                        .filter((writer) => (writer.firstName + writer.lastName).toLowerCase().includes(recentQuery.toLowerCase())).slice(0, recentLimit).map((writer) => (
                          <BaseUserCard user={writer} onClickCard={onClickRecentWriter} />
                        ))}
                    </Fragment>
                  )}
              </RecentWriterWrapper>

              {recentArtists.filter((writer) => (writer.firstName + writer.lastName).toLowerCase().includes(recentQuery.toLowerCase())).length === 0 && (
                <EmptyStateList mb="0.875rem" message="No Writers Match that Name" />
              )}

              {(recentArtists.length > recentLimit || showAllRecent)
                && (
                  <Box display="flex" justifyContent="flex-end">
                    <LinkButton btnText={showAllRecent ? 'View Less' : 'View More'} variant="small" color="var(--text-primary)" onClick={onClickViewAllWriters} />
                  </Box>
                )}
            </Fragment>
          )}

        {!emailToInvite
          && (
            <Box mt="1.25rem">
              <InputLabel label="Add Writer by QR Code" />

              {!qrCodeLink
                ? (
                  <QrButton onClick={() => handleGetQrCodeLink(false, true, true)}>
                    <Icon size="1.65rem" mr="0.875rem" pt="2px" color="var(--blue-400)">
                      <IconQR />
                    </Icon>
                    <Text>Generate QR Code</Text>
                  </QrButton>
                )
                : (
                  <Fragment>
                    <BreakLine mb="1.5em" mt="0.25rem" color="var(--panel-info-border)" />
                    <QrWrapper>
                      <QRCodeSVG
                        size={width > 780 ? '420px' : '320px'}
                        fgColor="#fcfcfc"
                        bgColor="#222222"
                        level="L"
                        value={qrCodeLink}
                      />
                      <QrControlItem>
                        <InputLabel fontSize="0.688rem" label="QR Code Permissions" />
                        <BaseSelect
                          fluid
                          variant="small"
                          mb="0.25rem"
                          defaultValue="Edit"
                          onChange={(event) => handleQrPermissionChange(event)}
                        >
                          <option value="Restricted">View Only</option>
                          <option value="Edit" default selected>Can Edit</option>
                          <option value="Admin">Moderator</option>
                        </BaseSelect>
                      </QrControlItem>
                      <Box display="flex" justifyContent="center">
                        <LinkButton btnText="Go Back" variant="small" color="var(--text-primary)" onClick={() => setQrCodeLink(null)} />
                      </Box>
                    </QrWrapper>
                  </Fragment>
                )}
            </Box>
          )}

      </Box>

      {(emailToInvite && !isSearching) && (
        <Fragment>
          {!existingUser?.id ? (
            <Fragment>
              <Box display="flex" mb="1.5em" alignItems="center">
                <Box mr="0.75em">
                  <MailWedge />
                </Box>
                <Box>
                  <Text fontWeight="600" fontSize="0.875rem">
                    Guest Writer
                  </Text>
                  {/* ONCE WE GET THINGS WIRED. ADD TEXT THAT EXPLAINS KNOWN GUEST VS NEW GUEST */}
                  <Text fontSize="0.813rem">They can join or just sign the split sheet.</Text>
                </Box>
              </Box>
              <BreakLine mb="1.5em" mt="1em" color="var(--panel-info-border)" />
              {!existingUser?.firstName && (
                <Box display="flex" mb="1.5em" alignItems="center">
                  <Box width="100%" mr="1.125rem">
                    <InputLabel label="First Name" />
                    <BaseInput
                      ref={inputIPI}
                      id="firstName"
                      name="firstName"
                      onChange={(e) => setGuestFirstName(e.target.value)}
                      value={guestFirstName}
                      fluid
                      type="text"
                      autoComplete="off"
                    />
                  </Box>
                  <Box width="100%">
                    <InputLabel label="Last Name" />
                    <BaseInput
                      ref={inputIPI}
                      id="lastName"
                      name="lastName"
                      onChange={(e) => setGuestLastName(e.target.value)}
                      value={guestLastName}
                      fluid
                      type="text"
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              )}
            </Fragment>
          ) : (
            <Box display="flex" mb="1.5em" alignItems="center">
              <Box mr="0.75em">
                <Avatar size="3em" mb="1em" imgURL={existingUser.imgURL} isVerified={existingUser.isVerified} checkSize="1em" checkY="4px" checkX="-2px" />
              </Box>
              <Box>
                <Text fontWeight="600" fontSize="0.75rem">
                  Registered User
                </Text>
                <Text>
                  {existingUser.firstName}
                  {' '}
                  {existingUser.lastName}
                </Text>
              </Box>
            </Box>
          )}

          <Box>
            <InputLabel label="What Recordings in the Project can they access?" />
            <BaseSelect
              onChange={(e) => setInviteProjectAccessLevel(e.target.value)}
              fluid
              mb="1.5em"
            >
              {[
                { value: 'full', text: 'Any Recording in the Project' },
                { value: 'limited', text: 'Only Recordings where they are added' },
              ].map((o) => (
                <option value={o.value} default={o.value === inviteProjectAccessLevel} selected={o.value === inviteProjectAccessLevel}>{o.text}</option>
              ))}
            </BaseSelect>

            <InputLabel label="What can the artist edit?" />
            <BaseSelect
              onChange={(e) => setInvitePermission(e.target.value)}
              fluid
              mb="0.25em"
            >
              {[
                { value: 'restricted', text: 'Only their credits' },
                { value: 'edit', text: 'Any credits or metadata' },
                { value: 'admin', text: 'Admin Access - Edit/Manage All Data' },
              ].map((o) => (
                <option value={o.value} default={o.value === invitePermission} selected={o.value === invitePermission}>{o.text}</option>
              ))}
            </BaseSelect>
            <InputLabel mt="4px" mb="1.5rem" color="var(--text-medium-mute)" label="Only applies if they join to collaborate" />

          </Box>

          <Fragment>
            <BreakLine mb="1.5em" mt="0.5em" color="var(--border)" />

            {error && (
              <AlertMessage
                variant="negative"
                message={error}
                mb="1.5em"
              />
            )}

            <Box display="flex" justifyContent="flex-end">
              <BaseButton
                mb="1.5em"
                btnText="Invite & Add Credits"
                onClick={onClickContinue}
                disabled={isSaving}
                isLoading={isSaving}
                fluid
              />
            </Box>
          </Fragment>
        </Fragment>
      )}
    </Box>
  );
}

ArtistAddNewRecordingParticipant.propTypes = {
  activeUser: PropTypes.object.isRequired,
  handleArtistAdded: PropTypes.func.isRequired,
  recordingData: PropTypes.object.isRequired,
};

export default ArtistAddNewRecordingParticipant;
