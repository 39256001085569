import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledTrackImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 0.5rem;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    flex-shrink: 0;
    ${space};
`;

export const StyledTrackImage = styled.img`
    height: 100%;
    width: 100%;
`;

export const StyledTrackImageEmpty = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--player-image-empty-bg);
`;

export const StyledPlayIconWrapper = styled.div`
   position: absolute;
   cursor: pointer;
`;
