/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'preact/hooks';
import AppState from '@state';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Tooltip from '@distinct-components/feedback/tooltip';
import Icon from '@ui-kit/icon';
import NoteIcon from '@assets/icons/single-note-square.svg';
import TrashIcon from '@assets/icons/trash-outline.svg';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import {
  StyledCreditContainer,
  StyledCreditDisplayInput,
  StyledContentEditableSection,
  StyledPersonaChip,
} from './CreditSelectorAddedCreditStyles';

function CreditSelectorAddedCredit({
  credit,
  handleRemoveCredit,
  handleUpdateCredit,
  canManagePersonalPrefs,
  ...restProps
}) {
  const [isDefault, setIsDefault] = useState(credit.isDefault);

  const prefixDivRef = useRef(null);
  const suffixDivRef = useRef(null);

  const onChangeDisplayCreditPrefix = () => {
    credit.displayPrefix = prefixDivRef.current.innerText;
    handleUpdateCredit(credit);
  };

  const onChangeDisplayCreditSuffix = () => {
    credit.displaySuffix = suffixDivRef.current.innerText;
    handleUpdateCredit(credit);
  };

  // TODO: get icon from dictionary
  useEffect(() => {
    const div1 = prefixDivRef.current;
    const div2 = suffixDivRef.current;
    if (div1) {
      div1.addEventListener('input', onChangeDisplayCreditPrefix);
    }
    if (div2) {
      div2.addEventListener('input', onChangeDisplayCreditSuffix);
    }

    if (credit.displayPrefix && credit.displayPrefix.length > 0) {
      prefixDivRef.current.innerText = credit.displayPrefix;
    }
    if (credit.displaySuffix && credit.displaySuffix.length > 0) {
      suffixDivRef.current.innerText = credit.displaySuffix;
    }
    return () => {
      if (div1) {
        div1.removeEventListener('input', onChangeDisplayCreditPrefix);
      }
      if (div2) {
        div2.removeEventListener('input', onChangeDisplayCreditSuffix);
      }
    };
  }, []);

  const onToggleDefaultCredit = () => {
    if (isDefault) {
      credit.isDefault = false;
    } else {
      credit.isDefault = true;
    }
    handleUpdateCredit(credit);
    setIsDefault(!isDefault);
  };

  return (
    <StyledCreditContainer {...restProps}>

      <Box mb="0.875rem" display="flex" justifyContent="space-between" alignItems="center">

        <Box display="flex" alignItems="center">
          <Box>
            <Icon pt="2px" mr="0.75rem" size="1.25rem"><NoteIcon /></Icon>
          </Box>
          <Text fontSize="0.938rem" variant="selectDisabled" fontWeight="500">{credit.displayName}</Text>
        </Box>

        <Icon onClick={() => handleRemoveCredit(credit)} pt="2px" mr="0.75rem" size="1rem" cursor><TrashIcon /></Icon>

      </Box>

      <Box>
        <Box mb="0.25rem" display="flex" alignItems="center">
          <Text mr="0.5rem">How it will Display</Text>
          <Tooltip
            overPanel
            width="250px"
            top="10px"
            left="4px"
            bottom="auto"
            right="auto"
            prompt="While standard industry values are saved for your credit, some streaming platforms will show your credit the way you want it to read."
          />
        </Box>
        <StyledCreditDisplayInput>
          <StyledContentEditableSection
            ref={prefixDivRef}
            contentEditable="true"
            onChange={(e) => onChangeDisplayCreditPrefix(e.target.value)}
          />
          {/* WILL EXTEND THIS CHIP TO ALLOW SELECTING PERSONAS */}
          <StyledPersonaChip onClick={() => alert('Extend to allow user to change persona with popover menu if can manage personal prefs.')}>
            {AppState.userProfile.firstName.value}
            {' '}
            {AppState.userProfile.lastName.value}
          </StyledPersonaChip>
          <StyledContentEditableSection
            ref={suffixDivRef}
            contentEditable="true"
            onChange={(e) => onChangeDisplayCreditSuffix(e.target.value)}
          />
        </StyledCreditDisplayInput>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mt="0.875rem">
        <Text color="var(--text-secondary)" fontSize="0.813rem" opacity="0.6">
          DDEX:
          {' '}
          {credit.ddexName}
        </Text>

        {canManagePersonalPrefs && (
        <Box display="flex" justifyContent="flex-end">
          <Text fontSize="0.875rem" color="var(--text-secondary)">Default</Text>
          <ToggleSwitch id={`isDefault-${credit.id}`} ml="0.75rem">
            <input
              type="checkbox"
              name={`isDefault-${credit.id}`}
              id={`isDefault-${credit.id}`}
              checked={isDefault}
              onChange={onToggleDefaultCredit}
            />
          </ToggleSwitch>
        </Box>
        )}

      </Box>

    </StyledCreditContainer>
  );
}

CreditSelectorAddedCredit.propTypes = {
  credit: PropTypes.object.isRequired,
  handleRemoveCredit: PropTypes.func.isRequired,
  handleUpdateCredit: PropTypes.func.isRequired,
  canManagePersonalPrefs: PropTypes.bool,
};

CreditSelectorAddedCredit.defaultProps = {
  canManagePersonalPrefs: true,
};

export default CreditSelectorAddedCredit;
