/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const CardWrapper = styled.div`
    background: var(--card-over-card-bg);
    border-radius: 1em;
    min-height: 14rem;
    width: 100%;
    border: 0.8px solid var(--card-border);
    padding: 1.5rem;
    cursor: pointer;
    ${space};
    ${layout};

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 0.875rem;
    }
`;

export const StyledImageTitleWrapper = styled.div`
    display: flex;
    gap: 1.25rem;
    border-bottom: 1px solid var(--panel-divider);
    padding-bottom: 24px;
    align-items: flex-start;

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding-bottom: 16px;
    }
`;

export const StyledProjectImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 0.5rem;
    height: 88px;
    width: 88px;
    flex-shrink: 0;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 56px;
        width: 56px;
    }

    @media screen and (min-width: ${pointBreaks.lg}) {
        height: 104px;
        width: 104px;
    }
`;

export const StyledProjectImage = styled.img`
    height: 100%;
    width: 100%;
`;

export const StyledProjectImageEmpty = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--pill-base-bg);
`;

export const StyleProjectTitleText = styled(Text)`
    font-size: 1.35rem;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font-weight: 600;
    padding-top: 0.875rem;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1.2rem;
        padding-top: 0.5rem;
    }
`;
