/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import Card from '@distinct-components/cards/baseCard';
import pointBreaks from '@styles/styleConstants';

export const StyledToolsCard = styled(Card)(({
  showTools,
}) => `
    box-shadow: ${showTools ? '-72px 4px 160px var(--side-panel-shadow)' : 'none'};
    width: ${showTools ? '100%' : '0%'};
    display: ${showTools ? 'block' : 'none'};
    background: var(--card);
    position: fixed;
    top: 120px;
    right: 0;
    bottom: 0;
    max-width: 24em;
    transition: all 600ms;
    padding: 0;
    overflow-y: scroll;
    height: auto;
    margin-bottom: 0em;
    ${space};

      @media screen and (min-width: ${pointBreaks.xxl}) {
        display: block;
        position: inherit;
        width: 100%;
        max-width: 28em;
        transition: none;
      }

      @media screen and (max-width: ${pointBreaks.sm}) {
        top: 0px;
        max-width: 100%;
        z-index: 1000;
    }
`);

export const ToolsCardHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid var(--border);
   margin-left: 1em;
   padding: 0.875em 0.875em 0.875em 0em;
`;

export const ClosePanelWrapper = styled.div`
   display: block;

   @media screen and (min-width: ${pointBreaks.xxl}) {
    display: none;
  }
`;

export const StyledToolsCardContent = styled.div`
  height: calc(100vh - 185px);
  overflow-y: scroll;

  @media screen and (min-width: ${pointBreaks.xl}) {
    height: auto;
    overflow: visible;
  }
`;
