/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const AvatarCollectionWrapper = styled.div`
    display: flex;
    align-items: center;
    ${space};
`;

export const AvatarRow = styled.div(
  ({
    userCount,
  }) => `
      display: grid;
      grid-template-columns: repeat(${userCount + 1}, 1.25rem);
   
       @media screen and (max-width: ${pointBreaks.sm}) {
           display: grid;
       }
       ${space}
    `,
  space,
);
