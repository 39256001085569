import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import ArtistMenu from './menus/artistMenu';
import PublisherMenu from './menus/publisherMenu';

import {
  SideBarContainer,
  MobileNavToggleWrapper,
  MobileNavToggle,
} from './CoreSideNavStyles';

function CoreSideNav({
  mode,
  ...restProps
}) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleMenuChange = () => {
    setShowMobileMenu(!showMobileMenu);
    // RESET PERSISTED PAGINATION WHEN CHANGING GLOBAL SPACE
    Object.keys(AppState.pagination.value).forEach((key) => {
      AppState.pagination[key].value = 1;
    });
  };

  return (
    <Fragment>
      <MobileNavToggleWrapper onClick={handleMenuChange}>
        <MobileNavToggle>
          <div className={`bar ${showMobileMenu && 'rotate-forward'}`} />
          <div className={`bar ${showMobileMenu && 'hide'}`} />
          <div className={`bar ${showMobileMenu && 'rotate-back'}`} />
        </MobileNavToggle>
      </MobileNavToggleWrapper>
      <SideBarContainer {...restProps} showMobileMenu={showMobileMenu}>
        {
        {
          artist: <ArtistMenu handleMenuChange={handleMenuChange} />,
          publisher: <PublisherMenu handleMenuChange={handleMenuChange} />,
        }[mode]
      }
      </SideBarContainer>
    </Fragment>
  );
}

CoreSideNav.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default CoreSideNav;
