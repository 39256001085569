import dayjs from 'dayjs';
import AppState from '@state';
import { getOrganization } from '@api/restricted/organization-api';
import useErrorOverlay from '@hooks/useErrorOverlay';

export default async function loadOrganization(uuid, { forcedLoad }) {
  if (forcedLoad || AppState.pubOrganization.uuid.value !== uuid) {
    try {
      const response = await getOrganization(uuid);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading organization` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading organization` });
        }
      } else {
        const json = await response.json();
        AppState.pubOrganization.id.value = json.id;
        AppState.pubOrganization.uuid.value = json.uuid;
        AppState.pubOrganization.name.value = json.name;
        AppState.pubOrganization.logoURL.value = json.logoURL;
        AppState.pubOrganization.customTags.value = json.customTags.sort((a, b) => a.id - b.id);
        AppState.pubOrganization.customStatuses.value = json.customStatuses.sort((a, b) => a.id - b.id);
        AppState.pubOrganization.receiveSummaryEmail.value = json.emailNotifications;
        AppState.pubOrganization.automaticRegistrationHold.value = json.automaticHold;
        AppState.orgConnections.value = json.connections.map((pub) => ({
          ...pub,
          isActive: !pub.inactiveDate || dayjs(pub.inactiveDate).isAfter(dayjs()),
        }));
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading organization` });
    }
  }
}
