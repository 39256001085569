/* eslint-disable import/prefer-default-export */
export const MOCK_PROJECT_DATA = [
  {
    id: 1,
    projectArtworkURL: 'https://switchchord-static-hosting.s3.amazonaws.com/mock_recording_assets/projectC.png',
    projectName: 'Down the Road',
    projectDisplayArtist: 'Carl Clifton',
    totalRecordings: 5,
    totalRecordingsFinalized: 0,
    missingCompositionLinkCount: 3,
    connectedLabels: [
      {
        id: 23,
        name: 'UMG',
      },
    ],
    participantSummary: [
      {
        id: 1,
        name: 'John Smith',
        imgExists: true,
      },
      {
        id: 2,
        name: 'Susie Smith',
        imgExists: true,
      },
    ],
  },
  {
    id: 2,
    projectArtworkURL: 'https://switchchord-static-hosting.s3.amazonaws.com/mock_recording_assets/projectA.png',
    projectName: 'Sunrise Serenade on the Boulevard',
    projectDisplayArtist: 'Ellen Adams',
    totalRecordings: 8,
    totalRecordingsFinalized: 4,
    missingCompositionLinkCount: 1,
    connectedLabels: [
      {
        id: 34,
        name: 'Sony Music',
      },
    ],
    participantSummary: [
      {
        id: 3,
        name: 'Michael Brown',
        imgExists: true,
      },
      {
        id: 4,
        name: 'Lucy Green',
      },
    ],
  },
  {
    id: 3,
    projectArtworkURL: 'https://switchchord-static-hosting.s3.amazonaws.com/mock_recording_assets/projectB.png',
    projectName: 'Moonlit Memories',
    projectDisplayArtist: 'David Ross',
    totalRecordings: 10,
    totalRecordingsFinalized: 7,
    missingCompositionLinkCount: 2,
    connectedLabels: [
      {
        id: 45,
        name: 'Warner Music',
      },
    ],
    participantSummary: [
      {
        id: 5,
        name: 'Emma Wilson',
      },
      {
        id: 6,
        name: 'Oliver White',
      },
    ],
  },
  {
    id: 4,
    projectArtworkURL: null,
    projectName: 'Echoes of Time',
    projectDisplayArtist: 'Nina Taylor',
    totalRecordings: 6,
    totalRecordingsFinalized: 3,
    missingCompositionLinkCount: 0,
    connectedLabels: [
      {
        id: 56,
        name: 'Universal Music',
      },
    ],
    participantSummary: [
      {
        id: 7,
        name: 'James Moore',
      },
      {
        id: 8,
        name: 'Paula King',
      },
      {
        id: 9,
        name: 'Joe Shmo',
      },
      {
        id: 10,
        name: 'Paul Kirby',
      },
    ],
  },
  {
    id: 5,
    projectArtworkURL: 'https://switchchord-static-hosting.s3.amazonaws.com/mock_recording_assets/projectD.png',
    projectName: 'Rhythms of the Heart',
    projectDisplayArtist: 'Liam Johnson',
    totalRecordings: 7,
    totalRecordingsFinalized: 5,
    missingCompositionLinkCount: 4,
    connectedLabels: [
      {
        id: 67,
        name: 'EMI',
      },
    ],
    participantSummary: [
      {
        id: 9,
        name: 'Ava Martinez',
      },
      {
        id: 10,
        name: 'William Anderson',
      },
    ],
  },
];

export const MOCK_SINGLE_PROJECT = {
  projectName: 'Rhythms of the Heart',
  projectDisplayArtist: 'Liam Johnson',
  projectArtworkURL: 'https://switchchord-static-hosting.s3.amazonaws.com/mock_recording_assets/projectD.png',
  primaryLabelName: 'Steamroller Records',
  recordings: [
    {
      id: 1,
      trackNumber: 1,
      recordingTitle: 'Whispering Pines',
      totalCredits: 8,
      compositionLinked: true,
      stemsAdded: false,
      fileVersion: 1,
      isFinalFile: true,
    },
    {
      id: 2,
      trackNumber: 2,
      recordingTitle: 'Road to Nowhere',
      totalCredits: 11,
      compositionLinked: true,
      stemsAdded: false,
      isFinalFile: true,
      fileVersion: 1,
    },
    {
      id: 3,
      trackNumber: 3,
      recordingTitle: 'Midnight Train',
      totalCredits: 15,
      compositionLinked: false,
      stemsAdded: false,
      isFinalFile: false,
      fileVersion: 0,
    },
    {
      id: 4,
      trackNumber: 4,
      recordingTitle: 'Echoes of the Past',
      totalCredits: 12,
      compositionLinked: false,
      stemsAdded: false,
      isFinalFile: false,
      fileVersion: 1,
    },
    {
      id: 5,
      trackNumber: 5,
      recordingTitle: 'Wandering Heart',
      totalCredits: 22,
      compositionLinked: true,
      stemsAdded: true,
      isFinalFile: false,
      fileVersion: 1,
    },
    {
      id: 6,
      trackNumber: 6,
      recordingTitle: 'Broken Dreams',
      totalCredits: 9,
      compositionLinked: false,
      stemsAdded: false,
      isFinalFile: false,
      fileVersion: 1,
    },
  ],
  participantSummary: [
    {
      id: 1,
      firstName: 'James',
      lastName: 'Moore',
      name: 'James Moore',
      imgExists: true,
      credits: [
        {
          recordingName: 'Track 1',
          credits: ['displayName, displayName'],
        },
        {
          recordingName: 'Track 2',
          credits: ['displayName, displayName'],
        },
        {
          recordingName: 'Track 3',
          credits: ['displayName, displayName'],
        },
      ],
    },
    {
      id: 2,
      firstName: 'Paula',
      lastName: 'King',
      name: 'Paula King',
      imgExists: false,
      credits: [
        {
          recordingName: 'Track 1',
          credits: ['displayName, displayName'],
        },
        {
          recordingName: 'Track 2',
          credits: ['displayName, displayName'],
        },
        {
          recordingName: 'Track 3',
          credits: ['displayName, displayName'],
        },
      ],
    },
    {
      id: 3,
      firstName: 'Joe',
      lastName: 'Shmo',
      name: 'Joe Shmo',
      imgExists: true,
      credits: [
        {
          recordingName: 'Track 1',
          credits: ['displayName, displayName'],
        },
        {
          recordingName: 'Track 2',
          credits: ['displayName, displayName'],
        },
        {
          recordingName: 'Track 3',
          credits: ['displayName, displayName'],
        },
      ],
    },
    {
      id: 4,
      firstName: 'Paul',
      lastName: 'Kirby',
      name: 'Paul Kirby',
      imgExists: false,
      credits: [
        // {
        //   recordingName: 'Track 1',
        //   credits: ['displayName, displayName'],
        // },
        // {
        //   recordingName: 'Track 2',
        //   credits: ['displayName, displayName'],
        // },
        // {
        //   recordingName: 'Track 3',
        //   credits: ['displayName, displayName'],
        // },
      ],
    },
  ],
};

export const MOCK_SINGLE_RECORDING = {
  id: 19,
  uuid: '90d9gd0sdg9dsg090dgs90', // discuss ids
  updatedAt: '2024-07-09T16:59:57Z',
  createdAt: '2024-07-09T16:59:57Z',
  projectName: 'Rhythms of the Heart',
  projectDisplayArtist: 'Liam Johnson',
  projectArtworkURL: 'https://switchchord-static-hosting.s3.amazonaws.com/mock_recording_assets/projectD.png',
  connectedLabel: { id: 55, name: 'Sheffield Avenue Projects' },
  secondaryLabel: null,
  trackNumber: 1,
  recordingTitle: 'Whispering Pines',
  recordingAlternateTitles: ['Title A, Title B'],
  totalCredits: 8,
  compositionLink: null,
  stemsAdded: false,
  fileVersion: 2,
  isFinalFile: false,
  files: [
    {
      id: 1, version: 1, fileName: 'name_of_file_mix-a.wav', addedBy: 'userId or Name', addedOn: 'dateStringHere',
    },
    {
      id: 2, version: 2, fileName: 'name_of_file_mix-b.wav', addedBy: 'userId or Name', addedOn: 'dateStringHere',
    },
  ],
  isLocked: false,
  participants: [
    {
      id: 1,
      firstName: 'James',
      lastName: 'Moore',
      isni: '0000 1111 2222 3333',
      imgExists: true,
      isVerified: true,
      isFeaturedArtist: true,
      credits: [
        {
          id: 1, displayName: 'Drums', displayString: 'Drums and Percussion: James M', displayPrefix: 'Drums and Percussion:', displaySuffix: null, ddexValue: 'Drums', isDefault: true,
        },
        {
          id: 2, displayName: 'Bass', displayString: 'Bass Guitars: James M', displayPrefix: 'Bass Guitars:', displaySuffix: null, ddexValue: 'Bass', isDefault: false,
        },
      ],
      creditsPersona: null, // We will need a way to know what the preferred display name is for all credits on the recording. Could be on each credit but increases complexity.
      creditsVerified: true,
      labelCredential: null,
    },
    {
      id: 2,
      firstName: 'Julie',
      lastName: 'King',
      isni: '0000 1111 2222 3333',
      imgExists: false,
      isVerified: false,
      isFeaturedArtist: true,
      credits: [
        {
          id: 1, displayName: 'Vocals', displayString: 'All Vocals sang by Julie King', displayPrefix: 'All Vocals sang by', displaySuffix: null, ddexValue: 'Vocals', isDefault: true,
        },
      ],
      creditsPersona: null, // We will need a way to know what the preferred display name is for all credits on the recording. Could be on each credit but increases complexity.
      creditsVerified: false,
      labelCredential: { id: 1, labelName: 'UMG' },
    },
    // {
    //   id: 3,
    //   firstName: 'Joe',
    //   lastName: 'Shmo',
    //   isni: '0000 1111 2222 3333',
    //   imgExists: true,
    //   isVerified: true,
    //   isFeaturedArtist: false,
    //   credits: [
    //     {
    //       id: 1, displayName: 'Guitar', displayString: 'Joe played guitars', displayPrefix: null, displaySuffix: 'played guitars', ddexValue: 'Guitar', isDefault: true,
    //     },
    //     {
    //       id: 2, displayName: 'Electric Guitar', displayString: 'Electric Shredding by Joe Shmo', displayPrefix: 'Electric Shredding by', displaySuffix: null, ddexValue: 'Electric Guitar', isDefault: true,
    //     },
    //   ],
    //   creditsPersona: null, // We will need a way to know what the preferred display name is for all credits on the recording. Could be on each credit but increases complexity.
    //   creditsVerified: true,
    //   labelCredential: null,
    // },
    // {
    //   id: 4,
    //   firstName: 'Paul',
    //   lastName: 'Kirby',
    //   isni: null,
    //   imgExists: false,
    //   isVerified: true,
    //   isFeaturedArtist: true,
    //   credits: [
    //     {
    //       id: 1, displayName: 'Piano', displayString: 'Piano parts Julie King did play', displayPrefix: 'Piano parts', displaySuffix: 'did play', ddexValue: 'Vocals', isDefault: true,
    //     },
    //   ],
    //   creditsPersona: null, // We will need a way to know what the preferred display name is for all credits on the recording. Could be on each credit but increases complexity.
    //   creditsVerified: false,
    //   labelCredential: null,
    // },
  ],
};
