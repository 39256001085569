import AppState from '@state';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import PanelCard from '@distinct-components/cards/panelCard';
import PanelDataCard from '@distinct-components/cards/panelDataCard';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

import {
  StyledVerifiedDetailsRow,
  StyledIdentifiersWrapper,
  StyledVerifiedDataHeader,
} from './ProfileVerifiedDataModeStyles';

function ProfileVerifiedDataMode() {
  return (
    <PanelCard
      title="Verified Data"
      description={ACCOUNT_EXPLANATIONS.verifiedData.description}
      descriptionWidth="100%"
      mb="1.5em"
      darkPanel
    >
      <Box mt="1.5em" minHeight="14em">
        <PanelDataCard mb="1.5em" variant="base">
          <StyledVerifiedDataHeader>
            <Text fontWeight="600">
              Human Identity Verification
            </Text>
            <Text>
              Source: Stripe
            </Text>
          </StyledVerifiedDataHeader>
          <StyledVerifiedDetailsRow>
            <StyledIdentifiersWrapper>
              <Box mr="1em">
                <Span fontWeight="600">Verified Name:</Span>
                        &nbsp;&nbsp;
                <Span>
                  {AppState.userProfile.verifiedData.value?.first_name}
                  {' '}
                  {AppState.userProfile.verifiedData.value?.last_name}
                </Span>
              </Box>
              <Box>
                <Span fontWeight="600">Document Verified:</Span>
                        &nbsp;&nbsp;
                <Span>
                  {AppState.userProfile.verifiedData.value?.document_type}
                </Span>
              </Box>
            </StyledIdentifiersWrapper>
          </StyledVerifiedDetailsRow>
          <StyledVerifiedDetailsRow>
            <StyledIdentifiersWrapper>
              {AppState.userProfile.verifiedData.value?.address_country && (
                <Box mr="1em">
                  <Span fontWeight="600">Address:</Span>
                        &nbsp;&nbsp;
                  <Span>
                    {AppState.userProfile.verifiedData.value?.address_line1}
                    {', '}
                    {AppState.userProfile.verifiedData.value?.address_line2
                  && (
                    <Span>
                      {AppState.userProfile.verifiedData.value?.address_line2}
                      {', '}
                    </Span>
                  )}
                    {AppState.userProfile.verifiedData.value?.address_city}
                    {', '}
                    {AppState.userProfile.verifiedData.value?.address_state}
                    {' '}
                    {AppState.userProfile.verifiedData.value?.address_zip}
                    {' '}
                    {AppState.userProfile.verifiedData.value?.address_country}
                  </Span>
                </Box>
              )}
              <Box>
                <Span fontWeight="600">Country:</Span>
                        &nbsp;&nbsp;
                <Span>
                  {AppState.userProfile.verifiedData.value?.issuing_country}
                </Span>
              </Box>
            </StyledIdentifiersWrapper>
          </StyledVerifiedDetailsRow>
        </PanelDataCard>
      </Box>
    </PanelCard>
  );
}

export default ProfileVerifiedDataMode;
