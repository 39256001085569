import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';

import { useProfilePictureLink } from '@hooks/useImageLink';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';

import {
  StyledArtistCard,
  StyledCardRow,
  StyledCardDetail,
  VerifiedDot,
  UnverifiedDot,
  StyledCreditChipWrapper,
  StyledCreditChip,
} from './ArtistCardStyles';

function ArtistCard({
  onClickArtistCard,
  artist,
  activeUser,
}) {
  const { width } = useWindowDimensions();
  const ppl = useProfilePictureLink;

  useEffect(() => {
    // TODO: ADD A LIL MOTION
  }, []);

  const onClickCard = () => {
    if (activeUser?.isAdmin || activeUser?.id === artist.id) {
      onClickArtistCard(artist);
    }
  };

  return (
    <StyledArtistCard isClickable={activeUser?.isAdmin || activeUser?.id === artist.id}>
      <StyledCardRow>
        <StyledCardDetail onClick={onClickCard}>
          {artist.imgExists
            ? (
              <Avatar
                size="3.5em"
                borderSize="2px"
                imgURL={ppl(artist.id)}
                isVerified={artist.isVerified}
                checkSize="1rem"
                checkX="-1px"
                checkY="4px"
              />
            )
            : (
              <Avatar
                size="3.5em"
                borderSize="2px"
                imgURL={null}
                isVerified={artist.isVerified}
                checkSize="1rem"
                checkX="-1px"
                checkY="4px"
              />
            )}
          <Box>
            {activeUser.id === artist.id && artist.credits == null
              ? (
                <Box>
                  <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.6" fontSize="0.875rem" maxWidth={width > 400 ? '400px' : '150px'} truncate>
                    Add Your Credits
                  </Text>
                  <Text fontSize="0.75rem" fontWeight="500" color="var(--text-secondary)" mr="1em">
                    If you participated in the recording
                  </Text>
                </Box>
              ) : (
                <Box>
                  <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.6" fontSize="0.875rem" maxWidth={width > 400 ? '400px' : '150px'} truncate>
                    {artist.firstName}
                    {' '}
                    {artist.lastName}
                  </Text>
                  <Box display="flex">
                    <Text fontSize="0.75rem" fontWeight="500" color="var(--text-secondary)" mr="1em">
                      {artist.isFeaturedArtist ? 'Featured Artist' : 'Non Featured Artist'}
                    </Text>
                    {artist.creditsVerified
                      ? (
                        <Box display="flex" alignItems="center">
                          <VerifiedDot />
                          <Text color="var(--text-secondary)" fontSize="0.75rem" fontWeight="500">Verified Credits</Text>
                        </Box>
                      )
                      : (
                        <Box>
                          {artist.credits !== null
                     && (
                     <Box display="flex" alignItems="center">
                       <UnverifiedDot />
                       <Text color="var(--text-secondary)" fontSize="0.75rem" fontWeight="500">Unverified Credits</Text>
                     </Box>
                     )}
                        </Box>
                      )}
                  </Box>
                  <Box display="flex">
                    {artist.credits !== null
                      ? (
                        <StyledCreditChipWrapper>

                          {artist.credits.map((c) => (
                            <StyledCreditChip>{c.ddexValue}</StyledCreditChip>
                          ))}

                        </StyledCreditChipWrapper>
                      ) : (
                        <Text color="var(--text-secondary)" fontSize="0.678rem" fontWeight="400">No Credits Added</Text>
                      )}
                  </Box>
                </Box>
              )}
          </Box>
        </StyledCardDetail>

      </StyledCardRow>
    </StyledArtistCard>
  );
}

ArtistCard.propTypes = {
  onClickArtistCard: PropTypes.func.isRequired,
  artist: PropTypes.object.isRequired,
  activeUser: PropTypes.object,
};

ArtistCard.defaultProps = {
  activeUser: null,
};

export default ArtistCard;
