import styled from 'styled-components';

export const StyledPercentageInput = styled.input`
    height: 2.75em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0em;
    border: none;
    width: 100%;
    padding-left: 0.75em;
    background: var(--input-bg);
    color: var(--text-primary);
`;

export const StyledPercentBox = styled.div`
    color: var(--white);
    height: 2.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    background: var(--input-append-bg);
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
`;

export const RecentWriterWrapper = styled.div`
    position: relative;
`;

export const QrControlItem = styled.div`
    width: 100%;
    max-width: 420px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
`;

export const QrPrompt = styled.div`
    align-items: center;
    display: flex;
    gap: 0.938em;
    cursor: pointer;
`;

export const QrWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const QrButton = styled.div`
    display: flex;
    border: 1px solid var(--border);
    border-radius: 0.5rem;
    align-items: center;
    padding: 8px 10px 8px 10px;
    margin-bottom: 0.75rem;
    cursor: pointer;
`;
