import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Avatar from '@ui-kit/avatar';

import {
  UserCardWrapper,
} from './baseUserCardStyles';

function BaseUserCard({
  user,
  onClickCard,
  selected,
  ...restProps
}) {
  const handleCardClick = () => {
    if (onClickCard) {
      onClickCard(user);
    }
  };
  return (
    <UserCardWrapper selected={selected} {...restProps} onClick={handleCardClick}>
      {user.imgURL
        ? (
          <Avatar
            size="2em"
            borderSize="2px"
            imgURL={user.imgURL}
          />
        )
        : (
          <Avatar
            size="2em"
            borderSize="2px"
            imgURL={null}
          />
        )}
      <Box ml="0.5rem" pt="2px">
        {user.name ? (
          <Text lineHeight="1.2" fontWeight="600" fontSize="0.813rem">
            {user.name}
          </Text>
        ) : (
          <Text lineHeight="1.2" fontWeight="600" fontSize="0.813rem">
            {user.firstName}
            {' '}
            {user.lastName}
          </Text>
        )}
        <Text fontSize="0.675rem" color="var(--text-secondary)">{user.username ? `@${user.username}` : user.email}</Text>
      </Box>
    </UserCardWrapper>
  );
}

BaseUserCard.propTypes = {
  user: PropTypes.object.isRequired,
  onClickCard: PropTypes.func,
  selected: PropTypes.bool,
};

BaseUserCard.defaultProps = {
  onClickCard: null,
  selected: false,
};

export default BaseUserCard;
