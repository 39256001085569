/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useEffect, useLayoutEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import WorkspaceCard from '@distinct-components/cards/workspaceCard';

import BreakLine from '@ui-kit/dividers/breakLine';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import AddArtistOverlay from '@components/shared-overlays/addArtistOverlay';

import ArtistCard from './components/artistCard';
import ManageArtistAndCreditsOverlay from './overlays/manageArtistAndCreditsOverlay';

import {
  StyledCreditsListWrapper,
} from './RecordingCreditsSectionStyles';

function RecordingCreditsSection({ recordingData, activeUser }) {
  const [isAddingArtist, setIsAddingArtist] = useState(false);
  const [selectedTab, setSelectedTab] = useState('all');
  const [creditsToDisplay, setCreditsToDisplay] = useState(recordingData.participants);
  const [selectedArtist, setSelectedArtist] = useState(null);

  const isMobile = AppState.isMobile.value;

  useEffect(() => {
    console.log('started');
  }, []);

  useLayoutEffect(() => {
    if (isMobile) {
      setCreditsToDisplay(recordingData.participants);
    } else if (selectedTab === 'all') {
      setCreditsToDisplay(recordingData.participants);
    } else {
      alert('dont forget to wire this where we set filtered participants back based on selected tab');
      // TODO: Temporary change to show visual feedback of list changing.
      setCreditsToDisplay(recordingData.participants.slice(0, 1));
    }
  }, [isMobile]);

  const onClickAddArtist = () => {
    setIsAddingArtist(true);
    AppState.workspace.isEditingChild.value = true;
  };

  const onClickArtistCard = (artist) => {
    setSelectedArtist(artist);
    AppState.workspace.isEditingChild.value = true;
  };

  const handleCloseEditArtistOverlay = () => {
    setSelectedArtist(null);
    AppState.workspace.isEditingChild.value = false;
  };

  const handleCancelAddOrEdit = () => {
    setIsAddingArtist(false);
    AppState.workspace.isEditingChild.value = false;
  };

  const onClickCreditListTab = (tab) => {
    const { value } = tab;
    setSelectedTab(value);

    const filterParticipants = (condition) => recordingData.participants.filter(condition);

    switch (value) {
      case 'all':
        setCreditsToDisplay(recordingData.participants);
        break;
      case 'featured':
        setCreditsToDisplay(filterParticipants((p) => p.isFeaturedArtist));
        break;
      case 'non-featured':
        setCreditsToDisplay(filterParticipants((p) => !p.isFeaturedArtist));
        break;
      case 'production':
        // Placeholder logic for 'production' case
        setCreditsToDisplay(recordingData.participants.slice(0, 2));
        break;
      default:
        console.log('no case found for current tab action');
    }
  };

  const handleArtistAdded = (artist) => {
    setIsAddingArtist(false);
    setSelectedArtist(artist);
  };

  return (
    <Fragment>
      <WorkspaceCard
        pb="5em"
        title={isMobile ? `Participants: ${creditsToDisplay.length}` : null}
        tabs={[
          { label: 'All Credits', value: 'all' },
          { label: 'Featured', value: 'featured' },
          { label: 'Non-Featured', value: 'non-featured' },
          { label: 'Production', value: 'production' },
        ]}
        selectedTab={selectedTab}
        onChangeTab={onClickCreditListTab}
        handleAddToSection={onClickAddArtist}
        isCardLocked={!activeUser.isAdmin || recordingData.isLocked}
        lockedText="You do not have permission to edit this section or the recording is locked."
        mb="10em"
        mt="1.5rem"
      >
        <StyledCreditsListWrapper writerCount={recordingData.participants.length}>

          {creditsToDisplay.find((p) => p.id === activeUser.id) && (
            <Box>
              <Text fontSize="0.75rem" fontWeight="800" color="var(--text-secondary)" pl="0.25rem" pb="0.5rem">YOU</Text>
              {creditsToDisplay.filter((c) => c.id === activeUser.id).length === 0
                && <ArtistCard activeUser={activeUser} artist={null} onClickArtistCard={onClickArtistCard} />}
              {creditsToDisplay.filter((c) => c.id === activeUser.id).map((artist) => (
                <ArtistCard activeUser={activeUser} artist={artist} onClickArtistCard={onClickArtistCard} />
              ))}
              <BreakLine mt="0.75rem" mb="0.938rem" color="var(--panel-divider)" />
            </Box>
          )}

          {/* TODO: Handle sorting as we did with writers once the data is finalized. */}
          {/* {writers.sort((a, b) => a.lastName.localeCompare(b.lastName)).map((writer) => ( */}
          {creditsToDisplay.filter((c) => c.id !== activeUser.id).map((artist) => (
            <ArtistCard activeUser={activeUser} artist={artist} onClickArtistCard={onClickArtistCard} />
          ))}
        </StyledCreditsListWrapper>
      </WorkspaceCard>

      {isAddingArtist
        && (
          <AddArtistOverlay
            activeUser={activeUser}
            recordingData={recordingData}
            closeFunction={handleCancelAddOrEdit}
            handleArtistAdded={handleArtistAdded}
          />
        )}

      {selectedArtist
        && (
          <ManageArtistAndCreditsOverlay
            recordingData={recordingData}
            activeUser={activeUser}
            artistToManage={selectedArtist}
            closeFunction={handleCloseEditArtistOverlay}
          />
        )}

    </Fragment>
  );
}

RecordingCreditsSection.propTypes = {
  recordingData: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default RecordingCreditsSection;
