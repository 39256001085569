/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledMenuItem = styled.div`
    border-bottom: 1px solid var(--border);
    color: var(--tool-tip-text);
    color: ${(props) => (props.isDestructive ? 'var(--tool-tip-destructive-text)' : 'var(--tool-tip-text)')};
    font-size: 0.75rem;
    font-weight: 400;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
    ${space}

    &:last-child {
        margin-bottom: 0rem;
        border-bottom: none;
        padding-bottom: 0rem;
    }
`;
