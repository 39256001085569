import AppState from '@state';
import resetGlobalState from '@state/reset';
import { useState, useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';
import { route } from 'preact-router';

import { deleteAccount } from '@api/restricted/account-api';
import { postAccountStripePortal } from '@api/restricted/account-portal-api';

import useErrorOverlay from '@hooks/useErrorOverlay';

import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';
import LinkButton from '@ui-kit/buttons/linkButton';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import MailIcon from '@assets/icons/mail-outline.svg';
import SunIcon from '@assets/icons/sun-outline.svg';
import MoonIcon from '@assets/icons/moon-outline.svg';
import Text from '@ui-kit/typography/text';
import PanelCard from '@distinct-components/cards/panelCard';
import BaseButton from '@ui-kit/buttons/baseButton';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import { SETTINGS_EXPLANATIONS } from '@constants/supportingCopy';

import {
  StyledSettingsBox,
  CenteredSettingsBox,
  SunWrapper,
  MoonWrapper,
} from './SettingsStyles';

function Settings() {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectConfirm, setRejectConfirm] = useState(null);
  const [displayTheme, setDisplayTheme] = useState(null);

  const loadAnimation = anime.timeline({
    autoplay: true,
    delay: 400,
  });

  useEffect(() => {
    document.title = 'Settings';
    loadAnimation.add({
      targets: '.settings-section',
      translateY: ['16px', 0],
      opacity: [0, 1],
      duration: 600,
      direction: 'normal',
      delay: anime.stagger(100),
    }, 200);

    const currentTheme = localStorage.getItem('theme');
    if (!currentTheme) {
      setDisplayTheme('dark');
    } else {
      setDisplayTheme(currentTheme);
    }
  }, []);

  const onToggleDeleteAccount = () => {
    setShowDeletePrompt(!showDeletePrompt);
    setRejectConfirm(null);
    setError(null);
  };

  const onTypeToConfirm = (value) => {
    const validationText = value.toLowerCase();
    setRejectConfirm(validationText);
  };

  const onClickContactSupport = () => {
    window.location.href = SETTINGS_EXPLANATIONS.supportMailTo;
  };

  const onClickTerms = () => {
    window.open(
      'https://switchchord.com/terms',
      '_blank',
    );
  };

  const onClickPrivacy = () => {
    window.open(
      'https://switchchord.com/privacy',
      '_blank',
    );
  };

  const handleDeleteAccount = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setError(null);
      setShowDeletePrompt(false);

      deleteAccount().then((response) => {
        if (response.status === 200) {
          response.json().then(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            resetGlobalState();
            route('/login');
          }).catch((err) => {
            useErrorOverlay({ errorMessage: `${err.message} when deleting your account` });
          });
        } else {
          response.json().then((json) => {
            useErrorOverlay({ errorMessage: `${json.error ? json.error : response.statusText} when deleting account` });
          });
        }
      }).catch((err) => {
        useErrorOverlay({ errorMessage: `${err.message} when deleting your account` });
      });
    }, 3000);
  };

  const handleThemeChange = (e) => {
    e.preventDefault();
    if (displayTheme === 'dark') {
      setDisplayTheme('light');
      localStorage.setItem('theme', 'light');
      document.documentElement.setAttribute('data-theme', 'light');
    } else {
      setDisplayTheme('dark');
      localStorage.setItem('theme', 'dark');
      document.documentElement.setAttribute('data-theme', 'dark');
    }
  };

  const onClickPortal = () => {
    postAccountStripePortal().then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if (json.portal_url) {
            window.location.href = json.portal_url;
          } else {
            AppState.exceptions.errorPageError.value = 'Missing portal url';
          }
        });
      } else {
        response.json().then((json) => {
          AppState.exceptions.errorPageError.value = `${json.error ? json.error : response.statusText} when going to portal`;
        });
      }
    }).catch((err) => {
      AppState.exceptions.errorPageError.value = `${err.message} when going to portal`;
    });
  };

  return (
    <CoreLayout globalAlert={null}>
      <PageCard title="Settings">
        <PanelCard
          title="Display Theme"
          mt="1.5em"
          className="settings-section"
          style={{ opacity: 0 }}
          darkPanel
        >
          <CenteredSettingsBox>
            <Box>
              <Text fontSize="0.875rem">
                {displayTheme === 'dark' ? 'Dark Mode (Default)' : 'Light Mode'}
              </Text>
            </Box>
            {displayTheme
            && (
            <ToggleSwitch id="themeSwitch" large>
              <MoonWrapper><Icon size="14px"><MoonIcon /></Icon></MoonWrapper>
              <SunWrapper><Icon size="16px" color="var(--white)"><SunIcon /></Icon></SunWrapper>
              <input
                type="checkbox"
                name="themeSwitch"
                id="themeSwitch"
                checked={displayTheme === 'light'}
                onChange={handleThemeChange}
              />
            </ToggleSwitch>
            )}
          </CenteredSettingsBox>

        </PanelCard>

        {AppState.subscribed.value && (
        <PanelCard
          title="Manage Subscription"
          mt="1.5em"
          className="settings-section"
          style={{ opacity: 0 }}
          darkPanel
        >
          <StyledSettingsBox>
            <Box>
              <Text fontWeight="500">
                {AppState.subscribed.value ? 'Subscribed' : 'Not Subscribed'}
              </Text>
            </Box>
            <LinkButton btnText="Manage Subscription" variant="small" color="var(--red-300)" onClick={onClickPortal} />
          </StyledSettingsBox>
        </PanelCard>
        )}

        <PanelCard
          title="Your Account"
          mt="1.5em"
          className="settings-section"
          style={{ opacity: 0 }}
          darkPanel
        >
          <StyledSettingsBox>
            <Box>
              <Text fontWeight="500">
                {AppState.userProfile.email.value}
              </Text>
            </Box>
            <LinkButton btnText="Delete Account" variant="small" color="var(--red-300)" onClick={onToggleDeleteAccount} />
          </StyledSettingsBox>

        </PanelCard>

        <PanelCard
          title="Help and Support"
          description={SETTINGS_EXPLANATIONS.support}
          mt="1.5em"
          className="settings-section"
          style={{ opacity: 0 }}
          darkPanel
        >
          <StyledSettingsBox mt="1em">

            <Box display="flex" alignItems="center" className="cursor-p" onClick={onClickContactSupport}>
              <Icon size="1.25em" pt="3px" mr="0.5em" cursor><MailIcon /></Icon>
              <Text fontSize="0.75rem" fontWeight="500">Email Support</Text>
            </Box>

            <Box display="flex" alignItems="flex-start">
              <LinkButton btnText="Terms of Service" variant="small" onClick={onClickTerms} mr="1em" />
              <LinkButton btnText="Privacy Policy" variant="small" onClick={onClickPrivacy} />
            </Box>

          </StyledSettingsBox>

        </PanelCard>
      </PageCard>

      {showDeletePrompt && (
        <BaseEditOverlay formCentered closeFunction={onToggleDeleteAccount}>

          <ConversationPrompt
            primaryMessage="Are you sure you want to delete your account?"
            promptLabel="Woah There..."
            labelColor="var(--red-300)"
            mb="1em"
          />
          <ConversationExplanation mb="2em" explanation={SETTINGS_EXPLANATIONS.deleteWarning} truncateAtCharacter={500} />

          <InputLabel label="Confirm by typing the phrase &quot;Delete My Account&quot;" />
          <BaseInput
            placeholder="Enter &quot;Delete My Account&quot;"
            type="text"
            onChange={(e) => onTypeToConfirm((e.target.value))}
            fluid
            mb="1.5em"
          />

          {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          {rejectConfirm === 'delete my account'
          && (
            <BaseButton
              btnText="Delete My Account"
              variant="danger"
              spinnerVariant="danger"
              fluid
              isLoading={isLoading}
              onClick={handleDeleteAccount}
            />
          )}
        </BaseEditOverlay>
      )}
    </CoreLayout>
  );
}

export default Settings;
