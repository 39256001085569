/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import { postAdminCWR } from '@api/admin/admin-cwr-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Text from '@ui-kit/typography/text';
import DragAndDrop from '@distinct-components/files/dragAndDrop';

function AdminOrgUploadCWR({
  closeFunction,
  organization,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const useToastAlertSuccess = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'success' };
  };

  const handleAddFile = (files) => {
    setError(null);
    const fileToAdd = files[0];
    if (!fileToAdd) {
      setError('Invalid file');
      setFile(null);
      return;
    }
    setFile(fileToAdd);
  };

  const handleSubmitFile = () => {
    setIsLoading(true);
    setError(null);

    postAdminCWR(file)
      .then((response) => {
        if (response.status !== 200) {
          setIsLoading(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          setIsLoading(false);
          setFile(null);
          useToastAlertSuccess('Success');

          response.blob().then((blob) => {
            const fileName = response.headers.get('content-disposition').split('filename=')[1];
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage={organization.name}
        promptLabel="Import CWR for bulk writer onboarding"
        mb="1.5em"
      />

      <Fragment>
        <Text mb="1em" fontSize="0.813rem">Add CWR File</Text>
        <DragAndDrop
          handleFilesAdded={handleAddFile}
          disabled={isLoading}
          id="file-drop"
        />

        {file && (
        <Text mb="1em" fontSize="0.813rem">
          File Added:
          {' '}
          {file.name}
        </Text>
        )}

        {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
          mt="1.5em"
        />
        )}

        {file && <BaseButton mb="1.5em" btnText="Finish" fluid isLoading={isLoading} onClick={handleSubmitFile} />}
      </Fragment>

    </BaseEditOverlay>
  );
}

AdminOrgUploadCWR.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default AdminOrgUploadCWR;
