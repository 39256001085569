import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';

export const StyledHeader = styled.div`
  border-bottom: 1px solid var(--border);
  background: var(--header-background);
  margin: 0 auto;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 1.75em 1.5em 1.25em 1.5em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1em;
  }

`;

export const StyledNameText = styled(Text)`
  font-size: 0.875rem;
  color: var(--text-soft-mute);
  margin-left: 0.625em;
  font-weight: 500;
  font-family: var(--font-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9em;
  letter-spacing: -0.5px;
`;

export const StyledAccountWrapper = styled(Box)`
  cursor: pointer;
  border: 1px solid var(--nav-element-border);
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  border-radius: 8px;
  min-width: 9rem;
  background: var(--card);

  @media screen and (max-width: ${pointBreaks.sm}) {
   display: none;
  }
`;

export const StyledSearchBar = styled.input`
 background: var(--input-secondary-background);
 position: relative;
 font-size: 0.875rem;
 min-width: 20em;
 padding: 0.625em 1.125em;
 border-radius: 2em;
 color: var(--text-soft-mute);
 border: 1px solid var(--input-secondary-border);
 min-height: 2em;

 @media screen and (max-width: ${pointBreaks.sm}) {
   font-size: 1rem;
 }

 ::placeholder {
   color: var(--text-placeholder);
 }

 :disabled {
   cursor: not-allowed;
   background-color: var(--grey-ghost);
 }

 @media screen and (max-width: ${pointBreaks.md}) {
   display:none;
 }
`;

export const RunSearchChip = styled.div`
    position: absolute;
    height: 28px;
    width: 28px;
    background: var(--active-nav);
    opacity: 0.8;
    border-radius: 50px;
    top: 4px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 28px;
        width: 28px;
     }
`;

export const ActionMenuItem = styled.div`
    border-bottom: 1px solid var(--nav-element-border);
    color: var(--text-soft-mute);
    font-size: 0.75rem;
    font-weight: 500;
    width: 100%;
    padding-bottom: 1em;
    margin-bottom: 1.125em;
    cursor: pointer;
`;
