import styled from 'styled-components';
import { space } from 'styled-system';

export const FileAddedWrapper = styled.div(({
  background,
}) => `
    position: relative;
    min-height: 96px;
    display: flex;
    padding: 0px 16px;
    justify-content: flex-start;
    border-width: 2px;
    border-radius: 0.5em;
    border-style: dashed;
    border-color: var(--inactive);
    background: ${background};
    align-items: center;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    ${space};
`);

export const RemoveFileWrapper = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    background: var(--input-append-bg);
    border-radius: 50px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledFeedbackImageWrapper = styled.div`
    height: 64px;
    width: 64px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    background: var(--document-illustration);
    box-shadow: 2px 4px 11px var(--card-shadow);
    ${space};
`;

export const StyledFeedbackImage = styled.img`
    height: 100%;
    width: 100%;
`;

export const StyledFeedbackIconWrapper = styled.div`
    height: 64px;
    width: 64px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--document-illustration);
    border-radius: 8px;
    box-shadow: 2px 4px 11px var(--card-shadow);
`;
