/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import { postOrgOnboarding } from '@api/admin/admin-org-onboard-api';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Text from '@ui-kit/typography/text';
import DragAndDrop from '@distinct-components/files/dragAndDrop';

function AdminOrgWriterOnboarding({
  closeFunction,
  organization,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [confirmationPhrase, setConfirmationPhrase] = useState('');

  const useToastAlertSuccess = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'success' };
  };

  const handleAddFile = (files) => {
    setError(null);
    const fileToAdd = files[0];
    if (!fileToAdd || !fileToAdd.name.endsWith('.csv')) {
      setError('Invalid file');
      setFile(null);
      return;
    }
    setFile(fileToAdd);
  };

  const handleSubmitFile = () => {
    setIsLoading(true);
    setError(null);

    postOrgOnboarding(organization.id, file)
      .then((response) => {
        if (response.status !== 200) {
          setIsLoading(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          setIsLoading(false);
          setFile(null);
          useToastAlertSuccess('Onboarding Successful');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage={organization.name}
        promptLabel="Import CSV for bulk writer onboarding"
        mb="1.5em"
      />

      <Fragment>
        <Text mb="1em" fontSize="0.813rem">Add Onboarding CSV File</Text>
        <DragAndDrop
          handleFilesAdded={handleAddFile}
          disabled={isLoading}
          id="file-drop"
        />

        {file && (
        <Text mb="1em" fontSize="0.813rem">
          File Added:
          {' '}
          {file.name}
        </Text>
        )}

        <InputLabel label="Confirm Organization Name" />
        <BaseInput
          value={confirmationPhrase}
          onChange={(e) => setConfirmationPhrase(e.target.value)}
          id="phrase"
          name="phrase"
          placeholder="Type organization name to continue..."
          fluid
          type="text"
          mb="2.25em"
        />

        {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
          mt="1.5em"
        />
        )}

        {file && confirmationPhrase.toLowerCase() === organization.name.toLowerCase()
        && <BaseButton mb="1.5em" btnText="Finish" fluid isLoading={isLoading} onClick={handleSubmitFile} />}
      </Fragment>

    </BaseEditOverlay>
  );
}

AdminOrgWriterOnboarding.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default AdminOrgWriterOnboarding;
