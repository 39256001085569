import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';
import { route } from 'preact-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import useErrorOverlay from '@hooks/useErrorOverlay';

import Spinner from '@ui-kit/loaders/Spinner';
import Icon from '@ui-kit/icon';
import NoteIcon from '@assets/icons/single-note-square.svg';
import LabelIcon from '@assets/icons/label-connection-circle.svg';
import SoloLogo from '@assets/logos/logo-symbol.svg';
import ChevronIcon from '@assets/icons/chevron-down.svg';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import UtilityButton from '@ui-kit/buttons/utilityButton';
import AvatarCollection from '@distinct-components/avatarCollection';
import RecordingListCard from '@distinct-components/cards/recordingListCard';

import {
  StyledProjectViewWrapper,
  HeaderWrapper,
  HeaderDetails,
  HeaderActions,
  PageWrapper,
  PageContent,
  HeaderContent,
  MobileHeaderContent,
  MobileTitleWrapper,
  StyledProjectHeaderWrapper,
  StyledProjectImageTitleWrapper,
  StyledProjectImageWrapper,
  StyledProjectImage,
  StyledProjectImageEmpty,
  StyleProjectTitleText,
} from './ArtistRecordingProjectViewStyles';

import { MOCK_SINGLE_PROJECT } from '../MOCK_RECORDING_DATA';

function ArtistRecordingProjectView({ id }) {
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [projectData, setProjectData] = useState();
  const [projectRecordings, setProjectRecordings] = useState([]);

  const loadProjectData = async () => {
    setIsLoadingProject(true);
    try {
      // HAPPY LINTER
      console.log(id);

      // MOCK TIME
      setTimeout(() => {
        setProjectData(MOCK_SINGLE_PROJECT);
        setProjectRecordings(MOCK_SINGLE_PROJECT.recordings);
        setIsLoadingProject(false);
      }, 800);
    } catch (err) {
      useErrorOverlay({ errorMessage: `Unable to load project data. Error: ${err}` });
    }
  };

  useEffect(async () => {
    document.title = 'Recording Project';
    await loadProjectData();
  }, []);

  const onClickBackToProjects = () => {
    route('/recordings');
  };

  const reorderRecordings = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newRecordingOrder = reorderRecordings(
      projectRecordings,
      result.source.index,
      result.destination.index,
    );
    setProjectRecordings(newRecordingOrder);
  };

  const onClickPlayRecording = (rec) => {
    // MOCK VALUES JUST TO MAKE SURE THE TEXT AND IMAGES PASS CORRECTLY
    const MOCK_REC_OBJECT = {
      id: 19,
      name: 'How_Do_You_Find_Demo_PBMix.mp3',
      fileName: 'How_Do_You_Find_Demo_PBMix.mp3',
      type: 'audio/mpeg',
      size: 3113776,
      recordingType: 'Demo',
      isPrivate: false,
      isProcessing: false,
    };
    // for the sake of testing, I'm apply a composition uuid to appState
    AppState.composition.uuid.value = null;
    AppState.composition.uuid.value = 'w8VPeofP202uKnRLLXWGL17Xgf8';
    // END OF MOCK VALUES
    if (AppState.media.currentlyPlaying.value?.id === MOCK_REC_OBJECT.id && AppState.media.isPlaying.value === true) {
      AppState.media.currentlyPlaying.value = null;
      AppState.media.isPlaying.value = false;
      AppState.media.recordingName.value = null;
      AppState.media.recordingProjectName.value = null;
      AppState.media.recordingProjectArtistName.value = null;
    } else {
      AppState.media.artworkURL.value = projectData.projectArtworkURL || null;
      AppState.media.recordingName.value = rec.recordingTitle;
      AppState.media.recordingProjectName.value = projectData.projectName;
      AppState.media.recordingProjectArtistName.value = projectData.projectDisplayArtist || 'Undefined Artist';
      AppState.media.currentlyPlaying.value = MOCK_REC_OBJECT;
    }
  };

  return (
    <StyledProjectViewWrapper>

      {!isLoadingProject
      && (
      <HeaderWrapper id="scrollPoint">
        <MobileHeaderContent>
          <Box justifySelf="flexStart" flex="0" onClick={onClickBackToProjects}>
            <Icon cursor size="0.75em" style={{ transform: 'rotate(90deg)' }}><ChevronIcon /></Icon>
          </Box>
          <MobileTitleWrapper>
            <Text fontWeight="700" fontSize="0.938rem" color="var(--text-primary)">
              Project:
              {' '}
              <Span fontSize="1em">
                {projectData.projectName}
              </Span>
            </Text>
          </MobileTitleWrapper>
        </MobileHeaderContent>

        <HeaderContent>
          <HeaderDetails>
            <Icon onClick={onClickBackToProjects} pt="0.188em" cursor><SoloLogo /></Icon>
            <Text fontWeight="700" ml="0.75em" fontSize="1em" color="var(--text-primary)">
              Project:
              {' '}
              <Span fontSize="1em">
                {projectData.projectName}
              </Span>
            </Text>
          </HeaderDetails>
          <HeaderActions>
            <UtilityButton
              btnText="Actions"
              suffixDecoration="chevron"
              menuOptions={[
                { action: () => console.log('option A'), label: 'Add Another Rec' },
                { action: () => console.log('option B'), label: 'Download All' },
                { action: () => console.log('option C'), label: 'Manage Participants' },
                { action: () => console.log('option D'), label: 'Edit Project Details' },
              ]}
            />
            <UtilityButton ml="1rem" icon="close" onClick={onClickBackToProjects} />
          </HeaderActions>

        </HeaderContent>

      </HeaderWrapper>
      )}

      {isLoadingProject ? (
        <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
          <Spinner size="2em" variant="page" />
        </Box>
      ) : (
        <PageWrapper>
          <PageContent>

            <StyledProjectHeaderWrapper>

              <StyledProjectImageTitleWrapper>
                <Box>
                  <StyledProjectImageWrapper>
                    {projectData.projectArtworkURL
                      ? (
                        <StyledProjectImage src={projectData.projectArtworkURL} alt="artwork" />
                      ) : (
                        <StyledProjectImageEmpty>
                          <Icon size="2rem" color="var(--icon-secondary)" cursor><NoteIcon /></Icon>
                        </StyledProjectImageEmpty>
                      )}
                  </StyledProjectImageWrapper>
                </Box>
                <Box>
                  <StyleProjectTitleText>{projectData.projectName}</StyleProjectTitleText>
                  <Text mb="0.5rem" fontSize="0.938rem" color="var(--text-secondary)" fontWeight="400" lineHeight="1">{projectData.projectDisplayArtist || 'Undefined Artist'}</Text>
                  <Box display="flex">
                    <Text fontSize="0.813rem" color="var(--text-secondary)" fontWeight="400">
                      {projectData.recordings.length}
                      {' '}
                      Recordings
                    </Text>
                    {projectData.primaryLabelName
                    && (
                    <Box display="flex" ml="0.75rem" alignItems="center">
                      <Icon size="0.938rem" pt="4px" color="var(--color-blue)" mr="0.5rem" cursor><LabelIcon /></Icon>
                      <Text fontSize="0.813rem" color="var(--text-secondary)" fontWeight="400">{projectData.primaryLabelName}</Text>
                    </Box>
                    )}
                  </Box>
                </Box>
              </StyledProjectImageTitleWrapper>

              <Box display="flex" justifyContent="flex-end" alignItems="center" className="cursor-p" onClick={() => alert('open manage user overlay')}>
                <Text fontSize="0.75rem" color="var(--text-secondary)" mr="0.75rem">
                  {projectData.participantSummary.length}
                  {' '}
                  Participants
                </Text>
                <AvatarCollection users={projectData.participantSummary} />
              </Box>

            </StyledProjectHeaderWrapper>

            <Box mt="1.75rem">

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(droppableProvided) => (
                    <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                      {projectRecordings.map((r, index) => (
                        <Draggable key={String(r.id)} draggableId={String(r.id)} index={index}>
                          {(draggableProvided) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                            >
                              <RecordingListCard
                                mb="1rem"
                                recording={r}
                                index={index}
                                projectArtworkURL={projectData.projectArtworkURL}
                                dragHandleProps={draggableProvided.dragHandleProps}
                                handlePlayRecording={onClickPlayRecording}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

            </Box>

          </PageContent>
        </PageWrapper>
      )}

    </StyledProjectViewWrapper>
  );
}

ArtistRecordingProjectView.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ArtistRecordingProjectView;
