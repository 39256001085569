import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

// eslint-disable-next-line import/prefer-default-export
export const FormInputWrapper = styled.div`
    display: flex;

    #name {
        width: 100%;
        margin-right: 1em;
    }
    #type {
        min-width: 12rem;
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;

        #name {
            width: 100%;
        }
        #type {
            width: 100%;
        }
      }
`;
