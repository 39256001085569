import { useMemo } from 'preact/hooks';
import styled from 'styled-components';
import { space, variant } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

const BaseSelect = styled.select(
  ({
    hasError,
    fluid,
    minWidth,
  }) => {
    const inputWidth = useMemo(() => (fluid ? '100%' : 'auto'), [fluid]);

    return `
    font-size: 0.875rem;
    padding: 0.5em 1.125em;
    border-radius: 0.5em;
    color: var(--text-input);
    border: 1px solid ${hasError ? 'var(--red-400)' : 'var(--input-border)'};
    transition: all 250ms ease-in-out;
    background-color: var(--input-bg);
    color: var(--text-input);
    width: ${inputWidth};
    height: 2.75rem;
    cursor: pointer;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjUgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgMjwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtMiI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIxOCI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNOC44MDQxMzMyOSw3LjIxODA0NjAzIEM5LjA3ODI1MzI5LDYuOTI2NzMzNzkgOS41MjIwNjAxLDYuOTI3NDA0NyA5Ljc5NTQwMzU0LDcuMjE5NTQ0NTUgQzEwLjA0MTQxMjYsNy40ODI0NzA0MiAxMC4wNjU1MDM2LDcuODkxODc3OTIgOS44NjgwMjgyNiw4LjE4MzQ5Njc5IEw5Ljc5Mzk5NzQ1LDguMjc1OTc4MDMgTDYuNDk0OTMyMDgsMTEuNzgxOTU0IEM2LjI0ODcxODg0LDEyLjA0MzYwOTIgNS44NjU0OTEzOCwxMi4wNjk3NzQ3IDUuNTkxODc1MjksMTEuODYwNDUwNSBMNS41MDUwNjc5MiwxMS43ODE5NTQgTDIuMjA2MDAyNTUsOC4yNzU5NzgwMyBDMS45MzE4ODI1NCw3Ljk4NDY2NTc4IDEuOTMxMjUzMDIsNy41MTE2ODQ0MSAyLjIwNDU5NjQ2LDcuMjE5NTQ0NTUgQzIuNDUwNjA1NTUsNi45NTY2MTg2OCAyLjgzNDY4OTk4LDYuOTI5NzgyNjYgMy4xMDg4ODAyNSw3LjEzOTQxMTM0IEwzLjE5NTg2NjcxLDcuMjE4MDQ2MDMgTDYsMTAuMTk4MzE4MiBMOC44MDQxMzMyOSw3LjIxODA0NjAzIFoiIGlkPSJWZWN0b3IiIGZpbGw9IiM5MDY5RkYiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    min-width: ${minWidth || 'auto'};

    @media screen and (max-width: ${pointBreaks.sm}) {
      font-size: 1rem;
    }

    ::placeholder {
      color: var(--text-placeholder);
    }

    ${!hasError && `
      :focus {
        border-color: var(--input-focused-border);
      }
      :focus-visible {
        outline: none;
      }
      :disabled {
        cursor: not-allowed;
        background-color: var(--grey-ghost);
      }
    `};
  `;
  },
  space,
  variant({
    variants: {
      panel: {
        backgroundColor: 'var(--input-bg-panel)',
      },
      small: {
        height: '2.5em',
        backgroundPositionX: '100%',
        backgroundPositionY: '50%',
        backgroundImage: 'url(\'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjUgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+R3JvdXAgMjwvdGl0bGU+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iR3JvdXAtMiI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjAiIHk9IjAiIHdpZHRoPSIyNSIgaGVpZ2h0PSIxOCI+PC9yZWN0PgogICAgICAgICAgICA8cGF0aCBkPSJNOC44MDQxMzMyOSw3LjIxODA0NjAzIEM5LjA3ODI1MzI5LDYuOTI2NzMzNzkgOS41MjIwNjAxLDYuOTI3NDA0NyA5Ljc5NTQwMzU0LDcuMjE5NTQ0NTUgQzEwLjA0MTQxMjYsNy40ODI0NzA0MiAxMC4wNjU1MDM2LDcuODkxODc3OTIgOS44NjgwMjgyNiw4LjE4MzQ5Njc5IEw5Ljc5Mzk5NzQ1LDguMjc1OTc4MDMgTDYuNDk0OTMyMDgsMTEuNzgxOTU0IEM2LjI0ODcxODg0LDEyLjA0MzYwOTIgNS44NjU0OTEzOCwxMi4wNjk3NzQ3IDUuNTkxODc1MjksMTEuODYwNDUwNSBMNS41MDUwNjc5MiwxMS43ODE5NTQgTDIuMjA2MDAyNTUsOC4yNzU5NzgwMyBDMS45MzE4ODI1NCw3Ljk4NDY2NTc4IDEuOTMxMjUzMDIsNy41MTE2ODQ0MSAyLjIwNDU5NjQ2LDcuMjE5NTQ0NTUgQzIuNDUwNjA1NTUsNi45NTY2MTg2OCAyLjgzNDY4OTk4LDYuOTI5NzgyNjYgMy4xMDg4ODAyNSw3LjEzOTQxMTM0IEwzLjE5NTg2NjcxLDcuMjE4MDQ2MDMgTDYsMTAuMTk4MzE4MiBMOC44MDQxMzMyOSw3LjIxODA0NjAzIFoiIGlkPSJWZWN0b3IiIGZpbGw9IiMzNjNENTAiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+\')',
      },
    },
  }),
);

export default BaseSelect;
