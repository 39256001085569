/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef } from 'preact/hooks';
// import AppState from '@state';

import useClickOutside from '@hooks/useClickOutside';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';

import UtilityButton from '@ui-kit/buttons/utilityButton';

import ToolsInternalStatus from './sections/toolsInternalStatus';
import ToolsExports from './sections/toolsExports';
import ToolsInternalTags from './sections/toolsInternalTags';
import ToolsManageLists from './sections/toolsManageLists';
import ToolsComments from './sections/toolsComments';
import ToolsHolds from './sections/toolsHolds';

import {
  StyledToolsCard,
  ToolsCardHeader,
  ClosePanelWrapper,
  StyledToolsCardContent,
} from './ViewCompositionToolsStyles';

function ViewCompositionTools({
  compositionId,
  internalData,
  loadInternalData,
  showToolPanel,
  setShowToolsPanel,
  composition,
}) {
  const toolsRef = useRef();
  useClickOutside(toolsRef, () => setShowToolsPanel(false));

  return (
    <StyledToolsCard showTools={showToolPanel} mb="1.5em">
      <Box ref={toolsRef}>
        <ToolsCardHeader>
          <Text fontSize="0.938rem" fontWeight="600" color="var(--text-primary)">Manage Composition</Text>
          <ClosePanelWrapper>
            <UtilityButton ml="0.875em" icon="close" onClick={() => setShowToolsPanel(false)} />
          </ClosePanelWrapper>
        </ToolsCardHeader>

        <StyledToolsCardContent>
          <ToolsExports composition={composition} loadInternalData={loadInternalData} exportedStatus={internalData.exported} />

          <ToolsInternalStatus compositionId={compositionId} loadInternalData={loadInternalData} internalStatus={internalData.status} />

          {(internalData.holds.orgCanPutHold || internalData.holds.registration.isActive || internalData.holds.firstUse.isActive)
          && <ToolsHolds compositionId={compositionId} loadInternalData={loadInternalData} holds={internalData.holds} />}

          {/* <ToolsAssignedUsers assignedUsers={internalData.assignedUsers} /> */}

          <ToolsInternalTags compositionId={compositionId} loadInternalData={loadInternalData} internalTags={internalData.tags} />

          <ToolsManageLists compositionId={compositionId} loadInternalData={loadInternalData} assignedLists={internalData.lists} />

          <ToolsComments compositionId={compositionId} loadInternalData={loadInternalData} comments={internalData.comments} />
        </StyledToolsCardContent>

      </Box>
    </StyledToolsCard>
  );
}

ViewCompositionTools.propTypes = {
  compositionId: PropTypes.number.isRequired,
  internalData: PropTypes.object.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  showToolPanel: PropTypes.bool.isRequired,
  setShowToolsPanel: PropTypes.func.isRequired,
  composition: PropTypes.object.isRequired,
};

export default ViewCompositionTools;
