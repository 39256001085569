import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledCreditContainer = styled.div`
    border: 1px solid var(--border);
    border-radius: 0.5rem;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1.5em;
    overflow: visible;
    ${space};
`;

export const StyledCreditDisplayInput = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: var(--input-bg-panel);
    border: 1px solid var(--input-border);
    border-radius: 0.5rem;
    align-items: center;
    padding: 8px 12px;
`;

export const StyledContentEditableSection = styled.div`
    min-width: 4px;
    padding: 4px 0px;
    font-size: 0.875rem;
    outline: none;
    color: var(--text-primary);
    cursor: text;

    &:empty:before {
    content: attr(placeholder);
    color: #888; /* Placeholder text color */
    }
`;

export const StyledPersonaChip = styled.div`
    display: flex;
    border-radius: 0.5rem;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    background: var(--pill-base-bg);
    color: var(--text-primary);
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;
    margin: 0px 8px;
`;
