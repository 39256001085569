/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef, useState } from 'preact/hooks';

import useClickOutside from '@hooks/useClickOutside';

import Box from '@ui-kit/box';
import PopoverMenu from '@ui-kit/menu/popoverMenu';
import Text from '@ui-kit/typography/text';
import BreakLine from '@ui-kit/dividers/breakLine';

import {
  BetaChip,
} from './ContentCredentialPopoverStyles';

function ContentCredentialPopover({
  credentialData,
  ...restProps
}) {
  const [showCredentialData, setShowCredentialData] = useState(false);

  const popRef = useRef(null);
  useClickOutside(popRef, () => {
    setTimeout(() => {
      if (showCredentialData) {
        setShowCredentialData(false);
      }
    }, 250);
  });

  return (
    <Box position="relative" {...restProps}>
      <Box className="cursor-p" onClick={() => setShowCredentialData(!showCredentialData)}>
        <img src="/assets/thirdParty/cr_icon_light.png" height={18} width={18} alt="cr-icon" />
      </Box>
      {showCredentialData && (
      <PopoverMenu ref={popRef} width="14em" top="22px" right="-2px" padding="0.875rem">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text fontWeight="600" color="var(--tool-tip-text)">Content Credential</Text>
          <BetaChip>Beta</BetaChip>
        </Box>
        <BreakLine mt="0.75rem" mb="0.75rem" color="var(--panel-divider)" />
        <Text fontSize="0.667rem" mb="1rem" fontWeight="400" color="var(--text-secondary)">Anti fraud information has been digitally signed and added to this file.</Text>

        <Text fontSize="0.667rem" fontWeight="400" color="var(--text-secondary)">As more information is known about this file, the credential will be updated.</Text>
      </PopoverMenu>
      )}
    </Box>
  );
}

ContentCredentialPopover.propTypes = {
  credentialData: PropTypes.object,
};

ContentCredentialPopover.defaultProps = {
  credentialData: null,
};

export default ContentCredentialPopover;
