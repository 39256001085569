import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

export const WorkspaceWrapper = styled.div(({ isEditing }) => `
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 0em 2em;
    background: var(--background);
    -webkit-overflow-scrolling: touch;
    ${isEditing && 'overflow: hidden; height: 100%'};

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 0em 1em;
    }
    
    ${space};
`);

export const HeaderWrapper = styled.div`
    border-bottom: 1px solid var(--border);
    position: sticky;
    top: 0;
    background: var(--background);
    z-index: 200;
    ${space};
`;

export const MobileHeaderContent = styled.div`
    display: none;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 0em;
        position: relative;
    }
`;

export const MobileTitleWrapper = styled.div`
    flex: 1;
    margin-left: 1em;
`;

export const HeaderContent = styled.div`
    padding: 1em 0em;
    display:flex;
    justify-content: space-between;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display:none;
    }
`;

export const HeaderDetails = styled.div`
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    ${space};
`;

export const HeaderActions = styled.div`
    display:flex;
    justify-content: flex-end;
    align-items: center;
    ${space};
`;

export const PageWrapper = styled.div`
    display:flex;
    ${space};
`;

export const PageContent = styled.div`
    width:100%;
    max-width: 680px;
    margin: 0 auto;
    margin-top: 1.5rem;
    ${space};
`;

export const WorkItemsWrapper = styled.div`
    width:100%;
    display: flex;
    ${space};
`;

export const NavigationWrapper = styled.div`
    display:flex;
    padding: 1em 0em 1.5em 0em;
    flex-wrap: wrap-reverse;
    align-items: center;
    position: sticky;
    top: 0px;
    background: var(--background);
    z-index: 100;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding-top: 0rem;
    }
`;

export const NavigationTabs = styled.div`
    display:flex;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        flex-basis: 100%;
    }
`;

export const ProofWrapper = styled.div`
   display: flex;
   flex-direction: column;
   gap: 1.5em;
   padding-bottom: 1.5em;
   min-height: 34em;
`;
