import styled from 'styled-components';
import { useMemo } from 'preact/hooks';
import {
  space, variant, size, typography,
} from 'styled-system';

const StyledUtilityButton = styled.button(
  ({
    fluid,
    width,
    minWidth,
    maxHeight,
    icon,
  }) => {
    const btnWidth = useMemo(() => (fluid ? '100%' : width), [fluid]);

    return `
  background: var(--pill-base-bg);
  width: ${btnWidth};
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 500;
  min-width: ${icon ? '34px' : minWidth || 'auto'};
  min-height: 34px;
  max-height: ${maxHeight || '34px'};
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;
  overflow: hidden;
  padding: ${icon ? '0rem 0.5rem' : '0rem 1rem'};
  ${space}
  ${size}
  ${typography}

  &:disabled {
    cursor: not-allowed;
  }
  &:focus-visible {
    border: 2px solid var(--button-highlight);
  }
  `;
  },
  variant({
    variants: {
      secondary: {
        background: 'transparent',
        border: '1px solid var(--pill-base-bg)',
        color: 'var(--text-primary)',
      },
    },
  }),
  space,
  size,
  typography,
);

export default StyledUtilityButton;
