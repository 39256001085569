import { useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import AppState from '@state';
import { Fragment } from 'preact';
import anime from 'animejs/lib/anime.es';
import { route } from 'preact-router';
import exifr from 'exifr/dist/lite.esm.mjs';
import useErrorOverlay from '@hooks/useErrorOverlay';

import postProfileImageCheck from '@api/sidecar/profile-image-check-api';
import putAccountUserProfile from '@api/restricted/account-user-profile-api';
import loadAccountProfile from '@actions/loadAccountProfile';
import { postAccountProfilePicture } from '@api/restricted/account-profile-picture-api';
import postCreateWalletPass from '@api/sidecar/create-wallet-pass-api';

import AlertMessage from '@ui-kit/alert/Alert';
import Spinner from '@ui-kit/loaders/Spinner';
import Card from '@distinct-components/cards/baseCard';
import Header from '@ui-kit/typography/header';
import Box from '@ui-kit/box';
import BaseButton from '@ui-kit/buttons/baseButton';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import EditIcon from '@assets/icons/edit-line.svg';
import BlueCheck from '@assets/icons/blue-check.svg';
import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import CameraIcon from '@assets/icons/camera-outline.svg';
import AppleWalletButton from '@assets/thirdParty/add-to-wallet.svg';

import NO_NO_WORDS from '@constants/sensitiveContent';
import { COUNTRIES } from '@constants/sharedOptions';
import { BASE64_PROFILE } from '@constants/walletPassAssets';

import {
  AvatarImgWrapper,
  AccountDetailsWrapper,
  ActionsWrapper,
  StyledUserName,
  AvatarWrapper,
  AvatarPreviewWrapper,
  AvatarButtonWrapper,
  CircleProgress,
  InputPrefix,
  StyledBaseInput,
  VerificationBadge,
  VerificationBadgeButton,
  AvatarCheckWrapper,
  DesktopWalletButton,
  MobileWalletButton,
  WalletLoadingWrapper,
} from './ProfileAccountStyles';

function ProfileAccount() {
  const {
    register, handleSubmit, reset, setValue, trigger, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingImg, setIsUpdatingImg] = useState(false);
  const [error, setError] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [isUserNameOffensive, setIsUserNameOffensive] = useState(false);
  const [isGeneratingPass, setIsGeneratingPass] = useState(false);

  let placeholderURL = '/assets/illustrations/avatar_placeholder.png';
  const theme = document.documentElement.getAttribute('data-theme');
  if (theme === 'light') {
    placeholderURL = '/assets/illustrations/avatar_placeholder_light.png';
  }

  const completeAnimation = anime.timeline({ autoplay: false });

  const handleUploadComplete = () => {
    setIsUpdatingImg(false);
    completeAnimation.add({
      opacity: [0, 1],
      targets: '#progress',
      duration: 100,
      direction: 'normal',
    }).add({
      targets: '.circle',
      strokeDashoffset: [anime.strokeDashoffset, 0],
      duration: 400,
      direction: 'normal',
      easing: 'linear',
    }).add({
      targets: '.circle',
      scale: [1, 1.25],
      strokeWidth: [6, 1],
      translateX: 2,
      translateY: 2,
      duration: 800,
      direction: 'normal',
    }).add({
      opacity: [1, 0],
      targets: '#progress',
      duration: 100,
      direction: 'normal',
    }, '-=600')
      .add({
        duration: 500,
        complete: () => {
          completeAnimation.restart();
          completeAnimation.pause();
        },
      });
    completeAnimation.play();
  };

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (err) => {
      reject(err);
    };
  });

  const handleImageUpload = async (event) => {
    setIsUpdatingImg(true);
    setError(null);

    const file = event.target.files[0];
    setPreviewImage(URL.createObjectURL(file));
    if (file.name.includes('.heic')) {
      setError('Heic Images are not supported');
      setIsUpdatingImg(false);
      return;
    }
    if (file.type.split('/')[0] !== 'image') {
      setError('Unsupported File Type');
      setIsUpdatingImg(false);
      return;
    }
    if (file.size >= 10000000) {
      setError('Images need to be less than 10 MB');
      setIsUpdatingImg(false);
      return;
    }

    const base64Img = await convertBase64(file);
    const res = await postProfileImageCheck(base64Img);
    if (res.status !== 200) {
      try {
        const json = await res.json();
        useErrorOverlay({ errorMessage: `${json.error || res.statusText} when performing content policy check` });
      } catch {
        useErrorOverlay({ errorMessage: `${res.statusText} when performing content policy check` });
      }
      return;
    }

    setError(null);
    const json = await res.json();
    if (json.adult === 'LIKELY' || json.adult === 'VERY_LIKELY' || json.racy === 'VERY_LIKELY' || json.violence === 'VERY_LIKELY') {
      setError('This Image Does Not Align with Our Content Policies. Select Another Image');
      setIsUpdatingImg(false);
      return;
    }

    postAccountProfilePicture(file.name, file.type, file.size, file)
      .then((response) => {
        if (response.status !== 200) {
          setPreviewImage(null);
          setIsUpdatingImg(false);
          response.json()
            .then((json2) => setError(json2.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          handleUploadComplete();
        }
      })
      .catch((err) => {
        setPreviewImage(null);
        setIsUpdatingImg(false);
        setError(err.message);
      });
  };

  const checkOffensive = (name) => {
    const lowerName = name.toLowerCase();
    if (NO_NO_WORDS.some((v) => lowerName.includes(v))) {
      setIsUserNameOffensive(true);
    } else {
      setIsUserNameOffensive(false);
    }
  };

  const onUserNameChange = (event) => {
    let userName = event.target.value;
    userName = userName.replace(/[^a-zA-Z0-9 ]/g, '');
    const treatedUserName = userName.trim();
    setValue('username', treatedUserName);
    checkOffensive(treatedUserName);
    trigger('username');
  };

  const cleanName = (name) => {
    const words = name.trim().split(/\s+/);
    return words.join(' ').replaceAll('@', '');
  };

  const handleEditAccount = (data) => {
    const cleanedData = {
      username: data.username?.replaceAll(/\s/g, '').replaceAll('@', ''),
      legalName: data.legalName ? cleanName(data.legalName) : data.legalName,
      country: data.country,
      firstName: data.firstName ? cleanName(data.firstName) : data.firstName,
      lastName: data.lastName ? cleanName(data.lastName) : data.lastName,
    };
    if (!cleanedData.username || !cleanedData.legalName || !cleanedData.firstName || !cleanedData.lastName) {
      setError('Invalid input');
    } else {
      setIsLoading(true);
      putAccountUserProfile(
        cleanedData.username,
        AppState.userProfile.email.value,
        cleanedData.legalName,
        cleanedData.country,
        cleanedData.firstName,
        cleanedData.lastName,
      ).then((response) => {
        setIsLoading(false);
        if (response.status !== 200 && response.status !== 202) {
          response.json().then((json) => {
            setError(json.error ? json.error : response.statusText);
          });
        } else {
          loadAccountProfile();
          AppState.artistProfile.isEditingChild.value = false;
          setIsEditingAccount(false);
        }
      }).catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
    }
  };

  const onToggleEditAccount = () => {
    reset({
      username: AppState.userProfile.username.value,
      firstName: AppState.userProfile.firstName.value,
      lastName: AppState.userProfile.lastName.value,
      legalName: AppState.userProfile.legalName.value,
      country: AppState.userProfile.country.value,
    });
    setIsEditingAccount(!isEditingAccount);
    AppState.artistProfile.isEditingChild.value = !AppState.artistProfile.isEditingChild.value;
    if (AppState.userProfile.imgURL.value) {
      setPreviewImage(AppState.userProfile.imgURL.value);
    }
  };

  const onCloseAccountEdit = () => {
    loadAccountProfile();
    AppState.artistProfile.isEditingChild.value = false;
    setIsEditingAccount(false);
  };

  const checkOrientation = (file) => new Promise((resolve) => {
    exifr.parse(file)
      .then((data) => {
        if (data && data?.Orientation === 'Rotate 90 CW') {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch((e) => {
        console.log(`error checking image rotation: ${e.message}`);
        resolve(false);
      });
  });

  const getWalletPassImage = async () => {
    let base64Img;
    let image = null;

    if (AppState.userProfile.imgURL.value) {
      image = await fetch(AppState.userProfile.imgURL.value);
    }

    if (image) {
      const file = await image.blob();
      base64Img = await convertBase64(file);
      const imgIsRotated = await checkOrientation(file);
      return { base64Img, imgIsRotated };
    }

    base64Img = BASE64_PROFILE;
    const imgIsRotated = false;
    return { base64Img, imgIsRotated };
  };

  const constructPubInfo = async () => {
    if (AppState.publisherPresets.value.filter((preset) => preset.isDefault).length > 0) {
      const defaultPreset = AppState.publisherPresets.value.filter((preset) => preset.isDefault)[0];
      const owners = defaultPreset.owners.map((ownerPub) => `${ownerPub.name || AppState.publishers.value[ownerPub.id].name}\nSociety: ${ownerPub.society || AppState.publishers.value[ownerPub.id].society} IPI:${ownerPub.ipi || AppState.publishers.value[ownerPub.id].ipi}\n${ownerPub.address || AppState.publishers.value[ownerPub.id].address}`).join('\n\n');
      const admins = defaultPreset.admins.map((adminPub) => `${adminPub.name || AppState.publishers.value[adminPub.id].name}\nSociety: ${adminPub.society || AppState.publishers.value[adminPub.id].society} IPI:${adminPub.ipi || AppState.publishers.value[adminPub.id].ipi}\n${adminPub.address || AppState.publishers.value[adminPub.id].address}`).join('\n\n');
      const info = {
        owners,
        admins,
      };
      return info;
    }
    const info = {
      owners: 'Not Set',
      admins: 'Not Set',
    };
    return info;
  };

  const onClickAddToWallet = async () => {
    let displayName;
    if ((AppState.userProfile.firstName.value.length + AppState.userProfile.lastName.value.length) > 12) {
      displayName = `${AppState.userProfile.firstName.value}\n${AppState.userProfile.lastName.value}`;
    } else {
      displayName = `${AppState.userProfile.firstName.value} ${AppState.userProfile.lastName.value}`;
    }

    setIsGeneratingPass(true);
    const { base64Img, imgIsRotated } = await getWalletPassImage();
    const publishingInfo = await constructPubInfo();

    const data = {
      writerName: displayName,
      thumbnail: base64Img,
      rotateThumbnail: imgIsRotated,
      did: AppState.artistProfile.uuid.value,
      society: AppState.artistProfile.swCollSociety.value[0]?.name || 'Not Set',
      ipi: AppState.artistProfile.ipi.value || 'Not Set',
      isni: AppState.artistProfile.isni.value || 'Not Set',
      pubOwners: publishingInfo.owners,
      pubAdmins: publishingInfo.admins,
      isVerified: AppState.verified.value,
    };

    await postCreateWalletPass(data).then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${AppState.userProfile.firstName.value}_${AppState.userProfile.lastName.value}_Identity_Card.pkpass`);
        document.body.appendChild(link);
        link.click();
        setIsGeneratingPass(false);
      })
      .catch((e) => {
        console.log(e);
        setIsGeneratingPass(false);
      });
  };

  return (
    <Fragment>
      <Card mt="1.5em" mb="1.5em" p="0em">
        <Box display="flex" justifyContent="flex-end">
          <ActionsWrapper>
            <Icon size="1em" pt="0.25em" pr="0.25em" cursor onClick={onToggleEditAccount}><EditIcon /></Icon>
          </ActionsWrapper>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="baseline">
          <AccountDetailsWrapper>
            {AppState.userProfile.imgURL.value
              ? (
                <Box position="relative">
                  {AppState.verified.value && <AvatarCheckWrapper><Icon size="1.25em"><BlueCheck /></Icon></AvatarCheckWrapper>}
                  <AvatarImgWrapper onClick={onToggleEditAccount} avatarImg={AppState.userProfile.imgURL} />
                </Box>
              )
              : (
                <Box position="relative">
                  {AppState.verified.value && <AvatarCheckWrapper><Icon size="1.25em"><BlueCheck /></Icon></AvatarCheckWrapper>}
                  <AvatarImgWrapper onClick={onToggleEditAccount} avatarImg={placeholderURL} />
                </Box>
              )}
            <Box>
              <StyledUserName>
                {AppState.userProfile.firstName.value}
                {' '}
                {AppState.userProfile.lastName.value}
              </StyledUserName>
              <Text mb="0.5em">
                @
                {AppState.userProfile.username.value}
              </Text>
              <Box display="flex" alignItems="center">
                {AppState.verified.value
                && (
                <VerificationBadge verified={AppState.verified.value}>
                  Verified
                </VerificationBadge>
                )}
                {!AppState.verified.value && <VerificationBadgeButton onClick={() => route('/verify/get-started')}>Get Verified</VerificationBadgeButton>}
                <MobileWalletButton>
                  {isGeneratingPass
                    ? (
                      <WalletLoadingWrapper>
                        <Spinner size="1em" />
                      </WalletLoadingWrapper>
                    )
                    : (
                      <AppleWalletButton onClick={onClickAddToWallet} />
                    )}
                </MobileWalletButton>
              </Box>
            </Box>
          </AccountDetailsWrapper>
          <DesktopWalletButton>
            {isGeneratingPass
              ? (
                <WalletLoadingWrapper>
                  <Spinner size="1em" />
                </WalletLoadingWrapper>
              )
              : (
                <AppleWalletButton onClick={onClickAddToWallet} />
              )}
          </DesktopWalletButton>
        </Box>
      </Card>
      {isEditingAccount && (
      <BaseEditOverlay closeFunction={onCloseAccountEdit}>
        <AvatarWrapper>
          <AvatarPreviewWrapper previewImage={previewImage}>
            <CircleProgress>
              <svg id="progress" viewBox="0 0 112 112">
                <circle className="circle" cx="50" cy="50" r="50" stroke="var(--purple-300)" strokeWidth="6" fill="transparent" />
              </svg>
            </CircleProgress>
          </AvatarPreviewWrapper>
          <AvatarButtonWrapper>
            {isUpdatingImg
              ? (
                <Spinner size="1em" width="2px" />
              )
              : (
                <Icon size="0.938em" pt="3px" color="var(--brand-primary)" cursor><CameraIcon /></Icon>
              )}
            <input
              type="file"
              onChange={handleImageUpload}
              disabled={isUpdatingImg}
            />
          </AvatarButtonWrapper>
        </AvatarWrapper>
        <Box display="flex" justifyContent="center" mb="1.5em">
          <Header fontSize="1.25rem">Edit Account Details</Header>
        </Box>
        <form onSubmit={handleSubmit(handleEditAccount)} autoComplete="on">
          <InputLabel label="Primary User Name" />
          <Box display="flex" alignItems="center" mb={isUserNameOffensive ? '0em' : '1.5em'}>
            <InputPrefix>@</InputPrefix>
            <StyledBaseInput
              {...register('username', { required: true, minLength: 3 })}
              id="username"
              name="username"
              placeholder=""
              onChange={onUserNameChange}
              onPaste={(e) => e.preventDefault()}
              type="text"
              maxLength={30}
              fluid
            />
          </Box>
          {isUserNameOffensive && (
          <InputLabel
            mb="1.5em"
            mt="0.5em"
            color="var(--red-300)"
            label="This username violates our content policy. Choose another"
          />
          ) }
          <InputLabel label="First Name" />
          <BaseInput
            {...register('firstName', { required: true })}
            id="firstName"
            name="firstName"
            type="text"
            fluid
            mb="1.5rem"
          />
          <InputLabel label="Last Name" />
          <BaseInput
            {...register('lastName', { required: true })}
            id="lastName"
            name="lastName"
            type="text"
            fluid
            mb="1.5rem"
          />
          <InputLabel label="Legal Name" />
          <BaseInput
            {...register('legalName', { required: true })}
            id="legalName"
            name="legalName"
            type="text"
            fluid
            mb="1.5rem"
          />
          <InputLabel label="Country of Residence" />
          <BaseSelect
            {...register('country', { required: true })}
            id="country"
            name="country"
            fluid
            mb="2.5em"
          >
            {COUNTRIES.map((c) => (
              <option value={c.name}>{c.name}</option>
            ))}
          </BaseSelect>
          {error
            && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}
          <BaseButton mb="1.5em" type="submit" btnText="Submit" disabled={!isValid || isUserNameOffensive} fluid isLoading={isLoading} />
        </form>
      </BaseEditOverlay>
      )}
    </Fragment>
  );
}

export default ProfileAccount;
