import { useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

// import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
// import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';

import CreditsSelector from '@distinct-components/credits/creditsSelector';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

function ManageArtistAndCreditsOverlay({
  activeUser,
  recordingData,
  artistToManage,
  closeFunction,
}) {
  useEffect(async () => {
    console.log('loaded artist overlay');
    console.log('active user', activeUser);
    console.log('artist to manage', artistToManage);
  }, []);

  const handleCreditsSaved = () => {
    closeFunction();
  };

  return (
    <BaseEditOverlay formWidth="40rem" closeFunction={closeFunction}>
      <Box>
        <CreditsSelector
          workspaceId={recordingData.uuid}
          recordingTitle={recordingData.recordingTitle}
          canManagePersonalPrefs={activeUser.id === artistToManage.id}
          onCreditsSaved={handleCreditsSaved}
          existingCredits={artistToManage.credits}
          isAdmin={activeUser.isAdmin}
          selectedArtist={artistToManage}
          handleCloseParent={closeFunction}
        />
      </Box>
    </BaseEditOverlay>
  );
}

ManageArtistAndCreditsOverlay.propTypes = {
  activeUser: PropTypes.object.isRequired,
  artistToManage: PropTypes.object.isRequired,
  recordingData: PropTypes.object.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default ManageArtistAndCreditsOverlay;
