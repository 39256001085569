/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useState } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';
import AppState from '@state';

import useResponsiveText from '@hooks/useResponsiveText';
import { useProfilePictureLink } from '@hooks/useImageLink';
import useWindowDimensions from '@hooks/useWindowDimensions';

import NO_NO_WORDS from '@constants/sensitiveContent';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import InputLabel from '@ui-kit/inputs/inputLabel';
import AlertMessage from '@ui-kit/alert/Alert';
import Spinner from '@ui-kit/loaders/Spinner';
import BreakLine from '@ui-kit/dividers/breakLine';
import Icon from '@ui-kit/icon';
import ClearIcon from '@assets/icons/close-solo.svg';
import BaseButton from '@ui-kit/buttons/baseButton';
import Avatar from '@ui-kit/avatar';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import ConfirmDeleteUtilityBtn from '@distinct-components/distinctButtons/confirmDeleteUtilityBtn';

import CreditSelectorEmptyState from '../creditSelectorEmptyState';
import CreditSelectorListItem from '../creditSelectorListItem';
import CreditSelectorAddedCredit from '../creditSelectorAddedCredit';

import {
  StyledCreditsListWrapper,
  StyledClearSearchChip,
  StyledIndicatorWrapper,
  StyledCreditsAddedWrapper,
  StyledUserInfoWrapper,
  StyledAdminToolsRow,
} from './CreditsSelectorStyles';

const MOCK_DEFAULT_CREDITS = [
  {
    id: 88, displayName: 'Main Producer', ddexName: 'Audio Producer', displayPrefix: 'Album Producer -', displaySuffix: null, isDefault: true,
  },
  {
    id: 89, displayName: 'Mixing Engineer', ddexName: 'Audio Mixer', displayPrefix: 'Mixed by', displaySuffix: 'with love', isDefault: true,
  },
  {
    id: 90, displayName: 'Sound Engineer', ddexName: 'Audio Engineer', displayPrefix: null, displaySuffix: 'made the sounds', isDefault: true,
  },
];

const MOCK_FULL_CREDITS = [
  {
    id: 1, displayName: 'Main Vocals', ddexName: 'TBD',
  },
  {
    id: 2, displayName: 'Background Vocals', ddexName: 'TBD',
  },
  {
    id: 3, displayName: 'Guitar', ddexName: 'TBD',
  },
  {
    id: 4, displayName: 'Piano', ddexName: 'TBD',
  },
  {
    id: 5, displayName: 'Percussion: Drums', ddexName: 'TBD',
  },
  {
    id: 6, displayName: 'Percussion: Tambourine', ddexName: 'TBD',
  },
];

function CreditsSelector({
  workspaceId,
  existingCredits,
  onCreditsSaved,
  selectedArtist,
  recordingTitle,
  isAdmin,
  canManagePersonalPrefs,
  handleCloseParent,
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allCreditsList, setAllCreditsList] = useState([]);
  const [defaultCreditsList, setDefaultCreditsList] = useState([]);
  const [userHasDefaults, setUserHasDefaults] = useState(false);
  const [isAddingCredit, setIsAddingCredit] = useState(false);
  const [isSavingCredits, setIsSavingCredits] = useState(false);
  const [addedCredits, setAddedCredits] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [creditListMode, setCreditListMode] = useState('all');
  const [isMakingFeaturedArtist, setIsMakingFeaturedArtist] = useState(false);
  const [isRemovingArtist, setIsRemovingArtist] = useState(false);

  const rt = useResponsiveText;
  const ppl = useProfilePictureLink;
  const { width } = useWindowDimensions();
  const defaultsLabel = AppState.accountId.value === selectedArtist.id ? 'My Defaults' : 'Artist Defaults';

  // Utility to filter out added credits from list;
  const excludeAddedCreditsFromLists = (credit) => {
    const defaults = defaultCreditsList;
    const allCredits = allCreditsList;

    const filteredDefaults = defaults.filter((c) => c.id !== credit.id);
    const filteredCredits = allCredits.filter((c) => c.id !== credit.id);
    setDefaultCreditsList(filteredDefaults);
    setAllCreditsList(filteredCredits);
  };

  useEffect(() => {
    // TODO: LOAD THE ACTUAL CREDITS FROM THE DB
    if (existingCredits.length) {
      console.log('existing credits', existingCredits);
      setAddedCredits(existingCredits);
      setCreditListMode('added');
      addedCredits.forEach((c) => {
        excludeAddedCreditsFromLists(c);
      });
    }

    setAllCreditsList(MOCK_FULL_CREDITS);
    setDefaultCreditsList(MOCK_DEFAULT_CREDITS);

    if (defaultCreditsList.length !== 0) {
      setUserHasDefaults(true);
    }

    if (addedCredits.length === 0 && userHasDefaults) {
      setCreditListMode('defaults');
    }

    // MOCK TIME
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, []);

  useLayoutEffect(() => {
    if (!isLoading && addedCredits.length > 0 && creditListMode !== 'added') {
      anime({
        targets: '#indicator',
        scale: [0.9, 1],
        opacity: [0, 1],
        duration: 2000,
        translateY: [8, 0],
        easing: 'spring(1, 80, 10, 0)',
      });
    }
  }, [addedCredits]);

  const onClickAddCredit = (credit) => {
    if (isAddingCredit) { return; }
    setError(null);
    setIsAddingCredit(true);
    const existingCreditsList = addedCredits;

    // newly added credits marked unsaved
    credit.unsaved = true;

    if (!credit.isDefault) {
      credit.displayPrefix = `${credit.displayName} -`;
      credit.displaySuffix = null;
      credit.isDefault = false;
    }

    existingCreditsList.push(credit);
    setAddedCredits([...existingCredits]);
    excludeAddedCreditsFromLists(credit);

    // UX Time - Leave
    setTimeout(() => {
      setIsAddingCredit(false);
    }, 600);
  };

  const onClickReviewCredits = () => {
    setCreditListMode('added');
  };

  const handleUpdateCredit = (updatedCredit) => {
    setAddedCredits((prevCredits) => prevCredits.map((credit) => (credit.id === updatedCredit.id ? updatedCredit : credit)));
  };

  const onClickRemoveCredit = (credit) => {
    setError(null);
    const reducedCredits = addedCredits.filter((c) => c.id !== credit.id);
    setAddedCredits(reducedCredits);
    if (credit.isDefault) {
      defaultCreditsList.push(credit);
    } else {
      allCreditsList.push(credit);
    }
  };

  const onClickSaveCredits = () => {
    setError(null);
    setIsSavingCredits(true);

    let clean = true;
    let missingText = false;
    addedCredits.forEach((c) => {
      if (c.displayPrefix === '' || c.displaySuffix === '') {
        missingText = true;
      }
      const displayText = (`${c.displayPrefix} ${c.displaySuffix}`).toLowerCase();
      if (NO_NO_WORDS.some((w) => displayText.includes(w))) {
        clean = false;
      }
    });

    if (!clean) {
      // TIME FOR STATE
      setTimeout(() => {
        setError('Your credits contain unacceptable content.');
        setIsSavingCredits(false);
      }, 500);
      return;
    }

    if (missingText) {
      // TIME FOR STATE
      setTimeout(() => {
        setError('One or more credits is missing display text');
        setIsSavingCredits(false);
      }, 500);
      return;
    }

    // MOCK TIME - can remove
    setTimeout(() => {
      alert('save all the credits');
      console.log('all added credits', addedCredits);
      setIsSavingCredits(false);
    }, 1800);

    if (onCreditsSaved) {
      onCreditsSaved();
    }
  };

  const handleToggleFeaturedArtist = () => {
    setIsMakingFeaturedArtist(true);
    selectedArtist.isFeaturedArtist = !selectedArtist.isFeaturedArtist;

    // MOCK TIME - can remove
    setTimeout(() => {
      setIsMakingFeaturedArtist(false);
    }, 800);
  };

  const handleRemoveArtistFromRecording = () => {
    setIsRemovingArtist(true);
    selectedArtist.isFeaturedArtist = !selectedArtist.isFeaturedArtist;

    // MOCK TIME - can remove
    setTimeout(() => {
      setIsRemovingArtist(false);
      // NEED TO CLOSE THE MODAL
      handleCloseParent();
    }, 3000);
  };

  return (
    <Box>
      {isLoading
        ? (
          <Box className="anti-flicker-anim-1s" width="100%" display="flex" justifyContent="center" mt="3rem">
            <Spinner size="1.5em" variant="page" />
          </Box>
        ) : (
          <Box {...restProps}>

            {selectedArtist && (
            <StyledUserInfoWrapper isAdmin={isAdmin} display="flex">
              <Box>
                {selectedArtist.imgExists
                  ? (
                    <Avatar
                      size="4em"
                      borderSize="2px"
                      imgURL={ppl(selectedArtist.id)}
                      isVerified={selectedArtist.isVerified}
                      checkSize="1rem"
                      checkX="0px"
                      checkY="4px"
                    />
                  )
                  : (
                    <Avatar
                      size="4em"
                      borderSize="2px"
                      imgURL={null}
                      isVerified={selectedArtist.isVerified}
                      checkSize="1rem"
                      checkX="0x"
                      checkY="4px"
                    />
                  )}
              </Box>
              <Box>
                <Text fontWeight="500" fontFamily="var(--font-lexend)" color="var(--text-primary)" lineHeight="1.6" fontSize="0.875rem" maxWidth={width > 400 ? '400px' : '150px'} truncate>
                  {selectedArtist.firstName}
                  {' '}
                  {selectedArtist.lastName}
                </Text>

                <Text fontSize="0.813rem" color="var(--text-secondary)">
                  ISNI:
                  {' '}
                  {selectedArtist.isni ? selectedArtist.isni : 'Not Defined'}
                </Text>
                <Text fontSize="0.813rem" color="var(--text-secondary)">
                  Credits for:
                  {' '}
                  {recordingTitle}
                </Text>

              </Box>
            </StyledUserInfoWrapper>
            )}

            {isAdmin && (
              <StyledAdminToolsRow>
                <Box>
                  <Text fontWeight="600">Admin Actions</Text>
                  <Text fontSize="0.75rem" color="var(--text-secondary)">Additional options for this artist</Text>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    <Text fontSize="0.813rem" mr="0.5rem">Featured Artist</Text>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <ToggleSwitch loading={isMakingFeaturedArtist} id="isFeaturedArtist">
                      <input
                        type="checkbox"
                        name="isFeaturedArtist"
                        disabled={isMakingFeaturedArtist}
                        id="isFeaturedArtist"
                        checked={selectedArtist.isFeaturedArtist}
                        onChange={(e) => handleToggleFeaturedArtist(e)}
                      />
                    </ToggleSwitch>
                    <ConfirmDeleteUtilityBtn
                      ml="0.875rem"
                      primaryText="Remove Artist"
                      confirmText="Yes, remove from recording"
                      destructiveFunction={handleRemoveArtistFromRecording}
                      isPerformingDestructiveAction={isRemovingArtist}
                    />
                  </Box>
                </Box>
              </StyledAdminToolsRow>
            )}

            <Box display="flex" mb="1.5em" width="100%">
              <ButtonSegment mr="2px" onClick={() => setCreditListMode('defaults')} btnText={rt({ text: defaultsLabel, mobileText: 'Defaults' })} variant="first" active={creditListMode === 'defaults'} fluid maxHeight="inherit" />
              <ButtonSegment onClick={() => setCreditListMode('all')} btnText={rt({ text: 'All Credits', mobileText: 'All' })} variant="last" active={creditListMode === 'all'} fluid maxHeight="inherit" />
              <Box className="box-relative w100p" ml="2em">
                <ButtonSegment onClick={() => setCreditListMode('added')} btnText="Added" variant="solo" active={creditListMode === 'added'} fluid maxHeight="inherit" />
                <StyledIndicatorWrapper
                  id="indicator"
                  count={addedCredits.length}
                  background={addedCredits.length > 0 ? 'var(--color-green)' : 'var(--pill-indicator-idle-bg)'}
                >
                  {addedCredits.length}
                </StyledIndicatorWrapper>
              </Box>
            </Box>

            <BreakLine mt="0.5rem" mb="1.25rem" color="var(--panel-info-border)" />

            {(creditListMode === 'all' || creditListMode === 'defaults') && (
              <Box style={{ position: 'relative' }}>
                <InputLabel label="Search Credits" />
                <BaseInput
                  id="searchCredits"
                  name="searchCredits"
                  placeholder="Start typing..."
                  value={searchValue}
                  type="text"
                  fluid
                  mb="0.5em"
                  onChange={(e) => setSearchValue((e.target.value))}
                  autoComplete="off"
                />
                {searchValue.length > 0
                && (
                <StyledClearSearchChip onClick={() => setSearchValue('')}>
                  <Icon size="0.65em" color="var(--text-secondary)" cursor><ClearIcon /></Icon>
                </StyledClearSearchChip>
                )}
              </Box>
            )}

            {{
              added:
                (
                  <StyledCreditsAddedWrapper mt="1rem">
                    {addedCredits.map((c) => (
                      <CreditSelectorAddedCredit
                        credit={c}
                        handleRemoveCredit={onClickRemoveCredit}
                        handleUpdateCredit={handleUpdateCredit}
                        canManagePersonalPrefs={canManagePersonalPrefs}
                      />
                    ))}

                    {addedCredits.length === 0 && (
                    <CreditSelectorEmptyState
                      text="No Credits Added for this Recording"
                    />
                    )}
                  </StyledCreditsAddedWrapper>
                ),
              defaults:
                (
                  <StyledCreditsListWrapper mt="1rem">
                    {defaultCreditsList
                      .filter((credit) => credit.displayName.includes(searchValue))
                      .sort((a, b) => a.displayName.localeCompare(b.displayName))
                      .map((c) => (
                        <CreditSelectorListItem credit={c} onClick={() => onClickAddCredit(c)} disabled={isAddingCredit} />
                      ))}

                    {defaultCreditsList.filter((credit) => credit.displayName.includes(searchValue)).length === 0 && (
                    <CreditSelectorEmptyState
                      text={searchValue ? 'No Default Credits Matching Your Search' : userHasDefaults ? 'All Default Credits Applied' : 'Add Credits and Save as Default for Next Time'}
                    />
                    )}
                  </StyledCreditsListWrapper>
                ),
              all:
                (
                  <StyledCreditsListWrapper mt="1rem">
                    {allCreditsList
                      .filter((credit) => credit.displayName.includes(searchValue))
                      .sort((a, b) => a.displayName.localeCompare(b.displayName))
                      .map((c) => (
                        <CreditSelectorListItem credit={c} onClick={() => onClickAddCredit(c)} disabled={isAddingCredit} />
                      ))}

                    {allCreditsList.filter((credit) => credit.displayName.includes(searchValue)).length === 0 && (
                    <CreditSelectorEmptyState
                      text="No Credits Matching Your Search"
                    />
                    )}
                  </StyledCreditsListWrapper>
                ),
            }[creditListMode]}

            {error
              && (
                <AlertMessage
                  variant="negative"
                  message={error}
                  mb="1.5em"
                />
              )}

            {addedCredits.some((c) => c.unsaved) > 0 && creditListMode !== 'added'
            && (
            <BaseButton
              mb="2.5em"
              type="submit"
              btnText="Review and Save"
              fluid
              onClick={onClickReviewCredits}
            />
            )}

            {addedCredits.some((c) => c.unsaved) > 0 && creditListMode === 'added'
            && (
            <BaseButton
              mb="2.5em"
              type="submit"
              btnText="Save Credits"
              fluid
              isLoading={isSavingCredits}
              onClick={onClickSaveCredits}
            />
            )}

          </Box>
        )}
    </Box>
  );
}

CreditsSelector.propTypes = {
  workspaceId: PropTypes.string.isRequired,
  recordingTitle: PropTypes.string,
  selectedArtist: PropTypes.object,
  existingCredits: PropTypes.array,
  onCreditsSaved: PropTypes.func,
  isAdmin: PropTypes.bool,
  canManagePersonalPrefs: PropTypes.bool,
  handleCloseParent: PropTypes.func,
};

CreditsSelector.defaultProps = {
  recordingTitle: null,
  selectedArtist: null,
  existingCredits: null,
  onCreditsSaved: null,
  isAdmin: false,
  canManagePersonalPrefs: true,
  handleCloseParent: null,
};

export default CreditsSelector;
