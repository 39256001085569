/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import AppState from '@state';
import { useState } from 'preact/hooks';

import { postOrgCompositionHold } from '@api/restricted/org-composition-holds-api';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';
import Box from '@ui-kit/box';
import ActionButtonPair from '@distinct-components/buttonGroups/actionButtonPair';

function ApplyHoldForm({
  compositionId,
  holdType,
  handleHoldStatusUpdated,
  handleCancel,
  loadInternalData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [holdReason, setHoldReason] = useState(null);
  const [error, setError] = useState(null);

  const onClickApplyHold = () => {
    setIsLoading(true);
    setError(null);
    postOrgCompositionHold(AppState.pubOrganization.id.value, compositionId, holdType, holdReason)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            setIsLoading(false);
            handleHoldStatusUpdated();
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };
  return (
    <Box>

      <BaseTextArea
        placeholder="Explain the reason for this hold..."
        type="textarea"
        value={holdReason}
        onChange={(e) => setHoldReason((e.target.value))}
        fluid
        maxLength="250"
        mb="1.5em"
      />

      {error
      && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      {!isLoading
      && (
      <ActionButtonPair
        primaryText="Apply Hold"
        primaryFunction={onClickApplyHold}
        secondaryText="Cancel"
        secondaryFunction={handleCancel}
        mb="2.5rem"
      />
      )}

    </Box>
  );
}

ApplyHoldForm.propTypes = {
  compositionId: PropTypes.number.isRequired,
  holdType: PropTypes.string.isRequired,
  handleHoldStatusUpdated: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  loadInternalData: PropTypes.func.isRequired,
};

export default ApplyHoldForm;
