import {
  useEffect, useState, useRef,
  useLayoutEffect,
} from 'preact/hooks';
import AppState from '@state';
import WaveSurfer from 'wavesurfer.js';

import { getWorkspaceRecordingFile } from '@api/restricted/workspace-recording-api';

import useTruncateText from '@hooks/useTruncateText';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import Text from '@ui-kit/typography/text';
import FileArtworkImage from '../files/fileArtworkImage/FileArtworkImage';

import {
  PlayerContainer,
  StyledPlayerContentRow,
  StyledTrackInfoWrapper,
  StyledWaveAreaWrapper,
  StyledWaveFormWrapper,
  StyledTimeWrapper,
  StyledPlayerIconWrapper,
  StyledErrorPanel,
} from './AudioPlayerStyles';

function MusicPlayer() {
  const [recordingError, setRecordingError] = useState(null);
  const [isLoadingRecording, setIsLoadingRecording] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const tt = useTruncateText;

  const file = AppState.media.currentlyPlaying.value;
  const uuid = AppState.media.currentlyPlayingUUID.value;
  const artworkURL = AppState.media.artworkURL.value;

  const compositionName = AppState.media.compositionName.value;
  const isCompositionFileMaster = AppState.media.isCompositionFileMaster.value;

  const recordingName = AppState.media.recordingName.value;
  const recordingProjectName = AppState.media.recordingProjectName.value;
  const recordingProjectArtistName = AppState.media.recordingProjectArtistName.value;

  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);

  useEffect(() => {
    setRecordingError(null);
    if (AppState.media.currentlyPlaying.value) {
      if (waveSurferRef.current) { waveSurferRef.current.destroy(); }
      setIsLoadingRecording(true);
      getWorkspaceRecordingFile(uuid, file.id)
        .then((response) => {
          if (response.status === 200) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              setTimeout(() => {
                if (waveformRef.current) {
                  waveSurferRef.current = WaveSurfer.create({
                    container: waveformRef.current,
                    waveColor: '#9E82EC',
                    progressColor: '#6740D8',
                    height: 64,
                    barWidth: 7,
                    barHeight: 0.7,
                    barGap: 2,
                    responsive: true,
                    partialRender: true,
                  });
                  waveSurferRef.current.load(url);

                  waveSurferRef.current.on('ready', () => {
                    setIsLoadingRecording(false);
                    console.log('WaveSurfer is ready');
                    setDuration(waveSurferRef.current.getDuration());
                    waveSurferRef.current.playPause();
                    AppState.media.isPlaying.value = true;
                  });

                  waveSurferRef.current.on('audioprocess', () => {
                    const time = waveSurferRef.current.getCurrentTime();
                    setCurrentTime(time);
                    AppState.media.currentTime.value = time;
                  });

                  waveSurferRef.current.on('finish', () => {
                    console.log('Playback has finished!');
                    AppState.media.isPlaying.value = false;
                    AppState.media.currentlyPlaying.value = null;
                  });

                  document.addEventListener('keydown', (event) => {
                    if (event.code === 'MediaPlayPause') {
                      waveSurferRef.current.playPause();
                      AppState.media.isPlaying.value = !AppState.media.isPlaying.value;
                    }
                  });
                  navigator.mediaSession.setActionHandler('play', () => {
                    waveSurferRef.current.playPause();
                    AppState.media.isPlaying.value = !AppState.media.isPlaying.value;
                  });
                  navigator.mediaSession.setActionHandler('pause', () => {
                    waveSurferRef.current.playPause();
                    AppState.media.isPlaying.value = !AppState.media.isPlaying.value;
                  });
                }
              }, 500);
            });
          } else {
            response.json()
              .then((json) => setRecordingError(json.error || response.statusText))
              .catch(() => setRecordingError(response.statusText));
            setIsLoadingRecording(false);
          }
        })
        .catch((err) => {
          setRecordingError(err.message);
          setIsLoadingRecording(false);
        });
    } else if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
      waveSurferRef.current.destroy();
    }
  }, [file, uuid]);

  useLayoutEffect(() => {
    if (waveSurferRef.current && AppState.media.workspaceElementSeekTime.value !== null) {
      waveSurferRef.current.seekTo(AppState.media.workspaceElementSeekTime.value / duration);
    }
  }, [AppState.media.workspaceElementSeekTime.value]);

  const onClickClosePlayer = () => {
    AppState.media.currentlyPlaying.value = null;
    AppState.media.isPlaying.value = false;
  };

  const handlePlay = () => {
    if (waveSurferRef.current) {
      waveSurferRef.current.playPause();
      AppState.media.isPlaying.value = !AppState.media.isPlaying.value;
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // TODO: CLEAN UP CONSOLE LOGS AFTER ALL FEATURES ADDED
  return (
    <PlayerContainer isActive={file !== null}>
      {AppState.media.currentlyPlaying.value
      && (
        <StyledPlayerContentRow>

          <StyledTrackInfoWrapper>

            <FileArtworkImage
              fileId={file.id}
              handleFilePlay={handlePlay}
              artworkURL={artworkURL}
              isFileAvailable={!isLoadingRecording}
              isLoading={isLoadingRecording}
            />

            <Box>
              {!recordingName
                ? (
                  <Box>
                    {compositionName
                    && (
                    <Text fontSize="0.813rem" fontWeight="500" lineHeight="1.6">
                      {tt(compositionName, { sm: 40, md: 40, lg: 40 })}
                    </Text>
                    )}
                    <Text fontSize="0.688rem" fontWeight="400" color="var(--text-secondary)" lineHeight="1.3">
                      {tt(file?.name, { sm: 40, md: 40, lg: 40 }, { keepExtension: true })}
                    </Text>
                    <Text fontSize="0.688rem" fontWeight="400" color="var(--text-secondary)">
                      {isCompositionFileMaster ? 'Master' : 'Demo'}
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <Text fontSize="0.813rem" fontWeight="500" lineHeight="1.6">
                      {tt(recordingName, { sm: 40, md: 40, lg: 40 })}
                    </Text>
                    <Text fontSize="0.688rem" fontWeight="400" color="var(--text-secondary)" lineHeight="1.3">
                      {tt(recordingProjectName, { sm: 40, md: 40, lg: 40 })}
                    </Text>
                    <Text fontSize="0.688rem" fontWeight="400" color="var(--text-secondary)">
                      {tt(recordingProjectArtistName, { sm: 40, md: 40, lg: 40 })}
                    </Text>
                  </Box>
                )}
            </Box>

          </StyledTrackInfoWrapper>

          <StyledWaveAreaWrapper>

            {recordingError && (
              <StyledErrorPanel>
                <Text fontSize="0.813rem" color="var(--text-primary)" fontWeight="500">Unable to Load Audio: </Text>
                <Text fontSize="0.813rem" color="var(--red-200)" fontWeight="400">{recordingError}</Text>
              </StyledErrorPanel>
            )}

            <StyledWaveFormWrapper>
              <div ref={waveformRef} />
            </StyledWaveFormWrapper>

          </StyledWaveAreaWrapper>

          <StyledTimeWrapper>
            <Text fontSize="0.75rem" color="var(--text-secondary)">
              {formatTime(currentTime)}
              {' '}
              /
              {' '}
              {formatTime(duration)}
            </Text>
          </StyledTimeWrapper>

          <StyledPlayerIconWrapper>
            <Icon pt="3px" size="0.65em" color="var(--text-primary)" cursor onClick={onClickClosePlayer}><CloseIcon /></Icon>
          </StyledPlayerIconWrapper>

        </StyledPlayerContentRow>
      )}
    </PlayerContainer>
  );
}

export default MusicPlayer;
