/* eslint-disable object-curly-newline */
import { useState, useEffect } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import loadAccountPublishers from '@actions/loadAccountPublishers';
import loadAccountPublisherPresets from '@actions/loadAccountPublisherPresets';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import PanelCard from '@distinct-components/cards/panelCard';
import InputLabel from '@ui-kit/inputs/inputLabel';
import PanelDataCard from '@distinct-components/cards/panelDataCard';
import LinkButton from '@ui-kit/buttons/linkButton';
import AlertMessage from '@ui-kit/alert/Alert';

import { ACCOUNT_EXPLANATIONS } from '@constants/supportingCopy';

import ManagePublisherOverlay from '@shared-overlays/managePublisherOverlay/ManagePublisherOverlay';
import ProfilePublisherCard from './profilePublisherCard/ProfilePublisherCard';

function ProfilePublisherSection() {
  const [isManagingPublisher, setIsManagingPublisher] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultPreset, setDefaultPreset] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [showPresetList, setShowPresetList] = useState(false);

  const TODAY_DATE = new Date().toISOString();

  useEffect(() => {
    loadAccountPublishers().then(() => {
      loadAccountPublisherPresets().then(() => {
        setDefaultPreset(AppState.publisherPresets.value.filter((preset) => preset.isDefault)[0]);
        setIsLoading(false);
      });
    });
  }, []);

  const onManagePublisherPreset = () => {
    if (!defaultPreset && AppState.publisherPresets.value.length > 0) {
      setShowPresetList(true);
    }
    setIsManagingPublisher(true);
    AppState.artistProfile.isEditingChild.value = true;
    AppState.artistProfile.isEditingCurrentPreset.value = true;
  };

  const onManagePublisherComplete = () => {
    setDefaultPreset(AppState.publisherPresets.value.filter((preset) => preset.isDefault)[0]);
    setIsManagingPublisher(false);
    setIsAssigning(false);
    setShowPresetList(false);
    AppState.artistProfile.isEditingChild.value = false;
  };

  const onClickAssignPreset = () => {
    setIsAssigning(true);
    setIsManagingPublisher(true);
    AppState.artistProfile.isEditingChild.value = true;
  };

  const onClickManageAllPresets = () => {
    setShowPresetList(true);
    setIsManagingPublisher(true);
    AppState.artistProfile.isEditingChild.value = true;
  };

  return (
    <Fragment>
      <PanelCard
        title="Publishing Information"
        description={ACCOUNT_EXPLANATIONS.publishers.description}
        descriptionWidth="44em"
        minHeight="20em"
        darkPanel
      >
        {!isLoading
        && (
        <Box mt="1.5em" mb="1.5em">

          {defaultPreset && !defaultPreset.shareIsValid
          && (
          <AlertMessage
            variant="warning"
            message="Ownership does not equal 100%. Edit and define the missing ownership."
            mb="1em"
          />
          )}

          {defaultPreset && defaultPreset.isRevoked
          && (
          <AlertMessage
            variant="negative"
            message="Publisher revoked this connection. Update your default Publishing Info."
            mb="1em"
          />
          )}

          {defaultPreset && defaultPreset.validPeriod.to !== null && (TODAY_DATE > defaultPreset.validPeriod.to)
          && (
          <AlertMessage
            variant="negative"
            message="This verified connection has expired. Update your default Publishing Info."
            mb="1em"
          />
          )}

          {!defaultPreset
            ? (
              <Fragment>
                <InputLabel mb="0.75em" label="Original Publisher and Administration" />
                <PanelDataCard
                  variant={AppState.artistProfile.publisherCritical.value ? 'warning' : 'initialize'}
                  onClick={onManagePublisherPreset}
                >
                  <Text fontWeight="600">Setup Publisher Info</Text>
                </PanelDataCard>
              </Fragment>
            )
            : (
              <Fragment>
                <InputLabel mb="0.75em" label="Default Publishing Relationship" />
                <PanelDataCard
                  padding="1rem"
                  onClick={onManagePublisherPreset}
                >
                  {defaultPreset.publishers.map((publisher, index) => (
                    <ProfilePublisherCard publisher={publisher} mt={index > 0 ? '1rem' : '0rem'} />
                  ))}
                </PanelDataCard>
                <Box display="flex" justifyContent="flex-end" mt="1em">
                  <LinkButton btnText="Assign to Compositions" variant="small" color="var(--text-primary)" mr="0.75em" onClick={onClickAssignPreset} />
                  <LinkButton btnText="Manage Publishers" variant="small" color="var(--text-primary)" onClick={onClickManageAllPresets} />
                </Box>
              </Fragment>

            )}
        </Box>
        )}
      </PanelCard>

      {isManagingPublisher && (
        <ManagePublisherOverlay
          closeFunction={onManagePublisherComplete}
          showPresetList={showPresetList}
          initPresetId={defaultPreset?.id}
          isAssign={isAssigning}
        />
      )}
    </Fragment>
  );
}

export default ProfilePublisherSection;
