/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
// import dayjs from 'dayjs';

import AppState from '@state';
import { getOrgConnection } from '@api/restricted/org-connections-api';

import d from '@hooks/useIsoDateFormat';
import useClickOutside from '@hooks/useClickOutside';
import useKeyPressAction from '@hooks/useKeyPressAction';
import useErrorOverlay from '@hooks/useErrorOverlay';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Logo from '@ui-kit/logo/Logo';
import Icon from '@ui-kit/icon';
import CloseIcon from '@assets/icons/close-solo.svg';
import Tabs from '@ui-kit/tabs/Tabs';
// import LinkButton from '@ui-kit/buttons/linkButton';

import ProfileOverlayStats from './sections/profileOverlayStats';
import ProfileOverlayCredentials from './sections/profileOverlayCredentials';

import {
  ModalContainer,
  ModalPage,
  ModalHeader,
  StyledCredentialBadge,
  StyledCloseButton,
  ProfileTabsSection,
} from './PublisherProfileOverlayStyles';

function PublisherProfileOverlay({
  closeFunction,
  pub,
  setCredToRevoke,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [profileTab, setProfileTab] = useState('stats');
  const [writerCount, setWriterCount] = useState(0);
  const [workCount, setWorkCount] = useState(0);
  const [credentials, setCredentials] = useState([]);

  const ref = useRef();
  useClickOutside(ref, () => closeFunction());

  useKeyPressAction('Escape', () => {
    closeFunction();
  });

  const loadAnimation = anime.timeline({
    autoplay: false,
  });

  const loadPubProfile = async () => {
    try {
      const response = await getOrgConnection(AppState.pubOrganization.id.value, pub.orgId);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading publisher profile` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading publisher profile` });
        }
      } else {
        const json = await response.json();

        setWriterCount(json.writerCount);
        setWorkCount(json.compositionCount);

        setCredentials(json.credentials.map((c) => ({
          id: c.id,
          isActive: c.isActive,
          createdAt: c.createdDate,
          issuedBy: c.connectionIsClient ? pub.name : AppState.pubOrganization.name.value,
          issuedTo: c.connectionIsClient ? AppState.pubOrganization.name.value : pub.name,
          featuredData: [
            { label: 'From', value: d(c.createdDate) },
            { label: 'Until', value: 'Credential Revoked' },
            { label: 'Admin Territory', value: c.adminTerritory },
          ],
          credentialJSON: c.credentialJSON,
        })));
        // setWriterCount(20);
        // setWorkCount(100);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading publisher profile` });
    }
  };

  useEffect(() => {
    loadPubProfile().then(() => {
      loadAnimation.add({
        targets: '#overlay',
        backdropFilter: ['blur(0px)', 'blur(10px)'],
        duration: 2000,
      }, 100).add({
        targets: '#container',
        translateY: ['32px', 0],
        opacity: [0, 1],
        duration: 600,
        easing: 'easeOutQuad',
      }, 0);
      loadAnimation.play();
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    });
  }, []);

  const onProfileTabChange = (tab) => {
    setProfileTab(tab.value);
  };

  return (
    <ModalPage id="overlay">
      <Box ref={ref} m="0 auto" maxWidth="72em" minHeight="calc(100vh - 100px)">
        <ModalContainer id="container">
          <ModalHeader>

            <Box display="flex" alignItems="center">
              <Box mr="1.5em">
                <Logo orgImageURL={pub.logoURL} />
              </Box>
              <Box>
                <Text fontSize="1.5rem" lineHeight="1" fontWeight="600" color="var(--text-primary)">
                  {pub.name}
                </Text>
                <Text mt="0.25rem" fontSize="0.75rem" color="var(--text-secondary)">
                  Connected Since:
                  {' '}
                  {d(pub.createdDate)}
                </Text>
              </Box>
            </Box>

            <Box id="actions" display="flex" justifyContent="flex-end">
              <StyledCredentialBadge activeCredential={pub.isActive} onClick={() => setProfileTab('credentials')}>
                {pub.isActive ? 'Active Credential' : 'Inactive'}
              </StyledCredentialBadge>
              <StyledCloseButton onClick={closeFunction}>
                <Icon cursor size="0.75em"><CloseIcon /></Icon>
              </StyledCloseButton>
            </Box>
          </ModalHeader>

          <ProfileTabsSection>
            <Tabs
              tabs={[
                { name: 'Stats', value: 'stats' },
                { name: 'Credentials', value: 'credentials' },
              ]}
              handleTabChange={onProfileTabChange}
              selectedTab={profileTab}
            />
            {{
              stats: <ProfileOverlayStats stats={{ totalWorks: workCount, totalWriters: writerCount }} isLoading={isLoading} />,
              credentials:
  <ProfileOverlayCredentials
    credentials={credentials}
    isLoading={isLoading}
    setCredToRevoke={setCredToRevoke}
  />,
            }[profileTab]}
          </ProfileTabsSection>

        </ModalContainer>

      </Box>

    </ModalPage>
  );
}

PublisherProfileOverlay.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  pub: PropTypes.object.isRequired,
  setCredToRevoke: PropTypes.func.isRequired,
};

export default PublisherProfileOverlay;
