import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Icon from '@ui-kit/icon';
import NoteIcon from '@assets/icons/single-note-square.svg';

import { StyledEmptyListWrapper } from './CreditSelectorEmptyStateStyles';

function CreditSelectorEmptyState({
  text,
  ...restProps
}) {
  useEffect(() => {
    anime({
      targets: '#presentation',
      scale: [0.9, 1],
      opacity: [0, 1],
      duration: 600,
      translateY: [8, 0],
      easing: 'spring(1, 80, 10, 0)',
    });
  }, []);

  return (
    <StyledEmptyListWrapper {...restProps}>
      <Box className="anime-opacity-zero" id="presentation" display="flex" flexDirection="column" alignItems="center">
        <Icon color="var(--empty-state-primary)" mb="0.5rem" size="2.25rem"><NoteIcon /></Icon>
        <Text fontSize="0.75rem" variant="selectDisabled" color="var(--empty-state-primary)">{text}</Text>
      </Box>
    </StyledEmptyListWrapper>
  );
}

CreditSelectorEmptyState.propTypes = {
  text: PropTypes.string,
};

CreditSelectorEmptyState.defaultProps = {
  text: 'Nothing to See Here',
};

export default CreditSelectorEmptyState;
