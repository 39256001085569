import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import ArtistAddExistingParticipant from '@components/shared-recording-components/artistAddExistingParticipant/artistAddExistingParticipant';
import ArtistAddNewRecordingParticipant from '@components/shared-recording-components/artistAddNewRecordingParticipant';

function AddArtistOverlay({
  activeUser,
  recordingData,
  closeFunction,
  handleArtistAdded,
}) {
  const [isNewArtist, setIsNewArtist] = useState(false);

  return (
    <BaseEditOverlay width="auto" formWidth="32rem" closeFunction={closeFunction}>
      <ConversationPrompt
        primaryMessage={recordingData.recordingTitle || 'Sound Recording'}
        promptLabel="Add Artist to"
        mb="1em"
      />
      <Box display="flex" mb="1.75em" width="100%">
        <ButtonSegment onClick={() => setIsNewArtist(false)} btnText="Project Artists" variant="first" active={!isNewArtist} fluid maxHeight="inherit" />
        <ButtonSegment onClick={() => setIsNewArtist(true)} btnText="Invite New" variant="last" active={isNewArtist} fluid maxHeight="inherit" />
      </Box>

      <Box>
        {!isNewArtist
          ? (
            <ArtistAddExistingParticipant activeUser={activeUser} recordingData={recordingData} handleArtistAdded={handleArtistAdded} />
          ) : (
            <ArtistAddNewRecordingParticipant activeUser={activeUser} recordingData={recordingData} handleArtistAdded={handleArtistAdded} />
          )}
      </Box>
    </BaseEditOverlay>
  );
}

AddArtistOverlay.propTypes = {
  activeUser: PropTypes.object.isRequired,
  recordingData: PropTypes.object.isRequired,
  closeFunction: PropTypes.func.isRequired,
  handleArtistAdded: PropTypes.func.isRequired,
};

export default AddArtistOverlay;
