const baseUrl = '/api';
const sidecarUrl = '/sidecar';

// public resources
export const accountProfilePictureUrl = `${baseUrl}/account/profile/picture`;
export const orgLogoUrl = `${baseUrl}/org/logo`;
export const workspaceContractPublicUrl = `${baseUrl}/workspace/public/contract`;
export const workspaceRecordingPublicUrl = `${baseUrl}/workspace/recording`;

// public auth
export const forgotPasswordUrl = `${baseUrl}/forgot`;
export const loginUrl = `${baseUrl}/login`;
export const refreshUrl = `${baseUrl}/refresh`;
export const userRegisterUrl = `${baseUrl}/register`;
export const signupUrl = `${baseUrl}/signup`;

// public invite
export const artistRepInviteTokenUrl = `${baseUrl}/artist/reps/invite/token`;
export const orgInviteTokenUrl = `${baseUrl}/org/accounts/invite/token`;
export const orgArtistInviteTokenUrl = `${baseUrl}/org/artists/invite/token`;
export const workspaceAccountInviteTokenUrl = `${baseUrl}/workspace/invite/token`;

// public identity viewer
export const artistIdentityUrl = `${baseUrl}/identity/artist`;
export const compositionIdentityUrl = `${baseUrl}/identity/composition`;
export const publisherIdentityUrl = `${baseUrl}/identity/publisher`;

// restricted account
export const accountUrl = `${baseUrl}/account`;
export const accountActivitiesUrl = `${baseUrl}/account/activities`;
export const accountUserAliasUrl = `${baseUrl}/account/aliases`;
export const accountCompositionsUrl = `${baseUrl}/account/compositions`;
export const accountContractsUrl = `${baseUrl}/account/contracts`;
export const accountLookupUrl = `${baseUrl}/account/lookup`;
export const accountNotificationsUrl = `${baseUrl}/account/notifications`;
export const accountStripePortalUrl = `${baseUrl}/account/portal`;
export const accountArtistProfileUrl = `${baseUrl}/account/profile/artist`;
export const accountProfilePictureUploadUrl = `${baseUrl}/account/profile/picture/upload`;
export const accountUserProfileUrl = `${baseUrl}/account/profile/user`;
export const accountPublisherUrl = `${baseUrl}/account/publishers`;
export const accountPublisherPresetUrl = `${baseUrl}/account/publishers/presets`;
export const accountSocietyUrl = `${baseUrl}/account/societies`;
export const accountIdentityVerificationUrl = `${baseUrl}/account/verification`;

// restricted artist
export const artistCollaboratorsUrl = `${baseUrl}/artist/collaborators`;
export const artistLookupUrl = `${baseUrl}/artist/lookup`;
export const artistRepsUrl = `${baseUrl}/artist/reps`;
export const artistRepsCredentialsUrl = `${baseUrl}/artist/reps/credentials`;
export const artistRepsInviteUrl = `${baseUrl}/artist/reps/invite`;
export const artistRepsInviteAcceptUrl = `${baseUrl}/artist/reps/invite/accept`;
export const artistRepsInviteActivateUrl = `${baseUrl}/artist/reps/invite/activate`;

// restricted rep
export const repArtistsUrl = `${baseUrl}/rep/artists`;
export const repCheckoutUrl = `${baseUrl}/rep/checkout`;
export const repTokensUrl = `${baseUrl}/rep/tokens`;
export const repProxyTokensUrl = `${baseUrl}/rep/tokens/proxy`;

// restricted workspace
export const tagsUrl = `${baseUrl}/tags`;
export const workspaceUrl = `${baseUrl}/workspace`;
export const workspaceAccountUrl = `${baseUrl}/workspace/accounts`;
export const workspaceActivitiesUrl = `${baseUrl}/workspace/activities`;
export const workspaceAttachmentsUrl = `${baseUrl}/workspace/attachments`;
export const compositionIdentityTokenUrl = `${baseUrl}/workspace/code`;
export const workspaceConfirmUrl = `${baseUrl}/workspace/confirm`;
export const workspaceContractUrl = `${baseUrl}/workspace/contracts`;
export const workspaceContractAmendmentUrl = `${baseUrl}/workspace/contracts/amendments`;
export const workspaceContractDownloadUrl = `${baseUrl}/workspace/contracts/download`;
export const workspaceRejectionUrl = `${baseUrl}/workspace/contracts/rejections`;
export const workspaceSamplesUrl = `${baseUrl}/workspace/contracts/samples`;
export const workspaceSignatureUrl = `${baseUrl}/workspace/contracts/signatures`;
export const workspaceSignatureRequestUrl = `${baseUrl}/workspace/contracts/signatures/request`;
export const workspaceAccountInviteUrl = `${baseUrl}/workspace/invite`;
export const workspaceInviteAcceptUrl = `${baseUrl}/workspace/invite/accept`;
export const workspaceLyricsUrl = `${baseUrl}/workspace/lyrics`;
export const workspaceRecordingsUrl = `${baseUrl}/workspace/recordings`;

// restricted org
export const organizationUrl = `${baseUrl}/org`;
export const orgAccountsUrl = `${baseUrl}/org/accounts`;
export const orgInviteUrl = `${baseUrl}/org/accounts/invite`;
export const orgInviteAcceptUrl = `${baseUrl}/org/accounts/invite/accept`;
export const orgArtistsUrl = `${baseUrl}/org/artists`;
export const orgArtistCredentialsUrl = `${baseUrl}/org/artists/credentials`;
export const orgArtistInviteUrl = `${baseUrl}/org/artists/invite`;
export const orgArtistInviteAcceptUrl = `${baseUrl}/org/artists/invite/accept`;
export const orgCompositionsUrl = `${baseUrl}/org/compositions`;
export const orgCompositionsHoldsUrl = `${baseUrl}/org/compositions/holds`;
export const orgCompositionInternalCommentsUrl = `${baseUrl}/org/compositions/internal/comments`;
export const orgCompositionInternalStatusUrl = `${baseUrl}/org/compositions/internal/status`;
export const orgCompositionInternalTagsUrl = `${baseUrl}/org/compositions/internal/tags`;
export const orgCompositionsTagsUrl = `${baseUrl}/org/compositions/tags`;
export const orgConnectionsUrl = `${baseUrl}/org/connections`;
export const orgCustomTagsUrl = `${baseUrl}/org/custom/tags`;
export const orgExportsUrl = `${baseUrl}/org/exports`;
export const orgListsUrl = `${baseUrl}/org/lists`;
export const orgListContentsUrl = `${baseUrl}/org/lists/contents`;
export const orgLogoUploadUrl = `${baseUrl}/org/logo/upload`;
export const orgRegistrationUrl = `${baseUrl}/org/registrations`;

// admin
export const adminCwrUrl = `${baseUrl}/admin/cwr`;
export const adminLoginUrl = `${baseUrl}/admin/login`;
export const adminMetricsUrl = `${baseUrl}/admin/metrics`;
export const adminOrganizationsUrl = `${baseUrl}/admin/org`;
export const adminOrgInviteUrl = `${baseUrl}/admin/org/invite`;
export const adminOrgLogoUploadUrl = `${baseUrl}/admin/org/logo/upload`;
export const adminOrgOnboardUrl = `${baseUrl}/admin/org/onboard`;

// sidecar
export const createWalletPassUrl = `${sidecarUrl}/createPass`;
export const profileImageCheckUrl = `${sidecarUrl}/imgCheck`;

// unimplemented
export const accountCheckoutUrl = `${baseUrl}/account/checkout`;
export const adminMockDataUrl = `${baseUrl}/admin/mock`;
