/* eslint-disable react/forbid-prop-types */
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';

import d from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Avatar from '@ui-kit/avatar';

import { TableSearchEmptyPrompt, TableIllustrationWrapper } from './ExpiredRepsStyles';

function ExpiredReps({ setSelectedRep, isLoading, reps }) {
  const [searchActive, setSearchActive] = useState(false);
  const [expiredReps, setExpiredReps] = useState(reps);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = expiredReps.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      reps.forEach((r) => {
        const name = `${r.firstName} ${r.lastName}`;
        if (name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(r);
        }
      });
      setExpiredReps(results);
      setSearchActive(true);
    } else {
      setExpiredReps(reps);
      setSearchActive(false);
    }
  };

  return (
    isLoading ? (
      <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
        <Spinner size="2em" variant="page" />
      </Box>
    ) : (
      <Box>
        <div ref={pageRef} />
        {reps.length === 0 ? (
          <Card mt="1.5em">
            <BaseEmptyState
              title="No Inactive Representatives"
              message="If a representatives's access expires, you can issue a new credential from this page"
              illustration="personas"
              minHeight="38em"
            />
          </Card>
        ) : (
          <Fragment>
            <TableControls
              itemName="Representative"
              tableData={reps}
              currentResults={currentResults}
              indexOfFirst={indexOfFirst}
              indexOfLast={indexOfLast}
              showSearch
              searchActive={searchActive}
              searchResults={expiredReps}
              handleSearch={onSearchResults}
            />
            <Table mb="1em">
              <TableHeader>
                <TableCell widthPercent="48%" pr="0.75em">Representative Name</TableCell>
                <TableCell>Credential Expired</TableCell>
              </TableHeader>
              {currentResults
                .map((rep) => (
                  <TableRow onClick={() => setSelectedRep(rep)} cursor>
                    <TableCell widthPercent="48%" pr="0.75em">
                      <TableIllustrationWrapper>
                        <Box mr="1em">
                          <Avatar
                            size="3em"
                            imgURL={rep.avatarURL}
                            isVerified={rep.isVerified}
                          />
                        </Box>
                        <Box pt="0.25rem">
                          <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">
                            {`${rep.firstName} ${rep.lastName}`}
                          </Text>
                          <Text mt="0.125em" fontSize="0.688rem" maxWidth="16em" color="var(--text-secondary)" truncate>
                            id:
                            {rep.repId}
                          </Text>
                        </Box>
                      </TableIllustrationWrapper>
                    </TableCell>
                    <TableCell mobileLabel="Expired:">{d(rep.expiredAt)}</TableCell>
                  </TableRow>
                ))}
              {(searchActive && currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Representatives Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
            </Table>
            <TablePagination
              itemsPerPage={itemsPerPage}
              totalItems={expiredReps.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Fragment>
        )}

      </Box>
    )
  );
}

ExpiredReps.propTypes = {
  setSelectedRep: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reps: PropTypes.array.isRequired,
};

export default ExpiredReps;
