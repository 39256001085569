import AppState from '@state';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import PlayIcon from '@assets/icons/recording-play-circle.svg';
import PauseIcon from '@assets/icons/recording-pause-circle.svg';
import StopIcon from '@assets/icons/recording-stop-circle.svg';
import ActivePlayerAnimation from '@animations/general/ActivePlayerAnimation';
import Spinner from '@ui-kit/loaders/Spinner';

import {
  StyledTrackImageWrapper,
  StyledPlayIconWrapper,
  StyledTrackImage,
  StyledTrackImageEmpty,
} from './FileArtworkImageStyles';

function FileArtworkImage({
  artworkURL,
  fileId,
  handleFilePlay,
  toggleAction,
  isLoading,
  isFileAvailable,
  height,
  width,
  ...restProps
}) {
  const isPlaying = AppState.media.isPlaying.value;
  const currentFilePlaying = AppState.media.currentlyPlaying.value;

  return (
    <StyledTrackImageWrapper height={height} width={width} {...restProps}>
      {isLoading
        && (
          <Box width="100%" display="flex" justifyContent="center" style={{ position: 'absolute' }}>
            <Spinner size="1.25em" variant="audioArtwork" />
          </Box>
        )}

      {isFileAvailable && !isLoading && (
      <StyledPlayIconWrapper onClick={handleFilePlay}>
        {/* COME BACK AND ONLY SHOW IF IS PLAYING AND CURRENTLY PLAYING MATCHES REC ID */}
        {isPlaying && (fileId === currentFilePlaying?.id)
          ? (
            <Box>
              <ActivePlayerAnimation />
              {toggleAction === 'pause'
                ? (
                  <Icon size="28px" pt="4px" color="var(--white)" cursor><PauseIcon /></Icon>
                ) : (
                  <Icon size="28px" pt="4px" color="var(--white)" cursor><StopIcon /></Icon>
                )}
            </Box>
          ) : (
            <Icon size="28px" pt="4px" color="var(--white)" cursor><PlayIcon /></Icon>
          )}
      </StyledPlayIconWrapper>
      )}
      {artworkURL !== null
        ? (
          <StyledTrackImage src={artworkURL} alt="project-artwork" />
        )
        : (
          <StyledTrackImageEmpty id="me" />
        )}
    </StyledTrackImageWrapper>
  );
}

FileArtworkImage.propTypes = {
  fileId: PropTypes.number,
  artworkURL: PropTypes.string,
  toggleAction: PropTypes.string,
  handleFilePlay: PropTypes.func,
  isFileAvailable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
};

FileArtworkImage.defaultProps = {
  fileId: null,
  artworkURL: null,
  handleFilePlay: null,
  toggleAction: 'pause',
  isLoading: false,
  height: '48px',
  width: '48px',
};

export default FileArtworkImage;
