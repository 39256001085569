import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';

export const StyledHeader = styled.div`
  border-bottom: 1px solid var(--top-nav-border);
  background: var(--header-background);
  max-width: 1800px;
  margin: 0 auto;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 1.75em 1.5em 1.25em 1.5em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    padding: 1em;
  }

  @media screen and (min-width: ${pointBreaks.xxl}) {
    padding: 1.75em 0.5em 1.25em 0.5em;
  }

`;

export const StyledNameText = styled(Text)`
  font-size: 0.875rem;
  color: var(--text-soft-mute);
  margin-left: 0.625em;
  font-weight: 500;
  font-family: var(--font-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9em;
  letter-spacing: -0.5px;
`;

export const StyledAccountWrapper = styled(Box)`
  cursor: pointer;

  @media screen and (max-width: ${pointBreaks.sm}) {
   display: none;
  }
`;

export const StyledRepWrapper = styled(Box)`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  background: var(--trust);
  padding: 6px 18px 6px 8px;
  border-radius: 50px;

  @media screen and (max-width: ${pointBreaks.sm}) {
   display: none;
  }
`;

export const StyledNameTextRep = styled(Text)`
  font-size: 0.875rem;
  font-weight: 500;
  font-family: var(--font-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 9em;
  letter-spacing: -0.5px;
`;

export const StyledSearchBar = styled.input`
 background: var(--input-secondary-background);
 font-size: 0.875rem;
 min-width: 20em;
 padding: 0.625em 1.125em;
 border-radius: 2em;
 color: var(--text-soft-mute);
 border: 1px solid var(--input-secondary-border);
 min-height: 2em;

 @media screen and (max-width: ${pointBreaks.sm}) {
   font-size: 1rem;
 }

 ::placeholder {
   color: var(--text-placeholder);
 }

 :disabled {
   cursor: not-allowed;
   background-color var(--grey-ghost);
 }

 @media screen and (max-width: ${pointBreaks.sm}) {
   display:none;
 }
`;

export const StyledNotificationWrapper = styled(Box)`
  cursor: pointer;
  margin-right: 2em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    margin-right: 3em;
    margin-top: 4px;
  }
`;

export const StyledRepActionWrapper = styled(Box)`
  cursor: pointer;
  margin-right: 1em;
  background: var(--trust);
  padding: 4px 8px;
  border-radius: 8px;

  @media screen and (max-width: ${pointBreaks.sm}) {
     display: none;
  }
`;

export const NotificationIndicator = styled.div`
   height: 0.5em;
   width: 0.5em;
   background: var(--purple-300);
   border-radius: 50%;
   position: absolute;
  transform: translate(16px, 0px);
`;

export const RepNotificationIndicator = styled.div`
   height: 0.5em;
   width: 0.5em;
   background: var(--green-300);
   border-radius: 50%;
   position: absolute;
  transform: translate(20px, -5px);
`;

export const NotificationTray = styled.div`
  min-height: 22em;
  width: 21em;
  background: var(--card);
  position: absolute;
  transform: translateX(-312px);
  top: 48px;
  border-radius: 0.5em;
  box-shadow: 2px 4px 10px var(--base-shadow);
  border: 1px solid var(--border);

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
 }
`;

export const TasksTray = styled.div`
  min-height: 22em;
  width: 21em;
  background: var(--card);
  position: absolute;
  transform: translateX(-245px);
  top: 48px;
  border-radius: 0.5em;
  box-shadow: 2px 4px 10px var(--base-shadow);
  border: 1px solid var(--border);

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const NotificationHeader = styled.div`
  padding: 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--active-nav);
  border-top-right-radius: 0.25em;
  border-top-left-radius: 0.25em;
`;

export const TasksHeader = styled.div`
  padding: 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--blue-200);
  border-top-right-radius: 0.25em;
  border-top-left-radius: 0.25em;
`;

export const ResultsWrapper = styled.div`
    background: var(--card);
    position: absolute;
    border: 1px solid var(--border);
    box-shadow: 4px 8px 11px var(--base-shadow);
    min-height: 11em;
    max-height: 11em;
    overflow-y: scroll;
    margin-bottom: 1.5em;
    border-radius: 0.5em;
    transform: translate(0px,4px);
    width: 280px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        min-height: 14em;
        max-height: 14em;
    }
`;

export const ResultsItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 0.8px solid var(--panel-divider);
    padding: 0.75em 0.75em 0.75em 1em;

    p {
        color: var(--text-soft-mute);
        font-size: 0.875rem;
        margin: 0em;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.empty {
          color: var(--text-muted);
        }
    }
`;
