import { useEffect, useState } from 'preact/hooks';
import AppState from '@state/AppState';
// import { route } from 'preact-router';

import loadAccountProfile from '@actions/loadAccountProfile';

import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import CreditsSelector from '@distinct-components/credits/creditsSelector';

const MOCK_ID = '1234';
const MOCK_REC_NAME = 'Mock Recording Name';

// TODO: get the workspaceID from URL and pull workspace info to pass into the credits overlay.  This solves problem we have in composition where user can refresh after creating.

function ArtistAddInitialRecordingCredits() {
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    document.title = 'Add Recording';
    if (!AppState.profileLoaded.value) {
      loadAccountProfile().then(() => {
        setIsLoadingProfile(false);
      });
    } else {
      setIsLoadingProfile(false);
    }
  }, []);
  return (
    <FullScreenModal isLoading={isLoadingProfile} formWidth="36em" closeFunction={() => alert('Not wired up, will proceed to rec workspace')}>

      <ConversationPrompt
        primaryMessage={MOCK_REC_NAME}
        promptLabel="Add Credits"
        labelColor="var(--purple-300)"
        mb="0.25rem"
        mt="1rem"
      />
      <ConversationExplanation mb="1rem" explanation="Add your credits. You can also add or update them later" truncateAtCharacter={500} />

      <CreditsSelector workspaceId={MOCK_ID} />

    </FullScreenModal>
  );
}

export default ArtistAddInitialRecordingCredits;
