/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState, useRef } from 'preact/hooks';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';

import {
  DragAndDropWrapper,
  DragAndDropContainer,
  StyledDropInput,
  StyledDropLabel,
  DragMonitor,
} from './DragAndDropStyles';

function DragAndDrop({
  handleFilesAdded,
  background,
  validationText,
  disabled,
  id,
  ...restProps
}) {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const onDrag = (e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const onDrop = (e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFilesAdded(e.dataTransfer.files);
    }
  };

  const onFileSelected = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFilesAdded(e.target.files);
    }
  };

  return (
    <DragAndDropWrapper {...restProps}>
      <DragAndDropContainer>
        <form id="form-file-upload" onDragEnter={onDrag} onSubmit={(e) => e.preventDefault()}>
          <StyledDropInput disabled={disabled} ref={inputRef} type="file" id={id} multiple onChange={onFileSelected} />
          <StyledDropLabel id="label-file-upload" htmlFor={id} background={background} className={dragActive ? 'drag-active' : ''}>
            <Box>
              <Text color="var(--text-secondary)">Drag and Drop or Select Files</Text>
            </Box>
          </StyledDropLabel>
          { dragActive && <DragMonitor id="drag-file-element" onDragEnter={onDrag} onDragLeave={onDrag} onDragOver={onDrag} onDrop={onDrop} /> }
        </form>
        {validationText
        && (
        <Text
          fontSize="0.75em"
          color="var(--text-medium-mute)"
          mt="0.25em"
        >
          {validationText}
        </Text>
        )}
      </DragAndDropContainer>
    </DragAndDropWrapper>
  );
}

DragAndDrop.propTypes = {
  handleFilesAdded: PropTypes.func.isRequired,
  background: PropTypes.string,
  validationText: PropTypes.string,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

DragAndDrop.defaultProps = {
  validationText: null,
  disabled: false,
  background: 'var(--panel)',
};

export default DragAndDrop;
