import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import { route } from 'preact-router';

import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import Header from '@ui-kit/typography/header';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import Text from '@ui-kit/typography/text';
import DragAndDrop from '@distinct-components/files/dragAndDrop';
import DroppedFileDisplay from '@distinct-components/files/droppedFileDisplay';
import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import Tooltip from '@distinct-components/feedback/tooltip';

function AddRecordingForm({
  projectId,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recordingName, setRecordingName] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isNewProject, setIsNewProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState(null);
  const [newProjectArtist, setNewProjectArtist] = useState(null);
  const [artworkToAdd, setArtworkToAdd] = useState(null);
  const [fileToAdd, setFileToAdd] = useState(null);
  const [isFinalFile, setIsFinalFile] = useState(false);
  const [fileError, setFileError] = useState(null);

  useEffect(() => {
    if (projectId) {
      console.log('extend component to set project Id and not show project elements');
    }
  }, []);

  const onClickAddRecording = async () => {
    setError(null);
    console.log('if a file was added the file to add is', fileToAdd);

    if (isNewProject) {
      console.log('CREATE NEW PROJECT DATA FOR', newProjectName);
    } else {
      console.log('ADD THIS RECORDING TO PROJECT ID ', selectedProjectId);
    }

    if (fileToAdd) {
      console.log('DO THINGS WITH THE ADDED FILE');
      console.log('IS THE AUDIO FILE THE FINAL MASTER ', isFinalFile);
    }

    const cleanedData = {
      // ADD THE DATA FROM ABOVE
      recordingName: recordingName.trim(),
      workspaceType: 'recording',
    };

    if (!cleanedData.recordingName) {
      setError('Invalid Recording Name');
    } else {
      setIsLoading(true);
      try {
        // save and route
        route('/recordings/add-credits'); // will need ID in the path
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const onClickRemoveRecording = () => {
    setFileToAdd(null);
    setIsFinalFile(false);
  };

  const handleRecordingFileAdded = (files) => {
    setFileError(null);
    if (files.length > 1) {
      setFileError('Add Recordings One at a Time');
    } else {
      const file = files[0];
      const fileName = file.name;
      const re = /(\.wav|\.mp3|\.m4a)$/;

      if (!re.exec(fileName.toLowerCase())) {
        setFileError('Supported Audio: .mp3 .m4a .wav');
      } else if (!file.type.startsWith('audio')) {
        setFileError('File type not supported');
      } else if (file.size >= 20000000) {
        setFileError('Recording must be less than 20 MB');
      } else {
        setFileToAdd(file);
      }
    }
  };

  const handleArtworkFileAdded = (files) => {
    setFileError(null);
    if (files.length > 1) {
      setFileError('You can only add one file');
    } else {
      const file = files[0];
      const fileName = file.name;
      const re = /(\.png|\.jpg|\.jpeg)$/;

      if (!re.exec(fileName.toLowerCase())) {
        setFileError('Supported Images: .png .jpg .jpeg');
      } else if (!file.type.startsWith('image')) {
        setFileError('File type not supported');
      } else if (file.size >= 20000000) {
        setFileError('File must be less than 20 MB');
      } else {
        setArtworkToAdd(file);
      }
    }
    const file = files[0];
    setTimeout(() => {
      setArtworkToAdd(file);
    }, 1000);
  };

  const onClickRemoveArtwork = () => {
    setArtworkToAdd(null);
  };

  const isFormValid = () => (
    (isNewProject && !newProjectName)
      || (!recordingName || (recordingName && recordingName.length === 0))
      || (!isNewProject && !selectedProjectId)
  );

  return (
    <Fragment>
      <Header mb="1em">Add Recording</Header>
      <InputLabel label="Recording Name" />
      <BaseInput
        id="recordingName"
        name="recordingName"
        placeholder="Name of the recording"
        type="text"
        fluid
        mb="1.5em"
        value={recordingName}
        onChange={(e) => setRecordingName(e.target.value)}
        autoComplete="off"
      />

      {!isNewProject && (
      <Fragment>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InputLabel label="Add to Project" />
          <LinkButton
            tabIndex="-1"
            type="button"
            btnText="+ New Project"
            color="var(--text-primary)"
            onClick={() => setIsNewProject(!isNewProject)}
            fontSize="0.75rem"
            mb="0.75rem"
          />
        </Box>
        <BaseSelect
          id="projectId"
          name="projectId"
          fluid
          value={selectedProjectId}
          onChange={(e) => setSelectedProjectId(e.target.value)}
        >
          <option value="" default selected>Select Project</option>
          <option value={1}>Project One</option>
          <option value={2}>Project Two</option>
          {/* {AppState.recordingProjects.value.map((p) => (
            <option value={p.id}>{p.name}</option>
          ))} */}
        </BaseSelect>
      </Fragment>
      )}

      {isNewProject && (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InputLabel label="New Project Name" />
          <LinkButton
            tabIndex="-1"
            type="button"
            btnText="Existing Projects"
            color="var(--text-primary)"
            onClick={() => setIsNewProject(!isNewProject)}
            fontSize="0.75rem"
            mb="0.75rem"
          />
        </Box>
        <BaseInput
          id="newProjectName"
          name="newProjectName"
          placeholder="Name of Release, Album etc.."
          type="text"
          fluid
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
          autoComplete="off"
          mb="1.5rem"
        />

        <InputLabel label="Project Artist" />
        <BaseInput
          id="projectArtist"
          name="projectArtist"
          placeholder="Performer or Band..."
          type="text"
          fluid
          mb="1.5em"
          value={newProjectArtist}
          onChange={(e) => setNewProjectArtist(e.target.value)}
          autoComplete="off"
        />

        <InputLabel label="Project Artwork (Optional)" />
        {artworkToAdd
          ? (
            <DroppedFileDisplay
              fileTypeLabel="Artwork File"
              feedbackTitle={artworkToAdd.name}
              feedbackImgURL={URL.createObjectURL(artworkToAdd)}
              handleRemoveFile={onClickRemoveArtwork}
            />
          )
          : (
            <DragAndDrop
              background="var(--input-bg)"
              handleFilesAdded={handleArtworkFileAdded}
              id="artwork-drop"
              mb="2.5rem"
            />
          )}
      </Box>

      )}

      <BreakLine mt="1.75rem" mb="1.75rem" color="var(--panel-info-border)" />

      <InputLabel label="Add First File .wav .aiff .mp3 (Optional)" />
      {!fileToAdd
        && (
          <Fragment>
            <DragAndDrop
              background="var(--input-bg)"
              handleFilesAdded={handleRecordingFileAdded}
              id="sound-recording-drop"
              mb="0.75rem"
            />
            <InputLabel mb="2.25em" color="var(--text-secondary)" label="You can start capturing data without a file" />
          </Fragment>
        )}

      {fileToAdd
        && (
          <Fragment>
            <DroppedFileDisplay
              fileTypeLabel="Audio File"
              feedbackTitle={fileToAdd.name}
              feedbackIcon="audio-file"
              handleRemoveFile={onClickRemoveRecording}
            />
            <Box display="flex" mb="2em" justifyContent="flex-end">
              <Box display="flex" alignItems="center">
                <Text fontSize="0.75rem" pt="2px" color="var(--text-primary)" mr="0.5rem">Final Mastered File</Text>
                <Tooltip prompt="Marking your file as Final Master unlocks additional features and processing for anti-fraud protection" />
              </Box>
              <ToggleSwitch id="isFinalFile" ml="0.75rem">
                <input
                  type="checkbox"
                  name="isFinalFile"
                  id="isFinalFile"
                  checked={isFinalFile}
                  onChange={() => setIsFinalFile(!isFinalFile)}
                />
              </ToggleSwitch>
            </Box>
          </Fragment>
        )}

      {fileError
            && (
            <AlertMessage
              variant="negative"
              message={fileError}
              mb="1.5em"
              mt="1.5em"
              dismissAlert={() => setFileError(null)}
            />
            )}

      {error
            && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}

      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Next"
        disabled={isFormValid()}
        onClick={onClickAddRecording}
        fluid
        isLoading={isLoading}
      />

    </Fragment>
  );
}

AddRecordingForm.propTypes = {
  projectId: PropTypes.string,
};

AddRecordingForm.defaultProps = {
  projectId: null,
};

export default AddRecordingForm;
