import styled from 'styled-components';
import { space, typography } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const StyledPageCard = styled.div`
  height: calc(100vh - 7em);  
  width: 100%;
  /* background: var(--card); */
  /* border: 0.8px solid var(--card-border); */
  box-shadow: 2px 4px 11px var(--card-shadow);
  /* border-radius: 1em; */
  overflow: hidden;

  @media screen and (max-width: ${pointBreaks.sm}) {
    height: inherit;
    background: var(--background);
    border: none;
  }
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  padding: 0.875rem 0rem 1.5rem 0rem;
  /* margin-left: 2em; */

  button {
    min-height: 2em;
    padding-left: 1.25em;
    padding-right: 1.25em;
    max-height: 32px;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    margin-left: 1em;
    padding: 1em 1.5em 1em 0em;
  }
`;

export const StyledTitleWrapper = styled.div`
  display: block;
`;

export const StyledActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`;

export const PageCardHeader = styled.p`
  font-size: 1.65rem;
  color: var(--text-primary);
  font-family: var(--font-secondary);
  font-weight: 800;
  margin: 0em;
  ${space};
  ${typography};

  @media screen and (max-width: ${pointBreaks.sm}) {
    font-size: 1.25rem;
  }
`;

export const StyledContentArea = styled.div(({
  isEditingChild,
}) => `
  height: 100%;
  overflow: auto;
  padding-bottom: 2em;

  @media screen and (max-width: ${pointBreaks.sm}) {
    height: ${isEditingChild ? '500px' : 'auto'};
    overflow: ${isEditingChild ? 'hidden' : 'auto'};
    padding: 0em 1em 4em 1em;
  }
`);

export const StyledPageTab = styled.button(({
  isSelected,
}) => `
  background: ${isSelected ? 'var(--active-nav)' : 'var(--inactive)'};
  min-width: 9em;
  color: ${isSelected ? 'var(--white)' : 'var(--text-soft-mute)'};
  font-size: 0.813rem;
  font-weight: ${isSelected ? '800' : '600'};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5em;
  margin-right: 1em;
  touch-action: manipulation;
  ${space}

  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
   min-width: 0em;
   padding: 1em;
  }

  `, space);

export const StyledPageCardId = styled(Text)`
    font-size: 0.813rem;
    color: var(--text-medium-mute);
    margin-top: 0.5rem;

  @media screen and (max-width: ${pointBreaks.sm}) {
    font-size: 0.725rem;
  }
`;
