/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import BaseButton from '@ui-kit/buttons/baseButton';

export const StyledHoldBtn = styled(BaseButton)`
    background: var(--inactive);
    font-weight: 600;
    text-align: center;
    color: var(--text-secondary);
    border: none;
    width: 100%;
    height: 2.5em;
    font-size: 0.875rem;

    &:focus {
      border: none;
    }
`;

export const StyledHoldDetail = styled.div`
    background: var(--inactive);
    color: var(--text-secondary);
    border: none;
    border-radius: 0.5rem;
    width: 100%;
`;

export const StyledHoldDetailHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.75rem 0.875rem 0rem 0.875rem;
`;
