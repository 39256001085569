/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import FileIcon from '@assets/icons/file-default.svg';
import WavFileIcon from '@assets/icons/file-wav-square.svg';
import RemoveIcon from '@assets/icons/close-solo.svg';

import {
  FileAddedWrapper,
  StyledFeedbackImageWrapper,
  StyledFeedbackIconWrapper,
  RemoveFileWrapper,
  StyledFeedbackImage,
} from './DroppedFileDisplayStyles';

function DroppedFileDisplay({
  background,
  feedbackIcon,
  feedbackImgURL,
  feedbackTitle,
  fileTypeLabel,
  handleRemoveFile,
  ...restProps
}) {
  useEffect(() => {
    anime({
      targets: feedbackImgURL ? '#image' : '#icon',
      scale: [0.5, 1],
      opacity: [0, 1],
      duration: 800,
      easing: 'spring(1, 80, 10, 0)',
      loop: false,
    });
    anime({
      targets: feedbackImgURL ? '#image-title' : '#icon-title',
      opacity: [0, 1],
      translateY: ['3px', '0px'],
      duration: 800,
      easing: 'easeOutQuad',
      loop: false,
      delay: 800,
    });
  }, []);

  return (
    <FileAddedWrapper background={background} {...restProps}>
      <RemoveFileWrapper onClick={handleRemoveFile}><Icon size="0.55rem" color="var(--text-secondary)" cursor><RemoveIcon /></Icon></RemoveFileWrapper>
      {feedbackImgURL
        ? (
          <StyledFeedbackImageWrapper id="image">
            <StyledFeedbackImage src={feedbackImgURL} alt="added-image" />
          </StyledFeedbackImageWrapper>
        ) : (
          <StyledFeedbackIconWrapper id="icon">
            {feedbackIcon === null && <Icon size="2em" pt="3px" color="var(--action-purple)"><FileIcon /></Icon>}
            {feedbackIcon === 'audio-file' && <Icon size="2em" pt="3px" color="var(--action-purple)"><WavFileIcon /></Icon>}
          </StyledFeedbackIconWrapper>
        )}
      <Box id={feedbackImgURL ? 'image-title' : 'icon-title'}>
        <Text fontSize="0.75rem" color="var(--text-primary)" fontWeight="500">{fileTypeLabel}</Text>
        <Text fontSize="0.75rem" color="var(--text-secondary)" maxWidth="280px" truncate>{feedbackTitle}</Text>
      </Box>
    </FileAddedWrapper>
  );
}

DroppedFileDisplay.propTypes = {
  feedbackIcon: PropTypes.string,
  feedbackImgURL: PropTypes.string,
  fileTypeLabel: PropTypes.string.isRequired,
  background: PropTypes.string,
  feedbackTitle: PropTypes.string.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
};

DroppedFileDisplay.defaultProps = {
  feedbackIcon: null,
  feedbackImgURL: null,
  background: 'var(--input-bg)',
};

export default DroppedFileDisplay;
