import PropTypes from 'prop-types';
import Text from '@ui-kit/typography/text';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function SecondaryText({
  children,
  size,
  weight,
  color,
  lineHeight,
  ...restProps
}) {
  return (
    <Text
      fontSize={size}
      fontWeight={weight}
      lineHeight={lineHeight}
      color={color}
      {...restProps}
    >
      {children}
    </Text>
  );
}

SecondaryText.propTypes = {
  children: childrenPropTypes.isRequired,
  size: PropTypes.string,
  lineHeight: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
};

SecondaryText.defaultProps = {
  size: '0.813rem',
  lineHeight: '1.6',
  weight: '400',
  color: 'var(--text-secondary)',
};

export default SecondaryText;
