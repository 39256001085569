import AppState from '@state';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import { useState, useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import loadOrganization from '@actions/loadOrganization';
import loadOrgActiveWriters from '@actions/loadOrgActiveWriters';
import { getOrgCompositions } from '@api/restricted/org-compositions-api';
import { getOrgCompositionsTags } from '@api/restricted/org-compositions-tags-api';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import GoToIcon from '@assets/icons/goto-square.svg';
import EmptyIcon from '@assets/icons/science.svg';
import CompositionIcon from '@assets/illustrations/composition-chip.svg';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import {
  WelcomeWrapper,
  StyledWelcomeHeader,
  StyledWelcomeMessage,
  HomeCardGrid,
  StyledHomeCard,
  HomeCardHeader,
  StyledCardLabel,
  HomeCardContent,
  HomeCardItem,
  StyledStatCard,
  StatCardGrid,
} from './PublisherDashboardStyles';

import DashboardGenreChart from './sections/dashboardGenreChart';
import DashboardAttributeChart from './sections/dashboardAttributeChart';

const d = useIsoDateFormat;

function PublisherDashboard({ uuid }) {
  const [isLoading, setIsLoading] = useState(true);
  const [recentCompositions, setRecentComposition] = useState([]);
  const [totalFinalized, setTotalFinalized] = useState(0);
  const [totalInProgress, setTotalInProgress] = useState(0);
  const [genres, setGenres] = useState([]);
  const [attributes, setAttributes] = useState([]);

  const loadAnimation = anime.timeline({
    autoplay: false,
    delay: 400,
  });

  const loadCompositions = async () => {
    try {
      const response = await getOrgCompositions(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading compositions`;
          route('/error/exception', true);
        } catch {
          AppState.exceptions.errorPageError.value = `${response.statusText} when loading compositions`;
          route('/error/exception', true);
        }
      } else {
        const json = await response.json();
        setTotalFinalized(json.totalFinalized);
        setTotalInProgress(json.totalNotFinalized);
        const formattedComps = json.compositions.map((c) => ({
          id: c.id,
          uuid: c.uuid,
          compositionName: c.name,
          writtenDate: d(c.written),
          createdAt: d(c.created),
          updatedAt: d(c.updated),
          controlledWriters: c.controlledWriters,
          writerCount: c.numWriters,
          uncontrolledWriterCount: c.numWriters - c.controlledWriters.length,
          finalized: c.finalized,
          status: c.status,
        }));
        setRecentComposition(formattedComps);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading compositions`;
      route('/error/exception', true);
    }
  };

  const loadCompositionsTags = async () => {
    try {
      const response = await getOrgCompositionsTags(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading compositions tags`;
          route('/error/exception', true);
        } catch {
          AppState.exceptions.errorPageError.value = `${response.statusText} when loading compositions tags`;
          route('/error/exception', true);
        }
      } else {
        const json = await response.json();
        setGenres(json.genres);
        setAttributes(json.attributes);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading compositions tags`;
      route('/error/exception', true);
    }
  };

  useEffect(() => {
    document.title = 'Dashboard';

    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadCompositions().then(() => {
        loadOrgActiveWriters(AppState.pubOrganization.id.value).then(() => {
          loadCompositionsTags().then(() => {
            setIsLoading(false);
            setTimeout(() => {
              loadAnimation.add({
                targets: '#grid .grid-section',
                translateY: ['16px', 0],
                opacity: [0, 1],
                duration: 600,
                direction: 'normal',
                delay: anime.stagger(100),
              }, 200);
              loadAnimation.play();
            }, 500);
          });
        });
      });
    });
  }, []);

  const onClickComposition = (compUuid) => {
    AppState.navigation.backActionPath.value = `/publisher/${uuid}/dashboard`;
    route(`/publisher/${AppState.pubOrganization.uuid.value}/compositions/${compUuid}`);
  };

  const onClickCompositions = () => {
    route(`/publisher/${AppState.pubOrganization.uuid.value}/compositions`);
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title=""
        showHeader={false}
        isLoadingPage={isLoading}
      >
        <WelcomeWrapper>
          <StyledWelcomeHeader>
            {AppState.pubOrganization.name.value}
          </StyledWelcomeHeader>
          <StyledWelcomeMessage>
            Here&apos;s what&apos;s happening with your writers
          </StyledWelcomeMessage>
        </WelcomeWrapper>

        <HomeCardGrid id="grid">

          <Box className="primary grid-section">

            <StatCardGrid>
              <StyledStatCard minHeight="14em">
                <Box p="1em">
                  <Text fontWeight="600">Finalized Works</Text>
                  <Text fontSize="1.8rem" fontWeight="200">{totalFinalized.toLocaleString()}</Text>
                </Box>
              </StyledStatCard>
              <StyledStatCard minHeight="14em">
                <Box p="1em">
                  <Text fontWeight="600">In Creation</Text>
                  <Text fontSize="1.8rem" fontWeight="200">{totalInProgress.toLocaleString()}</Text>
                </Box>
              </StyledStatCard>
              <StyledStatCard minHeight="14em">
                <Box p="1em">
                  <Text fontWeight="600">Active Writers</Text>
                  <Text fontSize="1.8rem" fontWeight="200">{AppState.publisherActiveSongwriters.value.length.toLocaleString()}</Text>
                </Box>
              </StyledStatCard>
            </StatCardGrid>

            <StyledHomeCard minHeight="14em">
              <HomeCardHeader>
                <StyledCardLabel>
                  Recent Compositions
                </StyledCardLabel>
                <Box display="flex">
                  <Icon size="1.125em" pt="3px" cursor onClick={onClickCompositions}><GoToIcon /></Icon>
                </Box>
              </HomeCardHeader>
              <HomeCardContent>
                {recentCompositions.length && recentCompositions.slice(0, 9).map((composition) => (
                  <HomeCardItem onClick={() => { onClickComposition(composition.uuid); }}>
                    <Icon size="2.25em" pt="3px" mr="1em"><CompositionIcon /></Icon>
                    <Box pt="0.25rem">
                      <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">{composition.compositionName}</Text>
                      <Box display="flex" alignItems="center">
                        <Text mt="0.125em" fontSize="0.688rem;" mr="1em" color="var(--text-secondary)">
                          Writers:
                          {' '}
                          {composition.writerCount}
                        </Text>
                        <Text mt="0.125em" fontSize="0.688rem;" color="var(--text-secondary)">
                          Updated:
                          {' '}
                          {useIsoDateFormat(composition.updatedAt)}
                        </Text>
                      </Box>
                    </Box>
                  </HomeCardItem>
                ))}
                {recentCompositions.length === 0 && (
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" minHeight="8.75em">
                  <Icon size="2em" pt="3px" color="var(--text-muted)" mb="0.25em"><EmptyIcon /></Icon>
                  <Text color="var(--text-muted)" fontSize="0.75em">No Compositions Created</Text>
                </Box>
                )}
              </HomeCardContent>
            </StyledHomeCard>

          </Box>

          <Box className="secondary grid-section">

            <StyledHomeCard>
              <DashboardGenreChart genres={genres} />
            </StyledHomeCard>

            <StyledHomeCard>
              <DashboardAttributeChart attributes={attributes} />
            </StyledHomeCard>

          </Box>
        </HomeCardGrid>
      </PageCard>
    </CorePublisherLayout>
  );
}

PublisherDashboard.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherDashboard;
