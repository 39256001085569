import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import anime from 'animejs/lib/anime.es';

import Text from '@ui-kit/typography/text';
import Span from '@ui-kit/typography/span';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import ExceptionIcon from '@assets/icons/exception-circle.svg';
import ValidIcon from '@assets/icons/valid-circle.svg';
import FileArtworkImage from '@distinct-components/files/fileArtworkImage/FileArtworkImage';

import {
  CardWrapper,
  StyledImageTitleWrapper,
  StyledRecordingTitleWrapper,
  StyleRecordingTitleText,
  StyledTrackNumberWrapper,
  StyledDragBarWrapper,
  StyledDragBar,
  StyledRecordingInfoWrapper,
  StyleRecordingInfoItem,
  StyleRecordingInfoItemLabel,
  StyleRecordingInfoFinalChip,
} from './RecordingListCardStyles';

function RecordingListCard({
  recording,
  index,
  projectArtworkURL,
  dragHandleProps,
  handlePlayRecording,
  ...restProps
}) {
  useEffect(() => {
    const delayValue = index * 100;

    anime({
      targets: `#image-${index}`,
      scale: [1, 1.1, 1],
      duration: 800,
      easing: 'easeOutCubic',
      delay: delayValue,
    });

    anime({
      targets: `#title-${index}`,
      opacity: [0, 1],
      translateY: [8, 0],
      duration: 800,
      easing: 'easeOutCubic',
      delay: 400,
    });
  }, []);

  const onClickCard = () => {
    route('/recording/1');
  };

  return (
    <CardWrapper
      {...restProps}
    >

      <Box display="flex" justifyContent="space-between">

        <Box display="flex" flexGrow="1">

          <StyledTrackNumberWrapper>
            <Text fontSize="0.813rem" fontWeight="600" color="var(--text-secondary)" variant="selectDisabled">{index + 1}</Text>
            <StyledDragBarWrapper {...dragHandleProps}>
              <StyledDragBar />
              <StyledDragBar />
              <StyledDragBar />
            </StyledDragBarWrapper>
          </StyledTrackNumberWrapper>

          <StyledImageTitleWrapper>

            <FileArtworkImage
              id={`image-${index}`}
              fileId={recording.id}
              handleFilePlay={() => handlePlayRecording(recording)}
              artworkURL={projectArtworkURL}
              isFileAvailable={recording.fileVersion > 0}
              toggleAction="stop"
            />

            <StyledRecordingTitleWrapper className="anime-opacity-zero" id={`title-${index}`} onClick={onClickCard}>
              <StyleRecordingTitleText>{recording.recordingTitle}</StyleRecordingTitleText>
              {recording.fileVersion === 0 && <Span fontSize="0.75rem" color="var(--text-secondary)">(No File Added)</Span>}
            </StyledRecordingTitleWrapper>
          </StyledImageTitleWrapper>

        </Box>

        <StyledRecordingInfoWrapper>

          <StyleRecordingInfoItem>
            {recording.stemsAdded && <StyleRecordingInfoItemLabel>Stems Added</StyleRecordingInfoItemLabel>}
          </StyleRecordingInfoItem>

          <StyleRecordingInfoItem>
            {recording.compositionLinked
              ? (
                <Icon size="0.75rem" pt="1px" mr="2px" color="var(--color-green)"><ValidIcon /></Icon>
              ) : (
                <Icon size="0.75rem" pt="1px" mr="2px" color="var(--color-orange)"><ExceptionIcon /></Icon>
              )}
            <StyleRecordingInfoItemLabel>Composition Link</StyleRecordingInfoItemLabel>
          </StyleRecordingInfoItem>

          <StyleRecordingInfoItem>
            <StyleRecordingInfoItemLabel>
              {recording.totalCredits}
              {' '}
              Credits
            </StyleRecordingInfoItemLabel>
          </StyleRecordingInfoItem>

          <StyleRecordingInfoItem>
            {recording.isFinalFile
              ? (
                <StyleRecordingInfoItemLabel>Not Final</StyleRecordingInfoItemLabel>
              ) : (
                <StyleRecordingInfoFinalChip>Finalized</StyleRecordingInfoFinalChip>
              )}
          </StyleRecordingInfoItem>

        </StyledRecordingInfoWrapper>

      </Box>

    </CardWrapper>
  );
}

RecordingListCard.propTypes = {
  recording: PropTypes.object.isRequired,
  handlePlayRecording: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object.isRequired,
  projectArtworkURL: PropTypes.string,
  index: PropTypes.number.isRequired,
};

RecordingListCard.defaultProps = {
  projectArtworkURL: null,
};

export default RecordingListCard;
