import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import anime from 'animejs/lib/anime.es';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import NoteIcon from '@assets/icons/single-note-square.svg';
import RecordIcon from '@assets/icons/recording-disc.svg';
import ExceptionIcon from '@assets/icons/exception-circle.svg';
import LabelIcon from '@assets/icons/label-connection-circle.svg';
import BreakLine from '@ui-kit/dividers/breakLine';

import AvatarCollection from '../../avatarCollection/AvatarCollection';

import {
  CardWrapper,
  StyledImageTitleWrapper,
  StyledProjectImageWrapper,
  StyledProjectImage,
  StyledProjectImageEmpty,
  StyleProjectTitleText,
} from './RecordingProjectCardStyles';

function RecordingProjectCard({
  project,
  handleSelectProject,
  handleShowPerformerSummary,
  ...restProps
}) {
  useEffect(() => {
    anime({
      targets: '#image',
      scale: [1, 1.03, 1],
      duration: 800,
      easing: 'easeOutCubic',
      delay: 200,
    });
    anime({
      targets: '#title',
      opacity: [0, 1],
      translateY: [8, 0],
      duration: 800,
      easing: 'easeOutCubic',
    });
  }, []);

  const onClickCard = () => {
    route('/recordings/project/1');
  };

  return (
    <CardWrapper
      onClick={onClickCard}
      {...restProps}
    >
      <StyledImageTitleWrapper>

        <StyledProjectImageWrapper id="image">
          {project.projectArtworkURL !== null
            ? (
              <StyledProjectImage src={project.projectArtworkURL} alt="project-artwork" />
            )
            : (
              <StyledProjectImageEmpty>
                <Icon size="2rem" color="var(--icon-secondary)" cursor><NoteIcon /></Icon>
              </StyledProjectImageEmpty>
            )}
        </StyledProjectImageWrapper>

        <Box id="title" className="anime-opacity-zero">
          <StyleProjectTitleText>{project.projectName}</StyleProjectTitleText>
          <Text fontSize="0.938rem" color="var(--text-secondary)" fontWeight="400" lineHeight="1">{project.projectDisplayArtist || 'Undefined Artist'}</Text>
        </Box>

      </StyledImageTitleWrapper>

      <Box display="flex" alignItems="center" mb="0.5rem" mt="1rem">
        <Box mr="0.75rem">
          <Icon size="1rem" pt="4px" color="var(--color-purple)" cursor><RecordIcon /></Icon>
        </Box>
        <Text color="var(--text-secondary)" fontSize="0.813rem">0 Recordings / 0 Finalized</Text>
      </Box>

      <Box display="flex" alignItems="center" mb="0.5rem">
        <Box mr="0.75rem">
          <Icon size="1rem" pt="4px" color="var(--color-orange)" cursor><ExceptionIcon /></Icon>
        </Box>
        <Text color="var(--text-secondary)" fontSize="0.813rem">0 Missing Composition Link</Text>
      </Box>

      <Box display="flex" alignItems="center" mb="0.5rem">
        <Box mr="0.75rem">
          <Icon size="1rem" pt="4px" color="var(--color-blue)" cursor><LabelIcon /></Icon>
        </Box>
        <Text color="var(--text-secondary)" fontSize="0.813rem">Label Name</Text>
      </Box>

      <BreakLine mt="0.875rem" mb="1.25rem" color="var(--panel-divider)" />

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Text fontSize="0.75rem" color="var(--text-secondary)" mr="0.75rem">
          {project.participantSummary.length}
          {' '}
          Participants
        </Text>
        <AvatarCollection users={project.participantSummary} />
      </Box>

    </CardWrapper>
  );
}

RecordingProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
  handleSelectProject: PropTypes.func,
  handleShowPerformerSummary: PropTypes.func,
  menuOptions: PropTypes.array,
};

RecordingProjectCard.defaultProps = {
  handleSelectProject: null,
  handleShowPerformerSummary: null,
  menuOptions: null,
};

export default RecordingProjectCard;
