import PropTypes from 'prop-types';

import {
  StyledMenuItem,
} from './MenuItemStyles';

function MenuItem({
  menuText,
  isDestructive,
  ...restProps
}) {
  return (

    <StyledMenuItem
      isDestructive={isDestructive}
      {...restProps}
    >
      {menuText}
    </StyledMenuItem>

  );
}

MenuItem.propTypes = {
  menuText: PropTypes.string.isRequired,
  isDestructive: PropTypes.bool,
};

MenuItem.defaultProps = {
  isDestructive: false,
};

export default MenuItem;
