/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import BaseButton from '@ui-kit/buttons/baseButton';
import DefaultIcon from '@assets/icons/science.svg';
import CompositionIcon from '@assets/icons/composition-note.svg';
import PersonIcon from '@assets/icons/user-basic.svg';
import AlertIcon from '@assets/icons/alert-list.svg';
import ContractIcon from '@assets/icons/contracts-scroll.svg';
import RecordingIcon from '@assets/icons/recordings-square.svg';
import MachineIcon from '@assets/icons/machine-stack.svg';
import LinkButton from '@ui-kit/buttons/linkButton';

import { EmptyStateWrapper, IconWrapper } from './BaseEmptyStateStyles';

function BaseEmptyState({
  title,
  message,
  action,
  secondaryAction,
  actionText,
  secondaryActionText,
  illustration,
  minHeight,
  size,
  showIllustration,
  mutedActions,
  animate,
  ...restProps
}) {
  const emptyAnimation = anime.timeline({
    autoplay: true,
    loop: false,
  });

  useEffect(() => {
    if (animate) {
      emptyAnimation
        .add({
          targets: '#animation',
          opacity: [0, 1],
          duration: 100,
        })
        .add({
          targets: '#main-square',
          scale: [0, 1],
          duration: 1200,
          easing: 'easeOutElastic',
        })
        .add({
          targets: '#inner-circle',
          scale: [0, 1],
          duration: 700,
          elasticity: 900,
        }, 600)
        .add({
          targets: '#outer-ring',
          scale: [0, 1],
          strokeDasharray: [0, 8],
          easing: 'easeOutElastic(1, .8)',
          duration: 600,
        }, 500)
        .add({
          targets: '#icon',
          scale: [0, 1],
          strokeDasharray: [0, 8],
          easing: 'easeOutElastic(1, .8)',
          duration: 600,
        }, 800)
        .add({
          targets: '#info',
          opacity: [0, 1],
          translateY: [16, 0],
          easing: 'easeOutExpo',
          duration: 1000,
        }, 300);
    }
  }, []);

  return (
    <EmptyStateWrapper minHeight={minHeight} size={size} {...restProps} id="animation" style={{ opacity: 0 }}>
      {showIllustration
      && (
      <Box mb="1em" position="relative">
        <IconWrapper id="icon">
          {!illustration && <Icon size="1.5em" color="var(--empty-state-primary)"><DefaultIcon /></Icon>}
          {illustration === 'composition' && <Icon size="1.5em" color="var(--empty-state-primary)"><CompositionIcon /></Icon>}
          {illustration === 'recording' && <Icon size="1.5em" color="var(--empty-state-primary)"><RecordingIcon /></Icon>}
          {illustration === 'personas' && <Icon size="1.5em" color="var(--empty-state-primary)"><PersonIcon /></Icon>}
          {illustration === 'activities' && <Icon size="1.5em" color="var(--empty-state-primary)"><AlertIcon /></Icon>}
          {illustration === 'contracts' && <Icon size="1.5em" color="var(--empty-state-primary)"><ContractIcon /></Icon>}
          {illustration === 'data' && <Icon size="1.5em" color="var(--empty-state-primary)"><MachineIcon /></Icon>}
        </IconWrapper>
        <svg width="99" height="91" viewBox="0 0 99 91" style={{ overflow: 'visible' }}>
          <defs>
            <path id="path-1" d="M9.28 0h76.48a9.28 9.28 0 0 1 9.28 9.28v69.44A9.28 9.28 0 0 1 85.76 88H9.28A9.28 9.28 0 0 1 0 78.72V9.28A9.28 9.28 0 0 1 9.28 0Z" />
            <mask id="mask-2" width="97.04" height="90" x="-1" y="-1" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox">
              <path fill="#fff" d="M-1-1h97.04v90H-1z" />
              <use xlinkHref="#path-1" />
            </mask>
          </defs>
          <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g transform="translate(-662 -373)">
              <g id="Group" transform="translate(663.98 374.5)">
                <g id="main-square" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <path fill="var(--empty-state-primary)" d="M16.3 6.5h62.44a9.28 9.28 0 0 1 9.28 9.28v56.44a9.28 9.28 0 0 1-9.28 9.28H16.3a9.28 9.28 0 0 1-9.28-9.28V15.78A9.28 9.28 0 0 1 16.3 6.5Z" transform="matrix(1 0 0 -1 0 88)" />
                </g>
                <g id="inner-circle" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <circle cx="48.02" cy="44.5" r="29" fill="var(--empty-state-secondary)" />
                </g>
                <g id="outer-ring" style="transform-box: fill-box; transform-origin: 50% 50%">
                  <use xlinkHref="#path-1" stroke="#B6B6E2" strokeDasharray="8" strokeWidth="2" mask="url(#mask-2)" opacity=".549" transform="matrix(1 0 0 -1 0 88)" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Box>
      )}
      <Box id="info" display="flex" flexDirection="column" alignItems="center" style={{ opacity: 0 }}>
        {title && <Text className="empty-title" color="var(--empty-state-secondary)">{title}</Text>}
        {message && <Box maxWidth="22em"><Text className="empty-message" color="var(--empty-state-secondary)">{message}</Text></Box>}
        {action && <BaseButton mt="2em" variant={mutedActions ? 'emptyState' : 'default'} btnText={actionText} onClick={action} />}
        {secondaryAction && (
          <Box display="flex" justifyContent="center">
            <LinkButton btnText={secondaryActionText} color="var(--empty-state-primary)" mt="2em" onClick={secondaryAction} />
          </Box>
        )}
      </Box>
    </EmptyStateWrapper>
  );
}

BaseEmptyState.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.func,
  secondaryAction: PropTypes.func,
  actionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
  illustration: PropTypes.string,
  minHeight: PropTypes.string,
  size: PropTypes.string,
  showIllustration: PropTypes.bool,
  mutedActions: PropTypes.bool,
  animate: PropTypes.bool,
};

BaseEmptyState.defaultProps = {
  title: null,
  message: null,
  action: null,
  secondaryAction: null,
  actionText: null,
  secondaryActionText: null,
  illustration: null,
  minHeight: '24em',
  size: 'default',
  showIllustration: true,
  mutedActions: true,
  animate: true,
};

export default BaseEmptyState;
