import { useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import { Fragment } from 'preact';
import AppState from '@state';

// import useErrorOverlay from '@hooks/useErrorOverlay';
import useWindowDimensions from '@hooks/useWindowDimensions';
import useAuthTokensValidCheck from '@hooks/useAuthTokensValidCheck';

import SoloLogo from '@assets/logos/logo-symbol.svg';
import Spinner from '@ui-kit/loaders/Spinner';
import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';
import Text from '@ui-kit/typography/text';
import UtilityButton from '@ui-kit/buttons/utilityButton';

import { CONTRACT_EXPLANATIONS } from '@constants/supportingCopy';

import postWorkspaceRejection from '@api/restricted/workspace-rejection-api';
import getWorkspaceContract from '@api/restricted/workspace-contract-api';
import { getWorkspaceContractPublic, patchWorkspaceRejectionPublic } from '@api/public/workspace-contract-public-api';

import ContractStatusBar from './sections/contractStatusBar';
import ContractDocument from './sections/contractDocument';
import ContractReject from './sections/contractReject';
import ContractConfirm from './sections/contractConfirm';
import ContractSignedSuccess from './sections/contractSignedSuccess';

import {
  ContractWrapper,
  HeaderWrapper,
  HeaderDetails,
  HeaderActions,
  PageWrapper,
  PageContent,
  RejectionWrapper,
  HeaderContent,
  MobileHeaderContent,
  MobileTitleWrapper,
} from './ContractViewStyles';

function ContractView({ id, version }) {
  const [contractData, setContractData] = useState({});
  const [showRejectContract, setShowRejectContract] = useState(false);
  const [isScrollToSignature, setIsScrollToSignature] = useState(false);
  const [errorRejecting, setErrorRejecting] = useState(null);
  const contractId = parseInt(id, 10);
  const contractVersion = parseInt(version, 10);
  const [isLoadingContract, setIsLoadingContract] = useState(true);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isSignatureComplete, setIsSignatureComplete] = useState(false);
  const [contractsToSign, setContractsToSign] = useState([]);
  const [userHasJoined, setUserHasJoined] = useState(true);
  const [userIsRegistered, setUserIsRegistered] = useState(false);
  const [suggestionsToConfirm, setSuggestionsToConfirm] = useState({});
  const signerRef = useRef(null);

  const { width } = useWindowDimensions();

  const loadContractData = async () => {
    setIsLoadingContract(true);
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const apiCall = async () => {
        if (useAuthTokensValidCheck()) {
          return getWorkspaceContract(contractId, contractVersion);
        }
        return getWorkspaceContractPublic(contractId, contractVersion, queryParams.get('email'), queryParams.get('token'));
      };
      const response = await apiCall();
      if (response.status !== 200) {
        const json = await response.json();
        // Not use error overlay here because it will look broken
        AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading contract`;
        route('/error/exception');
      } else {
        const json = await response.json();
        setUserIsRegistered(json.accountHasRegistered);
        setUserHasJoined(json.accountHasJoined);
        const newContractData = {
          id: contractId,
          version: contractVersion,
          isAmendment: json.isAmendment,
          executedDate: json.executedDate,
          previousExecutedDate: json.previousExecutedDate,
          compositionName: json.name,
          previousCompositionName: json.previousName,
          compositionHasSamples: json.hasSamples,
          rejection: json.rejection,
          status: json.status,
          signingParties: json.writers ? json.writers.map((p) => {
            let signatureText;
            let signatureImg;
            if (p.signatureIsImage) {
              signatureImg = p.signature;
              signatureText = null;
            } else {
              signatureText = p.signature;
              signatureImg = null;
            }

            let pubPreset = null;
            if (p.publisherPreset?.name) {
              pubPreset = {
                id: p.publisherPreset.id,
                name: p.publisherPreset.name,
                isDefault: p.publisherPreset.is_default,
                owners: p.publisherPreset.publishers.filter((pub) => pub.share > 0).map(({ territory, share, ...rest }) => ({ ownedPercentage: share, ...rest })),
                admins: p.publisherPreset.publishers.filter((pub) => pub.territory).map(({ share, ...rest }) => rest),
              };
            }

            return {
              legalName: p.name,
              persona: p.persona,
              share: p.share,
              contribution: p.contribution,
              accountId: p.id,
              email: p.email,
              signed: !!p.signature,
              signatureText,
              signatureImg,
              signatureRep: p.signatureRepresentative,
              signedDate: p.signatureDate,
              ipi: p.ipi,
              isni: p.isni,
              societies: p.societies.join(', '),
              preset: pubPreset,
            };
          }) : [],
          prevSigningParties: json.previousWriters,
        };
        setContractData(newContractData);
        setContractsToSign(json.contractsToSign);
        setSuggestionsToConfirm(json.suggestionsToConfirm);

        setTimeout(() => {
          setIsLoadingContract(false);
        }, 700);
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading contract`;
      route('/error/exception');
    }
  };

  useEffect(async () => {
    document.title = 'Contract';
    document.body.style.backgroundColor = 'var(--background)';

    setIsSignatureComplete(false);

    await loadContractData();
  }, []);

  const toggleRejectContract = () => {
    setShowRejectContract(!showRejectContract);
  };

  const onClickCloseContract = () => {
    if (userIsRegistered) {
      const str = document.referrer;
      if ((str.split('/').pop() === '') && (!AppState.navigation.backActionPath.value)) {
        route('/home');
      } else {
        window.history.back();
      }
    } else {
      route('/login');
    }
  };

  const onClickSignNow = () => {
    if (width < 768) {
      signerRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } else {
      signerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    setIsScrollToSignature(true);
  };

  const handleRejectContract = (type, message) => {
    const queryParams = new URLSearchParams(window.location.search);
    const rejectContractApi = () => {
      if (localStorage.getItem('accessToken')) {
        return postWorkspaceRejection(contractId, type, message);
      }
      return patchWorkspaceRejectionPublic(contractId, queryParams.get('email'), queryParams.get('token'), type, message);
    };

    setIsRejecting(true);
    rejectContractApi()
      .then((response) => {
        setIsRejecting(false);
        if (response.status === 200 || response.status === 202) {
          setIsLoadingContract(true);
          loadContractData().then(() => {
            setErrorRejecting('');
            setShowRejectContract(false);
            setIsLoadingContract(false);
          });
        } else {
          response.json()
            .then(({ error }) => setErrorRejecting(error || response.statusText))
            .catch(() => setErrorRejecting(response.statusText));
        }
      })
      .catch((err) => {
        setIsRejecting(false);
        setErrorRejecting(err.message);
      });
  };

  return (
    <ContractWrapper>
      <HeaderWrapper id="scrollPoint">
        <MobileHeaderContent>
          <Box justifySelf="flexStart" flex="0">
            {userIsRegistered
              ? (
                <UtilityButton iconSize="1rem" icon="arrow-left" onClick={onClickCloseContract} iconTransform="translateY(-1px)" />
              )
              : (
                <Box
                  onClick={onClickCloseContract}
                >
                  <Icon pt="0.188em" cursor><SoloLogo /></Icon>
                </Box>
              )}
          </Box>
          <MobileTitleWrapper>
            <Text fontWeight="700" fontSize="0.938rem" color="var(--text-primary)">
              Contract:
              {' '}
              {contractData.compositionName}
            </Text>
          </MobileTitleWrapper>
        </MobileHeaderContent>
        <HeaderContent>
          <HeaderDetails>
            <Icon onClick={onClickCloseContract} pt="0.188em" cursor><SoloLogo /></Icon>
            <Text fontWeight="700" ml="0.75em" fontSize="1em" color="var(--text-primary)">
              Contract:
              {' '}
              {contractData.compositionName}
            </Text>
          </HeaderDetails>
          <HeaderActions>
            {userIsRegistered
              ? (
                <UtilityButton ml="0.875em" icon="close" onClick={onClickCloseContract} />
              )
              : (
                <Box display="flex">
                  {!isLoadingContract
                  && <Text fontSize="0.875rem" color="var(--text-secondary)" mr="0.5rem">Signing as Guest</Text>}
                </Box>
              )}
          </HeaderActions>
        </HeaderContent>
      </HeaderWrapper>

      {isLoadingContract ? (
        <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
          <Spinner size="2em" variant="page" />
        </Box>
      ) : (
        <Fragment>
          <ContractStatusBar
            contractData={contractData}
            onClickSignNow={onClickSignNow}
            onClickRejectContract={toggleRejectContract}
          />
          <PageWrapper>
            <PageContent>
              {contractData.status === 'rejected'
                && (
                <RejectionWrapper>
                  <Box maxWidth="22em">
                    <ConversationPrompt
                      primaryMessage="A writer rejected this version of the Split Sheet"
                      promptLabel="Contract Rejected"
                      labelColor="var(--red-300)"
                      mb="1em"
                    />
                    <ConversationExplanation mb="2em" explanation={CONTRACT_EXPLANATIONS.contractView.contractRejected} truncateAtCharacter={500} />
                    {contractData.rejection.legalName && (
                      <Fragment>
                        <Text fontWeight="600">Rejected by:</Text>
                        <Text mb="1.5em">{contractData.rejection.legalName}</Text>
                      </Fragment>
                    )}
                    <Text fontWeight="600">Rejection Reason:</Text>
                    <Text>
                      &quot;
                      {contractData.rejection.reason}
                      &quot;
                    </Text>
                  </Box>
                </RejectionWrapper>
                )}
              {contractData.status !== 'rejected'
                && (
                <ContractDocument
                  contractId={contractId}
                  contractData={contractData}
                  isScrollToSignature={isScrollToSignature}
                  loadContractData={loadContractData}
                  setIsSignatureComplete={setIsSignatureComplete}
                  signerRef={signerRef}
                />
                )}
            </PageContent>
          </PageWrapper>
        </Fragment>
      )}

      {showRejectContract
      && (
      <ContractReject
        closeFunction={toggleRejectContract}
        handleRejectContract={handleRejectContract}
        errorRejecting={errorRejecting}
        isLoading={isRejecting}
      />
      )}

      {!userHasJoined && (
        <ContractConfirm
          contractData={contractData}
          suggestionsToConfirm={suggestionsToConfirm}
          setIsLoadingContract={setIsLoadingContract}
          loadContractData={loadContractData}
        />
      )}

      {isSignatureComplete && (
        <ContractSignedSuccess
          contractData={contractData}
          contractsToSign={contractsToSign}
          isGuest={!userIsRegistered}
          setIsSignatureComplete={setIsSignatureComplete}
        />
      )}

    </ContractWrapper>
  );
}

ContractView.propTypes = {
  id: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired,
};

export default ContractView;
