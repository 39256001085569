import AppState from '@state';
import { useEffect, useState } from 'preact/hooks';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import Box from '@ui-kit/box';
import PrimaryText from '@ui-kit/typography/primaryText';
import SecondaryText from '@ui-kit/typography/secondaryText';
import BaseButton from '@ui-kit/buttons/baseButton';
import { route } from 'preact-router';

function ActivateRecordingPreview() {
  const recordingPreviewToken = localStorage.getItem('soundRecordingPreview');
  const [isPreviewActive, setIsPreviewActive] = useState(false);

  useEffect(() => {
    if (recordingPreviewToken) {
      setIsPreviewActive(true);
    }
  }, []);

  const handleToggleRecordingPreview = () => {
    if (isPreviewActive) {
      AppState.artistRecordings.isPreviewActive.value = false;
      localStorage.removeItem('soundRecordingPreview');
      setIsPreviewActive(false);
      route('/home');
    } else {
      AppState.artistRecordings.isPreviewActive.value = true;
      localStorage.setItem('soundRecordingPreview', 'true');
      setIsPreviewActive(false);
      route('/home');
    }
  };

  return (
    <FullScreenModal formWidth="23em">
      {isPreviewActive
        ? (
          <Box mb="1rem">
            <PrimaryText size="1.25rem" weight="600" mb="0.25rem">Sound Recordings Preview - Active</PrimaryText>
            <SecondaryText size="1rem">UI and Features can be viewed during development</SecondaryText>
          </Box>
        )
        : (
          <Box mb="1rem">
            <PrimaryText size="1.25rem" weight="600" mb="0.25rem">Sound Recordings Preview - Disabled</PrimaryText>
            <SecondaryText size="1rem">UI and Features that are in development are hidden</SecondaryText>
          </Box>
        )}
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText={isPreviewActive ? 'Disable' : 'Activate'}
        fluid
        onClick={handleToggleRecordingPreview}
      />
    </FullScreenModal>
  );
}

export default ActivateRecordingPreview;
