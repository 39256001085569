/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import Avatar from '@ui-kit/avatar';

import { AvatarCollectionWrapper, AvatarRow } from './AvatarCollectionStyles';

function AvatarCollection({
  users,
  ...restProps
}) {
  const imgUsers = users.sort((a, b) => !!b.imgURL - !!a.imgURL);
  const displayUsers = imgUsers.slice(0, 4);

  return (
    <AvatarCollectionWrapper>
      {users.length >= 1
        && (
          <AvatarRow userCount={displayUsers.length} {...restProps}>
            {displayUsers.map((user) => (
              <Avatar borderColor="var(--background)" size="2.25em" imgURL={user.imgURL} />
            ))}
          </AvatarRow>
        )}
    </AvatarCollectionWrapper>
  );
}

AvatarCollection.propTypes = {
  users: PropTypes.array.isRequired,
};

export default AvatarCollection;
