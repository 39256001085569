/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state';

import loadOrgLists from '@actions/loadOrgLists';
import { postOrgListContent, deleteOrgListContent } from '@api/restricted/org-list-contents';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';
import ListTypeAhead from '@distinct-components/typeAheadInputs/listsTypeAhead';

function ToolsManageLists({ compositionId, loadInternalData, assignedLists }) {
  const [isLoadingLists, setIsLoadingLists] = useState(false);

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const handleAssignToList = (list) => {
    if (compositionId < 1) {
      // VAPOR
      alert(`add to list: ${list.name}`);
      return;
    }

    setIsLoadingLists(true);
    postOrgListContent(list.id, AppState.pubOrganization.id.value, compositionId)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: true }).then(() => {
              // Might want to have success toast alert
              setIsLoadingLists(false);
            });
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsLoadingLists(false);
        }
      })
      .catch((err) => {
        setIsLoadingLists(false);
        useToastAlertError(err.message);
      });
  };

  const handleUnassignFromList = (list) => {
    if (compositionId < 1) {
      // VAPOR
      alert(`remove from list: ${list.name}`);
      return;
    }

    setIsLoadingLists(true);
    deleteOrgListContent(list.id, AppState.pubOrganization.id.value, compositionId)
      .then((response) => {
        if (response.status === 200) {
          loadInternalData(compositionId).then(() => {
            loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: true }).then(() => {
              // Might want to have success toast alert
              setIsLoadingLists(false);
            });
          });
        } else {
          response.json()
            .then((json) => useToastAlertError(json.error || response.statusText))
            .catch(() => useToastAlertError(response.statusText));
          setIsLoadingLists(false);
        }
      })
      .catch((err) => {
        setIsLoadingLists(false);
        useToastAlertError(err.message);
      });
  };

  return (
    <Box p="1em 1em 0em 1em">
      <InputLabel mb="1em" label="Assign to Lists" />
      <ListTypeAhead
        currentLists={assignedLists}
        allLists={AppState.publisherLists.value}
        handleAssignToList={handleAssignToList}
        handleUnassignFromList={handleUnassignFromList}
        isLoadingLists={isLoadingLists}
      />
      <BreakLine mt="1.5em" color="var(--border)" />
    </Box>
  );
}

ToolsManageLists.propTypes = {
  compositionId: PropTypes.number.isRequired,
  loadInternalData: PropTypes.func.isRequired,
  assignedLists: PropTypes.array.isRequired,
};

export default ToolsManageLists;
