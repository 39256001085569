import { useState, useEffect, useRef } from 'preact/hooks';
import AppState from '@state';
import { route } from 'preact-router';
import { Fragment } from 'preact';

import useWindowDimensions from '@hooks/useWindowDimensions';

import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import TableControls from '@ui-kit/table/tableControls';
import TablePagination from '@ui-kit/table/tablePagination';
import RecordingProjectCard from '@distinct-components/cards/recordingProjectCard';

import { RECORDING_EXPLANATIONS } from '@constants/supportingCopy';

import {
  StyledProjectCardGrid,
} from './ArtistRecordingsStyles';

import { MOCK_PROJECT_DATA } from './MOCK_RECORDING_DATA';
// TODO: MOCK OBJECT THAT IS THE PARTICIPANT CREDIT SUMMARY WHEN CLICKING ON PEOPLE.

function ArtistRecordings() {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [recordingProjects, setRecordingProjects] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.artistRecording.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const loadArtistProjects = () => {
    setRecordingProjects(MOCK_PROJECT_DATA); // TODO: UPDATE MOCK REFERENCE
    setIsLoadingPage(false);
  };

  useEffect(() => {
    document.title = 'Recording Projects';
    if (height < 1080) {
      setItemsPerPage(7);
    }
    loadArtistProjects();
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = recordingProjects.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    setCurrentPage(1);
    if (query) {
      const results = [];
      MOCK_PROJECT_DATA.forEach((project) => {
        if (project.projectName.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(project);
        }
      });
      setRecordingProjects(results);
      setSearchActive(true);
    } else {
      setRecordingProjects(MOCK_PROJECT_DATA); // TODO: UPDATE MOCK REFERENCE
      setSearchActive(false);
    }
  };

  const addNew = () => {
    route('/recordings/create');
  };

  return (
    <CoreLayout globalAlert={null}>
      <PageCard
        title="Recordings"
        subTitle="Prepare your work with anti-fraud protection, credits, and verifiable copyright data"
        primaryAction={{ method: addNew, text: '+ New' }}
        isLoadingPage={isLoadingPage}
      >
        <div ref={pageRef} />
        {recordingProjects.length === 0
          ? (
            <Card variant="panel" mt="1.5em">
              <BaseEmptyState
                title="Add Your First Recording"
                message={RECORDING_EXPLANATIONS.projectList.emptyState}
                action={addNew}
                actionText="Add a Recording"
                illustration="recording"
                minHeight="38em"
              />
            </Card>
          )
          : (
            <Fragment>
              {/* TODO: sort order control & toggle table/card view */}
              <TableControls
                itemName="Project"
                tableData={recordingProjects}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={recordingProjects}
                handleSearch={onSearchResults}
              />
              <StyledProjectCardGrid>
                {currentResults.map((p) => (
                  <RecordingProjectCard project={p} />
                ))}
              </StyledProjectCardGrid>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={recordingProjects.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}
      </PageCard>
    </CoreLayout>
  );
}

export default ArtistRecordings;
