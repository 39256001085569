import PropTypes from 'prop-types';
import Text from '@ui-kit/typography/text';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function PrimaryText({
  children,
  size,
  weight,
  color,
  lineHeight,
  isDecorative,
  ...restProps
}) {
  return (
    <Text
      fontSize={size}
      fontWeight={weight}
      lineHeight={lineHeight}
      color={color}
      fontFamily={isDecorative ? 'var(--font-lexend)' : 'var(--font-roboto)'}
      {...restProps}
    >
      {children}
    </Text>
  );
}

PrimaryText.propTypes = {
  children: childrenPropTypes.isRequired,
  size: PropTypes.string,
  lineHeight: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  isDecorative: PropTypes.bool,
};

PrimaryText.defaultProps = {
  size: '0.875rem',
  lineHeight: '1.6',
  weight: '500',
  color: 'var(--text-primary)',
  isDecorative: false,
};

export default PrimaryText;
