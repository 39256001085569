/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledPublicHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
    min-height: 5em;
    padding: 0em 2em;

    a {
        color: var(--text-primary);
        font-weight: 500;
        font-size: 0.75rem;
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2em 1em;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 44em;

    .identity-header {
        transition: all 700ms ease-in;
    }
`;

export const LyricViewWrapper = styled.div`
    margin-top: 1.5em;
    white-space: pre-wrap;
    color: var(--text-soft-mute);
    font-size: 0.938rem;
    line-height: 1.6;
`;
