/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const StyledTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--card-divider);
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`;

export const StyledTrackNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  border-radius: 0.5rem;
  color: var(--text-secondary);
  background: var(--pill-base-bg);
  font-weight: 500;
  flex-shrink: 0;
`;

export const StyledWorkspaceTitle = styled(Text)`
    font-size: 1.375rem;
    font-family: var(--font-secondary);
    color: var(--text-primary);
    font-weight: 800;
    margin-left: 0.875rem;
    line-height: 1.5;
    padding-right: 0.25rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 1rem;
        display: block;
    }
`;

export const StyledFinalFileChip = styled.span`
    font-weight: 600;
    font-size: 0.75rem;
    background: var(--color-green);
    color: var(--white);
    padding: 6px 12px;
    border-radius: 15px;
    margin-left: 0.875rem;
    transform: translateY(2px);
    flex-shrink: 0;
    white-space: nowrap;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.667rem;
        padding: 3px 8px;
        margin-left: 0.5rem;
    }
`;

export const InputLockWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 11px;
`;

export const StyledPlayerContentRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.875rem;
  background: var(--player-panel-bg);
  min-height: 112px;
  border-radius: 0.75rem;
  padding: 0.5rem;
`;

export const StyledNoFileWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--player-panel-bg);
  min-height: 112px;
  border-radius: 0.75rem;
  padding: 0.5rem;
  flex-direction: column;
  cursor: ${(props) => (props.isClickable === false ? 'default' : 'pointer')};
`;

export const StyledTrackInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
  padding-right: 0.875rem;
  padding: 0.25rem;
`;

export const StyledWaveAreaWrapper = styled.div`
  position: relative;
  background: var(--player-panel-bg);
  height: 56px;
  overflow: hidden;
  flex-grow: 1;
  margin-top: 1rem;

  @media screen and (max-width: ${pointBreaks.sm}) {
    width: 100%;
  }
`;

export const StyledWaveFormWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: -10px;
  left: 0px;
  overflow: hidden;
`;

export const StyledActionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  border-radius: 0.5rem;
  background: var(--pill-base-bg);
  cursor: pointer;
  flex-shrink: 0;
`;

export const StyledContentCredentialWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const StyledAdminActionsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--card-divider);
  padding-top: 0.875rem;
  margin-top: 0.875rem;
  transform: translateY(2px);

  @media screen and (max-width: ${pointBreaks.sm}) {
      flex-direction: column;
      gap: 1em;
      align-items: flex-start;
  }
`;
