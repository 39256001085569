/* eslint-disable import/prefer-default-export */
import { adminCwrUrl } from '../urls';
import refresh from '../refresh';

export async function postAdminCWR(file) {
  const formData = new FormData();

  formData.append('cwrFile', file);

  return refresh(async () => {
    const response = await fetch(adminCwrUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: formData,
    });
    return response;
  });
}
