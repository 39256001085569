import styled from 'styled-components';
import { space } from 'styled-system';
import pointBreaks from '@styles/styleConstants';

import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

export const AvatarImgWrapper = styled.div(({
  avatarImg,
}) => `
      border: 2px solid ${avatarImg ? 'var(--panel-divider)' : 'var(--brand-primary)'};
      height: 6em;
      width: 6em;
      border-radius: 50%;
      background-image: url('/assets/illustrations/make-it-rain.svg');
      ${avatarImg && `background-image: url(${avatarImg})`};
      background-size: cover;
      background-position: top center;
      margin-right: 1.5em;
      cursor: pointer;
  
      @media screen and (max-width: ${pointBreaks.sm}) {
        margin-right: 0em;
        margin-bottom: 0.5em;
        height: 6em;
        width: 6em;
      }
  
      ${space};
  `);

export const AvatarCheckWrapper = styled.div`
    position: absolute;
    right: 26px;
    top: 8px;

    @media screen and (max-width: ${pointBreaks.sm}) {
        right: 4px;
        top: 8px;
      }
`;

export const AccountDetailsWrapper = styled.div`
    display: flex;
    padding: 0em 1.5em 1.5em 1.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
        align-items: center;
        flex-direction: column;
        text-align: center;
        flex: 1;
    }
    ${space};
`;

export const ActionsWrapper = styled.div`
    transform: translate(-14px, 12px);
    z-index: 0;
`;

export const DesktopWalletButton = styled.div`
   padding-right: 1em;
   cursor: pointer;
   display: block;

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: none;
    }
`;

export const MobileWalletButton = styled.div`
   padding-left: 1em;
   cursor: pointer;
   padding-top: 10px;
   display: none;

   svg {
    height: 32px;
    width: auto;
   }

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: block;
    }
`;

export const WalletLoadingWrapper = styled.div`
    height: 32px;
    background: black;
    border-radius: 9px;
    width: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-8px);

    @media screen and (max-width: ${pointBreaks.sm}) {
        transform: translateY(0px);
    }
`;

export const StyledUserName = styled.h2`
    font-size: 1.25rem;
    color: var(--text-primary);
    margin-bottom: 0.125em;
    margin-top: 0.5em;

    @media screen and (max-width: ${pointBreaks.sm}) {
         margin-top: 0.5em;
    }
`;

export const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
    ${space};

    input {
        display: none;
    }
`;

export const AvatarPreviewWrapper = styled.div(({
  previewImage,
}) => `
    border: 2px solid var(--brand-primary);
    height: 6em;
    width: 6em;
    border-radius: 50%;
    background-image: url('assets/illustrations/make-it-rain.svg');
    ${previewImage && `background-image: url(${previewImage})`};
    background-size: cover;
    background-position: top center;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 6em;
        width: 6em;
    }

    ${space};
`);

export const AvatarButtonWrapper = styled.label`
    height: 40px;
    width: 40px;
    background: var(--background);
    border: 2px solid var(--brand-primary);
    position: absolute;
    border-radius: 50%;
    transform: translate(32px,60px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const CircleProgress = styled.div`
    position: absolute;
    transform: rotate(-90deg) translate(18px, -3px);
    display: block;

    & svg {
        height: 112px;
        width: auto;
        overflow: visible;
        opacity: 0;
        transition: all 2s ease;
       
        .circle {
           transform-origin: 50% 50%;
        }
      }
`;

export const InputPrefix = styled.div`
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75em;
    font-size: 1em;
    height: inherit;
    background: var(--input-append-bg);
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    height: 42px;
    position: absolute;
    margin-top: 1px;
    margin-bottom: 1px;
`;

export const StyledBaseInput = styled(BaseInput)`
      padding-left: 3.75em;
`;

export const VerificationBadge = styled.div(({
  verified,
}) => `
    display: flex;
    align-items: center;
    justify-content: ${verified ? 'center' : 'flex-start'};
    color: var(--text-primary);
    background: ${verified ? 'var(--trust)' : 'var(--untrusted)'};
    border-radius: 0.5em;
    border-top-right-radius: ${verified ? '0.5em' : '0em'};
    border-bottom-right-radius: ${verified ? '0.5em' : '0em'};
    padding: ${verified ? '0.25em 1em' : '0.25em 0.125em 0.25em 0.5em'};
    color: var(--text-primary);
    font-size: 11px;
    font-weight: 600;
    min-height: 22px;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 0.5em;
        min-height: 28px;
    }
`);

export const VerificationBadgeButton = styled.div`
      display: flex;
      align-items: center;
      justify-content: 'center';
      color: var(--text-white);
      background: var(--pill-base-bg);
      border: 0.8px solid var(--trust);
      border-radius: 0.5em;
      padding: 0.25em 1em;
      color: var(--text-primary);
      font-size: 11px;
      font-weight: 600;
      min-height: 22px;
      cursor: pointer;
      ${space};

      @media screen and (max-width: ${pointBreaks.sm}) {
        margin-top: 0.5em;
        min-height: 28px;
    }
  `;
