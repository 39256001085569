import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import AppState from '@state/AppState';

import loadAccountProfile from '@actions/loadAccountProfile';

import Box from '@ui-kit/box';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

// import AddRecording from './sections/addRecording/AddRecording';
import AddRecordingForm from '@components/shared-recording-components/addRecordingForm';

function ArtistAddRecordingToProject() {
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  useEffect(() => {
    document.title = 'Add Recording';
    if (!AppState.profileLoaded.value) {
      loadAccountProfile().then(() => {
        setIsLoadingProfile(false);
      });
    } else {
      setIsLoadingProfile(false);
    }
  }, []);

  const handleBack = () => {
    route('/recordings');
  };

  return (
    <FullScreenModal isLoading={isLoadingProfile} formWidth="22em" closeFunction={handleBack}>
      <Box>
        <AddRecordingForm />
      </Box>
      {/* <AddRecording /> */}
    </FullScreenModal>
  );
}

export default ArtistAddRecordingToProject;
