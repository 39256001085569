import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';

import SwitchLogo from '@assets/logos/switchchord-logo-main.svg';
import Icon from '@ui-kit/icon';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import UtilityButton from '@ui-kit/buttons/utilityButton';

import {
  ModalPage,
  ModalContainer,
  ModalUtilityRow,
  ModalContentWrapper,
  ModalFormWrapper,
  ModalFormWrapperCentered,
} from './FullScreenModalStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function FullScreenModal({
  children,
  closeFunction,
  formWidth,
  formCentered,
  logoPath,
  isLoading,
}) {
  useEffect(() => {
    document.body.style.backgroundColor = 'var(--background)';
  }, []);

  const onClickModalLogo = () => {
    if (logoPath) {
      route(logoPath);
    }
  };

  return (
    <ModalPage>
      <ModalContainer>
        <ModalUtilityRow>
          <Icon size="172px" onClick={onClickModalLogo} style={{ cursor: logoPath ? 'pointer' : 'default' }}><SwitchLogo /></Icon>
          {closeFunction
          && <UtilityButton ml="0.875em" icon="close" onClick={closeFunction} />}
        </ModalUtilityRow>
        <ModalContentWrapper formCentered={formCentered}>
          {isLoading ? (
            <Box className="anti-flicker-anim-1s" width="100%" display="flex" justifyContent="center" mt="3rem">
              <Spinner size="1.5em" variant="page" />
            </Box>
          )
            : (
              <Box>
                {formCentered
                  ? (
                    <ModalFormWrapperCentered formWidth={formWidth}>
                      {children}
                    </ModalFormWrapperCentered>
                  )
                  : (
                    <ModalFormWrapper formWidth={formWidth}>
                      {children}
                    </ModalFormWrapper>
                  )}
              </Box>
            )}
        </ModalContentWrapper>
      </ModalContainer>
    </ModalPage>
  );
}

FullScreenModal.propTypes = {
  children: childrenPropTypes.isRequired,
  closeFunction: PropTypes.func,
  formWidth: PropTypes.string,
  formCentered: PropTypes.bool,
  logoPath: PropTypes.string,
  isLoading: PropTypes.bool,
};

FullScreenModal.defaultProps = {
  closeFunction: null,
  formWidth: null,
  formCentered: false,
  logoPath: null,
  isLoading: false,
};

export default FullScreenModal;
