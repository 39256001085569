/* eslint-disable react/forbid-prop-types */
import { useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';

import useClickOutside from '@hooks/useClickOutside';
import useTruncateText from '@hooks/useTruncateText';

import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import PopoverMenu from '@ui-kit/menu/popoverMenu';
import LinkButton from '@ui-kit/buttons/linkButton';
import CountDown from '@distinct-components/feedback/countDown';

import {
  FileCardWrapper,
  DynamicWrapper,
  FileContentWrapper,
  MenuWrapper,
  MenuDot,
  MenuItem,
} from './FileCardStyles';

const childrenPropTypes = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.func,
  PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ])),
]);

function FileCard({
  file,
  primaryDetail,
  secondaryDetail,
  activeUser,
  handleFileDelete,
  handleFileDownload,
  showMenu,
  children,
}) {
  const [showActions, setShowActions] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const tt = useTruncateText;

  const menuRef = useRef();
  useClickOutside(menuRef, () => setShowActions(false));

  const onClickRemoveFile = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const onClickConfirmDelete = () => {
    setShowActions(false);
    handleFileDelete(file);
  };

  const onClickDownload = () => {
    setShowActions(false);
    handleFileDownload(file);
  };

  return (
    <FileCardWrapper mb="1em">
      <DynamicWrapper>
        {children}
      </DynamicWrapper>
      <FileContentWrapper>
        <Text fontWeight="500">
          {tt(file?.name, { sm: 18, md: 50, lg: 60 }, { keepExtension: true })}
        </Text>
        <Box display="flex">
          {primaryDetail && <Text color="var(--text-secondary)" mr="1em">{primaryDetail}</Text>}
          {secondaryDetail && <Text color="var(--text-secondary)">{secondaryDetail}</Text>}
        </Box>
      </FileContentWrapper>
      {showMenu
      && (
      <MenuWrapper>
        <Box display="flex" alignItems="center" height="1em" className="cursor-p" onClick={() => setShowActions(true)}>
          <MenuDot />
          <MenuDot />
          <MenuDot />
        </Box>
        {showActions && (
        <PopoverMenu width="8.5rem" padding="0.5rem" ref={menuRef} top="12px" right="2px">
          <MenuItem>
            <Text fontSize="0.875rem" className="cursor-p" color="var(--text-secondary)" mr="1em" onClick={onClickDownload}>Download</Text>
          </MenuItem>
          <MenuItem>
            {activeUser && activeUser.isCanEdit && (
            <Box display="flex">
              {showDeleteConfirmation
                ? (
                  <Box display="flex" alignItems="center">
                    <CountDown mr="0.25em" mt="3px" timeInSeconds={5} size={12} color="var(--red-300)" />
                    <LinkButton btnText="Yes, Delete" color="var(--red-300)" mr="1em" onClick={onClickConfirmDelete} underline={false} />
                  </Box>
                )
                : <LinkButton btnText="Delete" color="var(--text-secondary)" mr="1em" onClick={() => onClickRemoveFile()} underline={false} />}
            </Box>
            )}
          </MenuItem>
        </PopoverMenu>
        )}
      </MenuWrapper>
      )}
    </FileCardWrapper>
  );
}

FileCard.propTypes = {
  children: childrenPropTypes.isRequired,
  file: PropTypes.object.isRequired,
  primaryDetail: PropTypes.string,
  secondaryDetail: PropTypes.string,
  activeUser: PropTypes.object,
  handleFileDelete: PropTypes.func,
  handleFileDownload: PropTypes.func,
  showMenu: PropTypes.bool,
};

FileCard.defaultProps = {
  primaryDetail: null,
  secondaryDetail: null,
  handleFileDelete: null,
  handleFileDownload: null,
  showMenu: true,
  activeUser: null,
};

export default FileCard;
