/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const BetaChip = styled.div`
  background: #99a2aa;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 4px;
  color: var(--side-nav-panel);
`;
