/* eslint-disable import/order */
import AppState from '@state';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { scrollIntoView } from 'seamless-scroll-polyfill';

import loadAccountProfile from '@actions/loadAccountProfile';
// import loadComposition from '@actions/loadComposition';
// import loadWorkspaceAccounts from '@actions/loadWorkspaceAccounts';

// import { postWorkspaceConfirm } from '@api/restricted/workspace-confirm-api';

import usePollForData from '@hooks/usePollForData';
import useResponsiveText from '@hooks/useResponsiveText';

import SoloLogo from '@assets/logos/logo-symbol.svg';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import Header from '@ui-kit/typography/header';
import Icon from '@ui-kit/icon';
import Spinner from '@ui-kit/loaders/Spinner';
import Tabs from '@ui-kit/tabs/Tabs';
import Text from '@ui-kit/typography/text';
import UtilityButton from '@ui-kit/buttons/utilityButton';

import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import { MOCK_SINGLE_RECORDING } from '../recordings/MOCK_RECORDING_DATA';

import RecordingFileSection from './sections/recordingFileSection';
import RecordingInstructionsSection from './sections/recordingInstructionsSection';
import RecordingCreditsSection from './sections/recordingCreditsSection';
// import LeaveWorkspace from './modes/sharedSections/leaveWorkspace';
// import DeleteWorkspace from './modes/sharedSections/deleteWorkspace';
// import ShareWorkspace from './modes/sharedSections/shareWorkspace';

import {
  WorkspaceWrapper,
  HeaderWrapper,
  HeaderDetails,
  HeaderActions,
  NavigationWrapper,
  NavigationTabs,
  PageWrapper,
  PageContent,
  WorkItemsWrapper,
  MobileHeaderContent,
  HeaderContent,
  MobileTitleWrapper,
} from '@styles/sharedStyledComponents/workspaceSharedStyledComponents';

function RecordingWorkspace({ id }) {
  const [isLoadingWorkspace, setIsLoadingWorkspace] = useState(true);
  const [recordingData, setRecordingData] = useState(null);

  const [showJoinWorkspace, setShowJoinWorkspace] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [joinWorkspaceError, setJoinWorkspaceError] = useState(null);

  const [activeUser, setActiveUser] = useState({});
  const [showShareWorkspace, setShowShareWorkspace] = useState(false);

  const [workspaceTab, setWorkspaceTab] = useState('Recording');
  const [menuActions, setMenuActions] = useState([]);

  const isChildEditing = AppState.workspace.isEditingChild.value;
  const workspaceId = parseInt(id, 10);

  const rt = useResponsiveText;

  const loadWorkspace = async () => {
    console.log(workspaceId);
    await loadAccountProfile();
    // LOAD RECORDING WORKSPACE
    // LOAD RECORDING PARTICIPANTS

    setRecordingData(MOCK_SINGLE_RECORDING);
    // LINTER HAPPINESS
    console.log(activeUser);
    // COMMENTING OUT UNTIL PARTICIPANTS FIGURED OUT
    // const currentUserId = AppState.accountId.value;
    // const workspaceAccounts = [];
    // const currentUser = workspaceAccounts.find((user) => user.id === currentUserId);

    // REMOVE AFTER WORKSPACE PARTICIPANTS IS SORTED OUT
    const TEMP_CURRENT_USER = { id: AppState.accountId.value, isAdmin: true };
    setActiveUser(TEMP_CURRENT_USER);
    setShowJoinWorkspace(false); // DECIDE IF JOIN IS APPLICABLE IN RECORDING
  };

  const handlePollForData = () => {
    // LOAD RECORDING PARTICIPANTS
  };

  usePollForData(true, 5000, handlePollForData, false);

  useEffect(() => {
    document.title = 'Recording';
    scrollIntoView(document.querySelector('#scrollPoint'), { behavior: 'instant', block: 'center', inline: 'center' });

    const actions = [
      { action: () => alert('Option Clicked'), label: 'Edit Recording Info' },
      { action: () => alert('Option Clicked'), label: 'Mark Current File as “Final Version”' },
      { action: () => alert('Option Clicked'), label: 'Add New File' },
      { action: () => alert('Option Clicked'), label: 'Delete Recording', isDestructive: true },
    ];
    setMenuActions(actions);

    loadWorkspace().then(() => {
      setIsLoadingWorkspace(false);
    });
  }, []);

  const handleTabChange = (tab) => {
    setWorkspaceTab(tab.value);
  };

  const onCloseWorkspace = () => {
    // if (AppState.navigation.backActionPath.value != null) {
    //   route(AppState.navigation.backActionPath.value);
    // } else {
    route('/recordings/project/1');
    // }
  };

  const onToggleShareWorkspace = () => {
    setShowShareWorkspace(!showShareWorkspace);
  };

  const onClickJoinWorkspace = () => {
    setIsJoining(true);
    setJoinWorkspaceError(null);

    const MOCK_SUCCESS = true;

    if (MOCK_SUCCESS) {
      setIsLoadingWorkspace(true);
      setTimeout(() => {
        loadWorkspace().then(() => {
          setIsJoining(false);
          setJoinWorkspaceError(null);
          setIsLoadingWorkspace(false);
        });
      }, 1200);
    } else {
      setJoinWorkspaceError('Unable to Join Workspace');
    }
  };

  const onClickCancelJoinWorkspace = () => {
    route('/home');
  };

  return (
    <WorkspaceWrapper isEditing={isChildEditing}>
      <HeaderWrapper id="scrollPoint">
        <MobileHeaderContent>
          <Box justifySelf="flexStart" flex="0">
            <UtilityButton iconSize="1rem" icon="arrow-left" onClick={onCloseWorkspace} iconTransform="translateY(-1px)" />
          </Box>
          <MobileTitleWrapper>
            <Text fontWeight="700" fontSize="0.938rem" color="var(--text-primary)">Sound Recording</Text>
          </MobileTitleWrapper>
          {!isLoadingWorkspace && (
            <Box display="flex" justifySelf="flex-end" justifyContent="flex-end" position="relative">
              <UtilityButton iconSize="1rem" mr="1rem" icon="share" onClick={onToggleShareWorkspace} />
              <UtilityButton
                iconSize="1rem"
                icon="menu"
                iconTransform="translateY(-4px)"
                menuOptions={menuActions}
              />
            </Box>
          )}
        </MobileHeaderContent>
        <HeaderContent>
          <HeaderDetails className="cursor-p" onClick={onCloseWorkspace}>
            <Icon pt="0.188em" cursor><SoloLogo /></Icon>
            <Text fontWeight="700" ml="0.75em" fontSize="1em" color="var(--text-primary)">Sound Recording</Text>
          </HeaderDetails>
          <HeaderActions>
            <UtilityButton
              mr="0.875rem"
              suffixDecoration="share"
              suffixIconSize="0.938rem"
              suffixIconTransform="translateY(2px)"
              btnText="Share"
              onClick={onToggleShareWorkspace}
            />
            <UtilityButton
              btnText="Actions"
              suffixDecoration="chevron"
              menuOptions={menuActions}
            />
            <UtilityButton ml="0.875em" icon="close" onClick={onCloseWorkspace} />
          </HeaderActions>
        </HeaderContent>
      </HeaderWrapper>
      {isLoadingWorkspace ? (
        <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
          <Spinner size="2em" variant="page" />
        </Box>
      ) : (
        <PageWrapper>
          <PageContent>
            <NavigationWrapper>
              <NavigationTabs>
                <Tabs
                  tabs={[
                    { name: 'Recording', value: 'Recording' },
                    { name: rt({ text: 'Composition Link', mobileText: 'Composition' }), value: 'Composition' },
                    { name: rt({ text: 'Files & Data', mobileText: 'Data' }), value: 'Data' },
                    { name: 'Activity', value: 'Activity' },
                  ]}
                  handleTabChange={handleTabChange}
                  selectedTab={workspaceTab}
                />
              </NavigationTabs>
            </NavigationWrapper>
            <WorkItemsWrapper style={AppState.media.currentlyPlaying.value && { marginBottom: '6.5em' }}>
              {
                {
                  Recording: (
                    <Box>
                      <RecordingFileSection recordingData={recordingData} activeUser={activeUser} />
                      <RecordingInstructionsSection recordingData={recordingData} activeUser={activeUser} />
                      <RecordingCreditsSection recordingData={recordingData} activeUser={activeUser} />
                    </Box>
                  ),
                }[workspaceTab]
              }
            </WorkItemsWrapper>
          </PageContent>
        </PageWrapper>
      )}

      {showJoinWorkspace && (
      <WorkspaceEditOverlay
        formWidth="23em"
        formCentered
      >
        <Header mb="0.25em">
          Join:
          {' '}
          {recordingData.recordingTitle}
        </Header>
        <Text variant="thinPrompt" mb="1.5em">By clicking Continue, you confirm that you are a participant.</Text>

        <Box mt="2px">
          {joinWorkspaceError && (
          <AlertMessage
            variant="negative"
            message={joinWorkspaceError}
            mb="1.5em"
          />
          )}

          <Box>
            <BaseButton
              mb="1.5em"
              btnText="Join Composition"
              onClick={onClickJoinWorkspace}
              disabled={isJoining}
              isLoading={isJoining}
              fluid
            />
            <BaseButton
              mb="1.5em"
              btnText="Cancel"
              variant="secondary"
              onClick={onClickCancelJoinWorkspace}
              disabled={isJoining}
              fluid
            />
          </Box>
        </Box>
      </WorkspaceEditOverlay>
      )}
    </WorkspaceWrapper>
  );
}

RecordingWorkspace.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RecordingWorkspace;
