/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import pointBreaks from '@styles/styleConstants';
import Text from '@ui-kit/typography/text';

export const CardWrapper = styled.div`
    background: var(--card-over-card-bg);
    border-radius: 1em;
    width: 100%;
    border: 0.8px solid var(--card-border);
    padding: 1rem;
    cursor: inherit;
    ${space};
    ${layout};

    @media screen and (max-width: ${pointBreaks.sm}) {
        padding: 0.875rem;
    }
`;

export const StyledImageTitleWrapper = styled.div`
    display: flex;
    gap: 0.875rem;
    align-items: center;
    flex-grow: 1;

     @media screen and (max-width: ${pointBreaks.sm}) {
        gap: 0.75;
    }
`;

export const StyledRecordingTitleWrapper = styled.div`
    transform: translateY(0px);
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
`;

export const StyleRecordingTitleText = styled(Text)`
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: 400;

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.75rem;
    }
`;

export const StyledTrackNumberWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    margin-right: 0.875rem;
`;

export const StyledDragBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    cursor: drag;
    gap: 0.25rem;
`;

export const StyledDragBar = styled.div`
    height: 2px;
    width: 16px;
    background: var(--card-handle-element-bg);
`;

export const StyledRecordingInfoWrapper = styled.div`
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: ${pointBreaks.sm}) {
        display: none;
    }
`;

export const StyleRecordingInfoItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    justify-content: center;

    &:last-child {
        justify-content: flex-end;
        margin-right: 0.5rem;
    }
`;

export const StyleRecordingInfoItemLabel = styled(Text)`
    font-size: 0.813rem;
    line-height: 1;
    font-weight: 400;
    color: var(--text-secondary);
`;

export const StyleRecordingInfoFinalChip = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 0.75rem;
    background: var(--color-green);
    color: var(--white);
    padding: 6px 12px;
    border-radius: 15px;
`;
