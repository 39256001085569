/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types';
import { useEffect } from 'preact/hooks';
import anime from 'animejs/lib/anime.es';

import Icon from '@ui-kit/icon';
import SecondaryText from '@ui-kit/typography/secondaryText';

import EmptyBubbles from '@assets/illustrations/empty-bubbles.svg';

import { EmptyStateWrapper, IconWrapper } from './EmptyStateListStyles';

function EmptyStateList({
  message,
  ...restProps
}) {
  const emptyAnimation = anime.timeline({
    autoplay: true,
    loop: true,
  });

  useEffect(() => {
    const dots = document.getElementsByClassName('empty-dot');
    emptyAnimation
      .add({
        targets: dots,
        opacity: [0, 1, 1, 0],
        translateY: [0, -5, 0],
        translateX: () => anime.random(-4, 6.75),
        duration: 2400,
        easing: 'easeInOutSine',
        delay: anime.stagger(anime.random(80, 150)),
      });
  }, []);

  return (
    <EmptyStateWrapper {...restProps} id="animation">
      <IconWrapper>
        <Icon size="100%" color="var(--empty-state-primary)"><EmptyBubbles /></Icon>
      </IconWrapper>
      <SecondaryText>{message}</SecondaryText>
    </EmptyStateWrapper>
  );
}

EmptyStateList.propTypes = {
  message: PropTypes.string,
};

EmptyStateList.defaultProps = {
  message: 'No Items to Display',
};

export default EmptyStateList;
