import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space } from 'styled-system';

export const StyledCreditsListWrapper = styled.div`
    min-height: 20em;
    max-height: 40rem;
    overflow-y: scroll;
    margin-bottom: 1.5em;
    border-bottom: 1px solid var(--panel-divider);
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        min-height: 14em;
        max-height: 20em;
    }
`;

export const StyledCreditsAddedWrapper = styled.div`
    min-height: 20em;
    max-height: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5em;
    border-bottom: 1px solid var(--panel-divider);
    ${space};
`;

export const StyledClearSearchChip = styled.div`
    position: absolute;
    height: 26px;
    width: 26px;
    background: var(--input-action-icon-bg);
    border-radius: 50px;
    top: 32px;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: ${pointBreaks.sm}) {
        height: 28px;
        width: 28px;
     }
`;

export const StyledIndicatorWrapper = styled.div(({
  background,
  count,
}) => `
    color: ${count > 0 ? 'var(--white)' : 'var(--text-secondary)'};
    position: absolute;
    background: ${background};
    border-radius: 50px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 0;
    top: -6px;
    right: -6px;
    box-shadow: var(--pill-indicator-shadow);
`);

export const StyledUserInfoWrapper = styled.div`
    display: flex;
    gap: 0.938rem;
    border-bottom: ${(props) => (props.isAdmin === true ? 'none' : '1px solid var(--panel-divider)')};
    align-items: center;
    padding-bottom: 0.875rem;
    margin-bottom: ${(props) => (props.isAdmin === false ? '1.5rem' : '0rem')};
`;

export const StyledAdminToolsRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0.875rem;
    border: 1px solid var(--panel-divider);
    align-items: center;
    padding: 0.875rem;
    margin-bottom: 1.5rem;
    background: var(--panel-card);
    border-radius: 8px;

     @media screen and (max-width: ${pointBreaks.sm}) {
        flex-direction: column;
        align-items: flex-start;
     }
`;
