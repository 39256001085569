/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--card-divider);
  margin-left: 1em;
  padding: 0.5em 1em 0.5em 0em;
  min-height: 2.625em;
`;

export const WorkspaceCardHeader = styled.div`
  font-size: 0.875em;
  font-weight: 500;
  color: var(--text-soft-mute);
`;

export const CardActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CardBodyWrapper = styled.div`
  padding: 1em;
`;

export const StyledTabsWrapper = styled.div`
  display: flex;
  gap: 0.875rem;
  align-items: center;
`;

export const StyledTab = styled.div`
  border-bottom: ${(props) => (props.isActive === false ? '2px solid transparent' : '2px solid var(--active-nav)')};
  color: ${(props) => (props.isActive === false ? 'var(--text-secondary)' : 'var(--text-primary)')};
  font-weight: 500;
  font-size: 0.875rem;
  padding: 6px 0px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard syntax */
`;
