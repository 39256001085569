import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';

import Box from '@ui-kit/box';
import Span from '@ui-kit/typography/span';

function ConversationExplanation({
  explanation,
  truncateAtCharacter,
  ...restProps
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const explanationPreview = explanation.slice(0, truncateAtCharacter);

  return (
    <Box {...restProps}>
      {!isExpanded
        ? (
          <Fragment>
            <Span lineHeight="1.7" color="var(--text-secondary)">
              {explanationPreview}
              {explanation.length > truncateAtCharacter && '...' }
            </Span>
            {explanation.length > truncateAtCharacter && <Span pl="0.5em" className="cursor-p" color="var(--text-muted-panel)" onClick={() => setIsExpanded(true)}>Tell Me More</Span>}
          </Fragment>
        )
        : (
          <Fragment>
            <Span lineHeight="1.7">{explanation}</Span>
            <Span pl="0.5em" className="cursor-p" color="var(--text-muted-panel)" onClick={() => setIsExpanded(false)}>View Less</Span>
          </Fragment>
        )}
    </Box>
  );
}

ConversationExplanation.propTypes = {
  explanation: PropTypes.string.isRequired,
  truncateAtCharacter: PropTypes.number,
};

ConversationExplanation.defaultProps = {
  truncateAtCharacter: 80,
};

export default ConversationExplanation;
