import { useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import BaseUserCard from '@distinct-components/cards/baseUserCard';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import EmptyStateList from '@distinct-components/feedback/emptyStateList';
import BaseButton from '@ui-kit/buttons/baseButton';

// MOCK THINGS
import { MOCK_SINGLE_PROJECT } from '../../../routes/restricted/core/recordings/MOCK_RECORDING_DATA';

function ArtistAddExistingParticipant({
  activeUser,
  recordingData,
  handleArtistAdded,
  ...restProps
}) {
  const [availableParticipants, setAvailableParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [artistNameQuery, setArtistNameQuery] = useState('');
  const searchRef = useRef(null);

  const loadExistingProjectParticipants = () => {
    // use recording.projectId to fetch the existing project people.
    const allProjectParticipants = MOCK_SINGLE_PROJECT.participantSummary;
    const currentParticipants = recordingData.participants || [];
    const availableToAdd = allProjectParticipants.filter(
      (a) => !currentParticipants.some((c) => c.id === a.id),
    );
    setAvailableParticipants(availableToAdd);
  };

  useEffect(async () => {
    // Get project id from the recording data.
    loadExistingProjectParticipants();
  }, []);

  const onSearchArtistName = (name) => {
    setArtistNameQuery(name);
  };

  const onClickAddExistingArtist = (artist) => {
    console.log('selected the ', artist);
    setSelectedParticipant(artist);
  };

  const onClickConfirmSelectedArtist = () => {
    // call the api to add the existing artist to the recording
    // once complete, pass the artist to the parent handleArtistAdded function
    // this will pop up the manage credits modal with the new artist.
    handleArtistAdded(selectedParticipant);
  };

  return (
    <Box {...restProps}>
      <Box mb="1.5rem">
        <BaseInput
          ref={searchRef}
          id="userTypeAhead"
          name="userTypeAhead"
          placeholder="Find by name..."
          onChange={(e) => onSearchArtistName((e.target.value))}
          value={artistNameQuery}
          type="email"
          fluid
          required
          autoComplete="off"
        />
      </Box>
      {artistNameQuery.length > 0
        ? (
          <Box>
            {availableParticipants.filter((a) => a.name.includes(artistNameQuery)).map((p) => (
              <BaseUserCard user={p} mb="0.875rem" selected={p.id === selectedParticipant?.id} onClickCard={() => onClickAddExistingArtist(p)} />
            ))}
          </Box>
        ) : (
          <Box>
            {availableParticipants.map((p) => (
              <BaseUserCard user={p} mb="0.875rem" selected={p.id === selectedParticipant?.id} onClickCard={() => onClickAddExistingArtist(p)} />
            ))}
          </Box>
        )}

      {availableParticipants.filter((a) => a.name.includes(artistNameQuery)).length === 0 && (
        <EmptyStateList mb="0.875rem" message="No Artists Match that Name" />
      )}

      {selectedParticipant
        && (
          <BaseButton
            mt="2.5em"
            type="submit"
            btnText="Add & Assign Credits"
            fluid
            onClick={onClickConfirmSelectedArtist}
          />
        )}

    </Box>
  );
}

ArtistAddExistingParticipant.propTypes = {
  activeUser: PropTypes.object.isRequired,
  handleArtistAdded: PropTypes.func.isRequired,
  recordingData: PropTypes.object,
};

ArtistAddExistingParticipant.defaultProps = {
  recordingData: PropTypes.object,
};

export default ArtistAddExistingParticipant;
