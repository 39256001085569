/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import { space } from 'styled-system';

export const StyledProjectCardGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5em;
    ${space}

    @media screen and (max-width: ${pointBreaks.lg}) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: ${pointBreaks.sm}) {
        grid-template-columns: repeat(1, 1fr);
    }
`;
