/* eslint-disable import/prefer-default-export */
import { organizationUrl } from '../urls';
import refresh from '../refresh';

export async function getOrganization(uuid) {
  return refresh(async () => {
    const response = await fetch(`${organizationUrl}?${new URLSearchParams({
      uuid,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function putOrganizationName(orgId, name) {
  const data = {
    org: orgId,
    name,
  };
  const response = await fetch(organizationUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function patchOrganizationAutomaticHold(orgId, automaticHold) {
  const data = {
    org: orgId,
    automatic_hold: automaticHold,
  };
  const response = await fetch(organizationUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  });
  return response;
}
