/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';
import BaseButton from '@ui-kit/buttons/baseButton';
import { space } from 'styled-system';

export const ActionWrapper = styled.div`
    width: 100%;
    background: var(--card);
    border: 1px solid ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : props.actionColor === 'purple' ? 'var(--action-purple)' : 'var(--action-orange)')};
    padding: 1em;
    border-radius: 16px;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
      display: block;
    }
`;

export const ActionTitle = styled.div`
   font-size: 1rem;
   color: var(--text-primary);
   margin-bottom: 0.5rem;
   font-weight: 600;
   ${space};
`;

export const ActionMsg = styled.div`
    font-size: 0.813rem;
    color: var(--text-secondary);
    min-height: 2rem;
    line-height: 1.6;
    ${space};
`;

export const ActionBtnRow = styled.div`
   display: flex;
   justify-content: space-between;
   gap: 1rem;
    ${space};
`;

export const SecondaryActionRow = styled.div`
   display: flex;
   border-top: 1px solid ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : props.actionColor === 'purple' ? 'var(--action-purple)' : 'var(--action-orange)')};
   gap: 1rem;
   margin-top: 1.5rem;
   padding-top: 1rem;
   justify-content: flex-end;
    ${space};
`;

export const StyledActionButton = styled(BaseButton)`
    background: ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : props.actionColor === 'purple' ? 'var(--action-purple)' : 'var(--action-orange)')};
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.813rem;
        padding-left: 4px;
        padding-right: 4px;
    }
`;

export const StyledSecondaryButton = styled(BaseButton)`
    border: 1px solid ${(props) => (props.actionColor === 'green' ? 'var(--action-green)' : props.actionColor === 'purple' ? 'var(--action-purple)' : 'var(--action-orange)')};
    background: transparent;
    color: var(--text-primary);
    font-weight: 400;
    ${space};

    @media screen and (max-width: ${pointBreaks.sm}) {
        font-size: 0.813rem;
        padding-left: 4px;
        padding-right: 4px;
    }
`;
