import { useState, useEffect } from 'preact/hooks';
import styled from 'styled-components';
import AppState from '@state';

import useRoundNumber from '@hooks/useRoundNumber';

const Svg = styled.svg`
  display: block;
`;

const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const ChartTitleWrapper = styled.div`
    position: absolute;
    bottom: 12px;
    width: 100%;
`;

const ChartTitle = styled.div`
    border: 1px solid ${({ chartColor }) => chartColor || 'var(--active-nav)'};
    color: var(--text-primary);
    max-width: 90px;
    margin: 0 auto;
    border-radius: 50px;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 6px 8px;
`;

const ChartNumberWrapper = styled.div`
    position: absolute;
    top: 62px;
    width: 100%;
`;

const ChartNumber = styled.div`
    color: var(--text-primary);
    margin: 0 auto;
    border-radius: 50px;
    font-size: 2.4rem;
    font-weight: 100;
    opacity: 0.8;
`;

const BaseCircle = styled.circle`
  fill: transparent;
  stroke: var(--chart-ownership-bg);
  stroke-width: ${({ strokeWidth }) => strokeWidth}px;
  stroke-dasharray: ${({ dashArray }) => dashArray};
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
`;

const PercentCircle = styled.circle`
  fill: transparent;
  stroke: url(#grad);
  stroke-width: ${({ strokeWidth }) => strokeWidth}px;
  stroke-dasharray: ${({ dashArray }) => dashArray};
  stroke-dashoffset: ${({ offset }) => offset};
  stroke-linecap: round;
  transition: stroke-dashoffset 0.3s;
`;

function CompositionOwnershipChart() {
  const [chartColor, setChartColor] = useState(null);
  const [chartPercentage, setChartPercentage] = useState(0);
  const [displayPercentage, setDisplayPercentage] = useState(0);
  const strokeWidth = 9;
  const radius = 88;
  const innerRadius = radius - strokeWidth;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * 0.75;
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(135, ${radius}, ${radius})`;
  const offset = arc - (chartPercentage / 100) * arc;

  const refreshSplits = () => {
    const latest = AppState.workspaceAccounts.value.filter((account) => account.isWriter);
    const writerShareTotal = latest.reduce((prev, current) => prev + +(current.share), 0);
    setChartColor(null);
    if (writerShareTotal === 100) {
      setChartColor('#367C14');
      setChartPercentage(useRoundNumber(100, 2));
    } else if (writerShareTotal > 100) {
      setChartPercentage(useRoundNumber(100, 2));
      setChartColor('var(--orange-300)');
    } else {
      setChartPercentage(useRoundNumber(writerShareTotal, 2));
    }
    setDisplayPercentage(useRoundNumber(writerShareTotal, 2));
  };

  useEffect(() => {
    refreshSplits();
  }, [AppState.workspaceAccounts.value]);

  return (
    <ChartWrapper>
      <Svg height={radius * 2} width={radius * 2}>
        <defs>
          <linearGradient id="grad" x1="0" y1="0" x2="1" y2="1">
            <stop offset="15%" stopColor={chartColor || '#764FE6'} stopOpacity="1" />
            <stop offset="85%" stopColor={chartColor || '#825BF2'} stopOpacity="1" />
          </linearGradient>
        </defs>
        <BaseCircle
          className="gauge_base"
          cx={radius}
          cy={radius}
          r={innerRadius}
          strokeWidth={strokeWidth}
          dashArray={dashArray}
          transform={transform}
        />
        <PercentCircle
          className="gauge_percent"
          cx={radius}
          cy={radius}
          r={innerRadius}
          strokeWidth={strokeWidth}
          dashArray={dashArray}
          offset={offset}
          transform={transform}
        />
      </Svg>
      <ChartNumberWrapper>
        <ChartNumber>
          {displayPercentage}
          %
        </ChartNumber>
      </ChartNumberWrapper>
      <ChartTitleWrapper>
        <ChartTitle chartColor={chartColor}>Ownership</ChartTitle>
      </ChartTitleWrapper>
    </ChartWrapper>
  );
}

export default CompositionOwnershipChart;
