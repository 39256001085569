import { route } from 'preact-router';
import { useState, useEffect, useLayoutEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import anime from 'animejs/lib/anime.es';
import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import loadOrgLists from '@actions/loadOrgLists';
import { getOrgComposition } from '@api/restricted/org-composition-api';

import CorePublisherLayout from '@layouts/corePublisher';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Spinner from '@ui-kit/loaders/Spinner';
import Icon from '@ui-kit/icon';
import LeftIcon from '@assets/icons/arrow-left.svg';
import ToolIcon from '@assets/icons/open-panel-outline.svg';
import TagIcon from '@assets/icons/tag-outline.svg';
import HoldIcon from '@assets/icons/alert-callout.svg';
import CommentIcon from '@assets/icons/doc-note.svg';

import SongwriterProfileOverlay from '@shared-overlays/songwriterProfileOverlay';

import useErrorOverlay from '@hooks/useErrorOverlay';
import usePollForData from '@hooks/usePollForData';

import ViewCompositionHeader from './sections/viewCompositionHeader';
import ViewCompositionWriters from './sections/viewCompositionWriters';
// import ViewCompositionOwners from './sections/viewCompositionOwners'; // Waiting on validation for value of this in short term.
import ViewCompositionRecordings from './sections/viewCompositionRecordings';
import ViewCompositionLegal from './sections/viewCompositionLegal';
import ViewCompositionLyrics from './sections/viewCompositionLyrics';
import ViewCompositionTools from './sections/viewCompositionTools';
import ViewCompositionSamples from './sections/viewCompositionSamples';

import {
  CompositionSectionsGrid,
  CompositionsToolsGrid,
  ToolsToggleWrapper,
  ToolsStatusViewRow,
  StyledStatusIndicator,
} from './PublisherViewCompositionStyles';

function PublisherViewComposition({ uuid, compositionUUID }) {
  const [isLoading, setIsLoading] = useState(true);
  const [composition, setComposition] = useState();
  const [internalData, setInternalData] = useState();
  const [selectedWriter, setSelectedWriter] = useState(false);
  const [showToolPanel, setShowToolPanel] = useState(false);

  const holdCount = (internalData?.holds?.registration?.isActive ? 1 : 0) + (internalData?.holds?.firstUse?.isActive ? 1 : 0);

  const animation = anime.timeline({
    autoplay: false,
  });

  const loadComposition = async () => {
    try {
      const response = await getOrgComposition(AppState.pubOrganization.id.value, compositionUUID);
      if (response.status === 200) {
        const json = await response.json();

        const formattedComposition = {
          ...json.composition,
          uuid: compositionUUID,
          writers: json.composition.writers.map(({
            id, imgURL, societies, pubPreset, ...rest
          }) => ({
            id,
            accountId: id,
            avatarURL: imgURL,
            societies: societies.join(', '),
            pubPreset: pubPreset?.name ? {
              id: pubPreset.id,
              name: pubPreset.name,
              isDefault: pubPreset.isDefault,
              owners: pubPreset.publishers.filter((pub) => pub.share > 0).map(({ territory, share, ...pubRest }) => ({ ownedPercentage: share, ...pubRest })),
              admins: pubPreset.publishers.filter((pub) => pub.territory).map(({ share, ...pubRest }) => pubRest),
            } : null,
            ...rest,
          })),
        };
        setComposition(formattedComposition);

        const formattedInternalData = {
          ...json.internalData,
          comments: json.internalData.comments.map(({ accountId, imgURL, ...rest }) => ({
            accountId,
            avatarURL: imgURL,
            ...rest,
          })),
        };
        setInternalData(formattedInternalData);
      } else {
        try {
          const json = await response.json();
          useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading composition` });
        } catch {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading composition` });
        }
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading composition` });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setSelectedWriter(null);
    setShowToolPanel(false);
    AppState.media.currentlyPlaying.value = null;
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadOrgLists(AppState.pubOrganization.id.value, { forcedLoad: false }).then(() => {
        loadComposition().then(() => {
          setTimeout(() => {
            setIsLoading(false);
            setTimeout(() => {
              animation.add({
                targets: '#top, #left, #right',
                translateY: ['32px', 0],
                opacity: [0, 1],
                duration: 600,
                direction: 'normal',
                delay: anime.stagger(100),
              });
              animation.play();
            }, 200);
          }, 1000);
        });
      });
    });
  }, [uuid, compositionUUID]);

  useLayoutEffect(() => {
    const divElement = document.getElementById('app');
    if (window.innerWidth < 780 && divElement && showToolPanel) {
      divElement.style.overflow = 'hidden';
    } else {
      divElement.style.overflow = 'auto';
    }
  }, [showToolPanel]);

  const onClickControlledWriter = (writer) => {
    setSelectedWriter(writer);
  };

  const pollForData = () => {
    loadComposition();
  };

  usePollForData(!isLoading, 5000, pollForData, false);

  return (
    <CorePublisherLayout>
      {isLoading
        ? (
          <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
            <Spinner size="2em" variant="page" />
          </Box>
        )
        : (
          <CompositionsToolsGrid id="the-grid">
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" className="page-back-wrapper">
                <Box
                  display="flex"
                  alignItems="center"
                  className="cursor-p"
                  onClick={() => {
                    if (AppState.navigation.backActionPath.value?.includes('/lists/')) {
                      route(AppState.navigation.backActionPath.value);
                    } else {
                      route(`/publisher/${uuid}/compositions`);
                    }
                  }}
                >
                  <Icon size="1.125em" mr="0.5em" cursor><LeftIcon /></Icon>
                  <Text color="var(--text-soft-mute)" fontSize="0.813rem" fontWeight="600">
                    {AppState.navigation.backActionPath.value?.includes('/lists/') ? 'Back to List' : 'All Compositions'}
                  </Text>
                </Box>
                <ToolsStatusViewRow onClick={() => setShowToolPanel(!showToolPanel)}>
                  <Box display="flex" alignItems="center" mr="1.5em">
                    <Text mr="0.75em">Internal Status:</Text>
                    <StyledStatusIndicator statusColor={internalData.status?.color}>
                      {internalData.status?.name || 'None'}
                    </StyledStatusIndicator>
                  </Box>
                  <Box display="flex" alignItems="center" mr="1.5em">
                    <Icon size="1.125em" mr="0.5em" pt="2px" cursor color={holdCount > 0 ? 'var(--alert-negative-segment)' : ''}><HoldIcon /></Icon>
                    <Text mr="0.5em">Holds:</Text>
                    <Text>{holdCount}</Text>
                  </Box>
                  <Box display="flex" alignItems="center" mr="1.5em">
                    <Icon size="1.125em" mr="0.5em" pt="2px" cursor><TagIcon /></Icon>
                    <Text mr="0.5em">Tags:</Text>
                    <Text>{internalData.tags.length}</Text>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Icon size="1.125em" mr="0.5em" pt="2px" cursor><CommentIcon /></Icon>
                    <Text mr="0.5em">Comments:</Text>
                    <Text>{internalData.comments.length}</Text>
                  </Box>
                </ToolsStatusViewRow>
                <ToolsToggleWrapper onClick={() => setShowToolPanel(!showToolPanel)}>
                  <Icon size="1.125em" mr="0.5em" pt="2px" cursor><ToolIcon /></Icon>
                  <Text color="var(--text-soft-mute)" fontSize="0.813rem" fontWeight="600">Show Tools</Text>
                </ToolsToggleWrapper>
              </Box>

              <Box id="top" style={{ opacity: 0 }}>
                <ViewCompositionHeader composition={composition} />
              </Box>

              <CompositionSectionsGrid id="composition-sections-grid">

                <Box width="100%" id="left" style={{ opacity: 0 }}>
                  <ViewCompositionWriters handleSelectedWriter={onClickControlledWriter} writers={composition.writers} />
                </Box>

                <Box width="100%" id="right" style={{ opacity: 0 }}>
                  <ViewCompositionRecordings recordings={composition.recordings} compositionUUID={compositionUUID} />
                  <ViewCompositionLegal composition={composition} />
                  <ViewCompositionSamples composition={composition} />
                  <ViewCompositionLyrics lyrics={composition.lyrics.text} isInstrumental={composition.lyrics.instrumental} />
                </Box>

              </CompositionSectionsGrid>

            </Box>
            <Box mt="2.5em">
              <ViewCompositionTools
                compositionId={composition.id}
                internalData={internalData}
                loadInternalData={loadComposition}
                showToolPanel={showToolPanel}
                setShowToolsPanel={setShowToolPanel}
                composition={composition}
              />
            </Box>
          </CompositionsToolsGrid>
        )}

      {selectedWriter && (
        <SongwriterProfileOverlay
          closeFunction={() => setSelectedWriter(null)}
          writer={selectedWriter}
        />
      )}
    </CorePublisherLayout>
  );
}

PublisherViewComposition.propTypes = {
  uuid: PropTypes.string.isRequired,
  compositionUUID: PropTypes.string.isRequired,
};

export default PublisherViewComposition;
