/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useState, useEffect, useRef, useLayoutEffect,
} from 'preact/hooks';
import { useForm } from 'react-hook-form';
import AppState from '@state';
import WaveSurfer from 'wavesurfer.js';

// import { putWorkspace } from '@api/restricted/workspace-api';
// import loadComposition from '@actions/loadComposition';

// TEMPORARY TO BUILD SOME FEATURES - LIKELY A DIFFERENT ENDPOINT
import { getWorkspaceRecordingFile } from '@api/restricted/workspace-recording-api';

import useTruncateText from '@hooks/useTruncateText';
import useResponsiveText from '@hooks/useResponsiveText';
import { useTimeAgo } from '@hooks/useTimeAgo';

import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Span from '@ui-kit/typography/span';
import Text from '@ui-kit/typography/text';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import LockPrompt from '@distinct-components/feedback/lockPrompt';
import Card from '@distinct-components/cards/baseCard';
import ItemListInput from '@ui-kit/inputs/itemListInput/ItemListInput';
import FileArtworkImage from '@distinct-components/files/fileArtworkImage';
import Icon from '@ui-kit/icon';
import NoteIcon from '@assets/icons/single-note-square.svg';
import EditIcon from '@assets/icons/edit-line.svg';
import LabelIcon from '@assets/icons/label-connection-circle.svg';
import LinkButton from '@ui-kit/buttons/linkButton';
import ContentCredentialPopover from '@distinct-components/dataDisplay/contentCredentialPopover';

import WorkspaceEditOverlay from '@layouts/full-screen/workspaceEditOverlay';

import {
  StyledTitleRow,
  StyledTrackNumberWrapper,
  StyledWorkspaceTitle,
  InputLockWrapper,
  StyledPlayerContentRow,
  StyledTrackInfoWrapper,
  StyledWaveAreaWrapper,
  StyledWaveFormWrapper,
  StyledFinalFileChip,
  StyledActionIconWrapper,
  StyledContentCredentialWrapper,
  StyledAdminActionsRow,
  StyledNoFileWrapper,
} from './RecordingFileSectionStyles';

function RecordingFileSection({ recordingData, activeUser }) {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [fileDataError, setFileDataError] = useState(null);
  const [isLoadingFileData, setIsLoadingFileData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alternateTitles, setAlternateTitles] = useState(AppState.composition.alternateTitles.value);
  const [fileDuration, setFileDuration] = useState(null);
  const [currentFile, setCurrentFile] = useState({});

  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);

  const tt = useTruncateText;
  const rt = useResponsiveText;

  const {
    register, handleSubmit, reset, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const getCurrentFileDetails = () => {
    const currentFileData = recordingData.files.find((file) => file.version === recordingData.fileVersion);
    setCurrentFile(currentFileData);
  };

  useEffect(() => {
    // KEEPING LINTER HAPPY
    console.log(activeUser);
    console.log(fileDataError);
    console.log(isLoadingFileData);
    getCurrentFileDetails();

    const MOCK_FILE_ID = 19;
    const MOCK_UUID = 'w8VPeofP202uKnRLLXWGL17Xgf8';

    setFileDataError(null);
    if (waveSurferRef.current) { waveSurferRef.current.destroy(); }
    setIsLoadingFileData(true);
    getWorkspaceRecordingFile(MOCK_UUID, MOCK_FILE_ID)
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            setTimeout(() => {
              if (waveformRef.current) {
                waveSurferRef.current = WaveSurfer.create({
                  container: waveformRef.current,
                  waveColor: '#9E82EC',
                  progressColor: '#6740D8',
                  height: 132,
                  barWidth: 4,
                  barHeight: 0.7,
                  barGap: 2,
                  responsive: true,
                  partialRender: true,
                });
                waveSurferRef.current.load(url);

                waveSurferRef.current.on('ready', () => {
                  setIsLoadingFileData(false);
                  const duration = waveSurferRef.current.getDuration();
                  setFileDuration(duration);
                  console.log('WaveSurfer is ready');
                  AppState.media.isPlaying.value = true;
                });

                waveSurferRef.current.on('interaction', () => {
                  const currentTime = waveSurferRef.current.getCurrentTime();
                  AppState.media.workspaceElementSeekTime.value = currentTime;
                });
              }
            }, 200);
          });
        } else {
          response.json()
            .then((json) => setFileDataError(json.error || response.statusText))
            .catch(() => setFileDataError(response.statusText));
          setIsLoadingFileData(false);
        }
      })
      .catch((err) => {
        setFileDataError(err.message);
        setIsLoadingFileData(false);
      });
  }, []);

  useLayoutEffect(() => {
    if (waveSurferRef.current && AppState.media.currentTime.value > 0 && fileDuration) {
      waveSurferRef.current.seekTo(AppState.media.currentTime.value / fileDuration);
      if (AppState.media.currentTime.value === fileDuration) {
        waveSurferRef.current.seekTo(0);
      }
    }
  }, [AppState.media.currentTime.value]);

  // const handleEditSection = () => {
  //   reset({
  //     recordingTitle: AppState.composition.name.value,
  //   });
  //   setAlternateTitles(recordingData.alternateTitles);
  //   setIsEditing(!isEditing);
  //   AppState.workspace.isEditingChild.value = true;
  // };

  const handleUpdateAlternateTitles = (titles) => {
    setAlternateTitles(titles);
  };

  const handleCancelEdit = () => {
    reset({
      recordingTitle: AppState.composition.name.value,
    });
    setAlternateTitles(recordingData.alternateTitles);
    setIsEditing(!isEditing);
    AppState.workspace.isEditingChild.value = false;
  };

  const handleSaveDetails = (data) => {
    setError('');

    // const cleanedAlternateTitles = alternateTitles.map((title) => title.trim()).filter((title) => title);

    const cleanedData = {
      recordingTitle: data.recordingTitle?.trim(),
    };

    if (!cleanedData.recordingTitle) {
      setError('Invalid Recording Title');
    } else {
      setIsLoading(true);
      // putWorkspace(workspaceId, tagIds, cleanedAlternateTitles, data.intendedUse, utcDate, cleanedData, AppState.composition.hash.value)
      //   .then((response) => {
      //     setIsLoading(false);
      //     if (response.status === 200 || response.status === 202) {
      //       loadComposition(workspaceId);
      //       setEditMode(!isEditing);
      //       setError('');
      //       AppState.workspace.isEditingChild.value = false;
      //     } else {
      //       response.json().then((json) => {
      //         setError(json.error ? json.error : response.statusText);
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //     setError(err.message);
      //   });
      // MOCK TIME
      setTimeout(() => {
        setIsLoading(false);
        setIsEditing(!isEditing);
        setError('');
      }, 1400);
    }
  };

  const onClickPlayRecording = () => {
    // MOCK VALUES JUST TO MAKE SURE THE TEXT AND IMAGES PASS CORRECTLY
    const MOCK_REC_OBJECT = {
      id: 19,
      name: 'How_Do_You_Find_Demo_PBMix.mp3',
      fileName: 'How_Do_You_Find_Demo_PBMix.mp3',
      type: 'audio/mpeg',
      size: 3113776,
      recordingType: 'Demo',
      isPrivate: false,
      isProcessing: false,
    };
    // for the sake of testing, I'm apply a composition uuid to appState
    AppState.composition.uuid.value = null;
    AppState.composition.uuid.value = 'w8VPeofP202uKnRLLXWGL17Xgf8';
    // END OF MOCK VALUES
    if (AppState.media.currentlyPlaying.value?.id === MOCK_REC_OBJECT.id && AppState.media.isPlaying.value === true) {
      AppState.media.currentlyPlaying.value = null;
      AppState.media.isPlaying.value = false;
      AppState.media.recordingName.value = null;
      AppState.media.recordingProjectName.value = null;
      AppState.media.recordingProjectArtistName.value = null;
    } else {
      AppState.media.artworkURL.value = recordingData.projectArtworkURL || null;
      AppState.media.recordingName.value = recordingData.recordingTitle;
      AppState.media.recordingProjectName.value = recordingData.projectName;
      AppState.media.recordingProjectArtistName.value = recordingData.projectDisplayArtist || 'Undefined Artist';
      AppState.media.currentlyPlaying.value = MOCK_REC_OBJECT;
    }
  };

  const onClickNoFileCard = () => {
    if (activeUser.isAdmin) {
      alert('manage file overlay to edit or add new file');
    }
  };

  return (
    <Box width="100%">

      <Card mb="1.5em" p="1rem" minHeight="9em" variant="overflow" fluid>

        <StyledTitleRow>
          <Box display="flex" alignItems="center">
            <StyledTrackNumberWrapper>8</StyledTrackNumberWrapper>
            <Box display="flex" alignItems="center">
              <StyledWorkspaceTitle>
                {recordingData.recordingTitle}
                {recordingData.isFinalFile && <StyledFinalFileChip>{rt({ text: 'Final File', mobileText: 'Final' })}</StyledFinalFileChip>}
              </StyledWorkspaceTitle>
            </Box>
          </Box>

          <StyledActionIconWrapper onClick={() => setIsEditing(!isEditing)}>
            <Icon size="0.875rem" pt="2px" color="var(--text-secondary)" cursor><EditIcon /></Icon>
          </StyledActionIconWrapper>
        </StyledTitleRow>

        {currentFile ? (
          <StyledPlayerContentRow>
            <StyledContentCredentialWrapper>
              <ContentCredentialPopover />
            </StyledContentCredentialWrapper>
            <StyledTrackInfoWrapper>
              <FileArtworkImage
                height="86px"
                width="86px"
                fileId={recordingData.id}
                handleFilePlay={onClickPlayRecording}
                artworkURL={recordingData.projectArtworkURL}
                isFileAvailable={recordingData.fileVersion > 0}
                isLoading={isLoadingFileData}
                toggleAction="stop"
              />
            </StyledTrackInfoWrapper>
            <Box width="100%">
              {currentFile && currentFile.fileName
            && (
            <Text color="var(--text-secondary)" fontSize="0.813rem" lineHeight="1">
              {tt(currentFile.fileName, { sm: 18, md: 40, lg: 60 }, { keepExtension: true })}
            </Text>
            )}
              <StyledWaveAreaWrapper>
                <StyledWaveFormWrapper>
                  <div ref={waveformRef} />
                </StyledWaveFormWrapper>
              </StyledWaveAreaWrapper>
            </Box>
          </StyledPlayerContentRow>
        ) : (
          <StyledNoFileWrapper isClickable={activeUser.isAdmin} onClick={onClickNoFileCard}>
            <Icon size="1.675rem" color="var(--text-muted)" cursor><NoteIcon /></Icon>
            <Text color="var(--text-muted)" mt="0.25rem">{activeUser.isAdmin ? 'Add the First Audio File' : 'No Audio File Added Yet'}</Text>
          </StyledNoFileWrapper>
        )}

        <StyledAdminActionsRow>

          <Box display="flex" className="cursor-p" alignItems="center" onClick={() => alert('overlay to edit label config')}>
            <Box mr="0.5rem">
              <Icon size="1rem" pt="4px" color={recordingData.connectedLabel ? 'var(--color-blue)' : 'var(--text-secondary)'} cursor><LabelIcon /></Icon>
            </Box>
            <Text
              color="var(--text-secondary)"
              fontSize="0.813rem"
              variant="selectDisabled"
            >
              {recordingData.connectedLabel ? recordingData.connectedLabel.name : 'No Label Defined'}
            </Text>
          </Box>

          <Box display="flex" alignItems="center">
            {activeUser.isAdmin
              && <LinkButton btnText="Manage File" fontSize="0.813rem" color="var(--text-secondary)" onClick={() => alert('Overlay to Add Notes or Mark Final')} />}
            <Text ml="0.875rem" fontSize="0.813rem" lineHeight="1" color="var(--text-secondary)">
              Updated
              {' '}
              {useTimeAgo(recordingData.updatedAt)}
            </Text>
          </Box>

        </StyledAdminActionsRow>
      </Card>

      {isEditing
      && (
      <WorkspaceEditOverlay
        closeFunction={handleCancelEdit}
      >
        <Text fontSize="1.5em" fontFamily="var(--font-secondary)" fontWeight="800">Recording Details</Text>
        <Box mb="1.5em">
          <Span color="var(--text-secondary)">id:</Span>
          <Span color="var(--text-secondary)" fontSize="0.813rem">{recordingData.uuid}</Span>
        </Box>
        <form id="editRecordingForm" onSubmit={handleSubmit(handleSaveDetails)} autoComplete="on">
          <InputLabel label="Recording Title" />
          <Box style={{ position: 'relative' }}>
            <BaseInput
              {...register('recordingTitle', { required: true })}
              id="recordingTitle"
              name="recordingTitle"
              placeholder="Name of Recording / Track"
              type="text"
              disabled={recordingData.isLocked}
              fluid
              pr={recordingData.isLocked ? '2.5em' : '1.125em'}
              mb="1.5em"
            />
            {recordingData.isLocked
            && (
            <InputLockWrapper>
              <LockPrompt lockPrompt="This file has been marked released by the label. Contact label for further changes." />
            </InputLockWrapper>
            )}
          </Box>
          <InputLabel label="Alternate Titles" />
          <ItemListInput
            mb="2.5em"
            placeholder="Alternate Title..."
            handleListChange={handleUpdateAlternateTitles}
            listItems={alternateTitles}
            itemLimit={10}
          />

          {error
          && (
          <AlertMessage
            variant="negative"
            message={error}
            mb="1.5em"
          />
          )}

          <BaseButton
            mb="1.5em"
            type="submit"
            btnText="Save Changes"
            disabled={!isValid}
            fluid
            isLoading={isLoading}
          />
        </form>
      </WorkspaceEditOverlay>
      )}

    </Box>
  );
}

RecordingFileSection.propTypes = {
  recordingData: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default RecordingFileSection;
