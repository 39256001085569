/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useRef, useLayoutEffect } from 'preact/hooks';

import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import Text from '@ui-kit/typography/text';
import SearchInput from '@ui-kit/inputs/searchInput';
import ClearIcon from '@assets/icons/close-solo.svg';

import { TableSearchWrapper, ClearSearchChip } from './TableControlsStyles';

function TableControls({
  showSearch,
  searchActive,
  handleSearch,
  searchResults,
  tableData,
  currentResults,
  itemName,
  indexOfFirst,
  indexOfLast,
  searchPlaceholder,
}) {
  const searchRef = useRef();

  useLayoutEffect(() => {
    if (showSearch && !searchActive) {
      searchRef.current.base.value = '';
    }
  }, [searchActive]);

  const handleClearSearch = () => {
    handleSearch('');
  };

  return (
    <Box mt="1.5em" mb="1.25em" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
      {!searchActive
        ? (
          <Box>
            {tableData.length > 10
              ? (
                <Text color="var(--text-secondary)">
                  Viewing
                  {' '}
                  {indexOfFirst + 1}
                  -
                  {currentResults.length === 10 ? indexOfLast : (indexOfFirst + currentResults.length)}
                  {' '}
                  of
                  {' '}
                  {tableData.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  {' '}
                  {itemName}
                  s
                </Text>
              )
              : (
                <Text color="var(--text-secondary)">
                  {tableData.length}
                  {' '}
                  {itemName}
                  {tableData.length > 1 && 's' }
                </Text>
              )}
          </Box>
        )
        : (
          <Box>
            {searchResults.length > 10
              ? (
                <Text color="var(--text-secondary)">
                  Viewing
                  {' '}
                  {indexOfFirst + 1}
                  -
                  {currentResults.length === 10 ? indexOfLast : (indexOfFirst + currentResults.length)}
                  {' '}
                  of
                  {' '}
                  {searchResults.length}
                  {' '}
                  {itemName}
                  s
                  {' '}
                  in Your Search
                </Text>
              )
              : (
                <Text color="var(--text-secondary)">
                  {searchResults.length}
                  {' '}
                  {itemName}
                  {searchResults.length > 1 && 's' }
                  {' '}
                  in Your Search
                </Text>
              )}
          </Box>
        )}
      <TableSearchWrapper>
        {showSearch
        && (
        <SearchInput
          ref={searchRef}
          id="search"
          name="search"
          placeholder={searchPlaceholder}
          type="text"
          autoComplete="off"
          fluid
          onChange={(e) => handleSearch(e.target.value)}
        />
        )}
        {searchActive
        && (
        <ClearSearchChip onClick={handleClearSearch}>
          <Icon size="0.65em" color="var(--white)" cursor><ClearIcon /></Icon>
        </ClearSearchChip>
        )}
      </TableSearchWrapper>
    </Box>
  );
}

TableControls.propTypes = {
  showSearch: PropTypes.bool,
  searchActive: PropTypes.bool,
  handleSearch: PropTypes.func,
  tableData: PropTypes.array.isRequired,
  currentResults: PropTypes.array.isRequired,
  searchResults: PropTypes.array,
  itemName: PropTypes.string.isRequired,
  indexOfFirst: PropTypes.number.isRequired,
  indexOfLast: PropTypes.number.isRequired,
  searchPlaceholder: PropTypes.string,
};

TableControls.defaultProps = {
  showSearch: false,
  searchActive: false,
  handleSearch: null,
  searchResults: [],
  searchPlaceholder: 'Search by name...',
};

export default TableControls;
