import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const TooltipWrapper = styled.div`
  display: block;
  position: relative;

  .toolTipText {
    visibility: hidden;
    background-color: ${(props) => (props.overPanel === false ? 'var(--tool-tip-bg)' : 'var(--tool-tip-bg-lighter)')};
    color: var(--text-secondary);
    text-align: left;
    position: absolute;
    z-index: 100;
    width: ${(props) => props.width};
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    left: ${(props) => props.left};
    right: ${(props) => props.right};
    font-size: 0.75rem;
    line-height: 1.6;
    padding: 0.75rem;
    border-radius: 1em;
    border: 1px solid var(--tool-tip-border);
    box-shadow: 2px 4px 11px var(--background);
    font-weight: 500;
  }
  
  &:hover .toolTipText {
    visibility: visible;
  }

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const MobileTooltipWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${pointBreaks.sm}) {
    display: block;
  }
`;
