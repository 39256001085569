/* eslint-disable object-curly-newline */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import { getOrgAccounts, deleteOrgAccount } from '@api/restricted/org-accounts-api';
import { getOrgInvites } from '@api/restricted/org-invite-api';

import useErrorOverlay from '@hooks/useErrorOverlay';
import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Avatar from '@ui-kit/avatar';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Icon from '@ui-kit/icon';
import TrashIcon from '@assets/icons/trash-outline.svg';

import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import { TableIllustrationWrapper, TableSearchEmptyPrompt } from './PublisherTeamStyles';

import InviteTeamMember from './sections/inviteTeamMember';
import PendingTeamInviteBar from './sections/pendingTeamInviteBar';

function PublisherTeam({ uuid }) {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [allTeamMembers, setAllTeamMembers] = useState([]);
  const [searchedTeamMembers, setSearchedTeamMembers] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.organizationTeam.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isInvitingMember, setIsInvitingMember] = useState(false);
  const [teamMemberToRemove, setTeamMemberToRemove] = useState();
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [error, setError] = useState();

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const d = useIsoDateFormat;

  const loadTeamMembers = async () => {
    const loadingError = {
      display: true,
      errorTitle: 'Loading Error',
      userMessage: 'Error loading team members',
      buttonText: 'Go Home',
      buttonPath: '/home',
    };
    try {
      const response = await getOrgAccounts(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          loadingError.errorMessage = json.error || response.statusText;
          useErrorOverlay(loadingError);
        } catch {
          loadingError.errorMessage = response.statusText;
          useErrorOverlay(loadingError);
        }
      } else {
        const json = await response.json();
        const formattedMembers = json.org_accounts.map((m) => ({
          ...m,
          avatarURL: m.imgURL,
        }));
        setAllTeamMembers(formattedMembers);
        setSearchedTeamMembers(formattedMembers);
        setSearchActive(false);
      }
    } catch (err) {
      loadingError.errorMessage = err.message;
      useErrorOverlay(loadingError);
    }
  };

  const loadPendingInvites = async () => {
    const loadingError = {
      display: true,
      errorTitle: 'Loading Error',
      userMessage: 'Error loading pending invitations',
      buttonText: 'Go Home',
      buttonPath: '/home',
    };
    try {
      const response = await getOrgInvites(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        try {
          const json = await response.json();
          loadingError.errorMessage = json.error || response.statusText;
          useErrorOverlay(loadingError);
        } catch {
          loadingError.errorMessage = response.statusText;
          useErrorOverlay(loadingError);
        }
      } else {
        const json = await response.json();
        setPendingInvitations(json.invites);
      }
    } catch (err) {
      loadingError.errorMessage = err.message;
      useErrorOverlay(loadingError);
    }
  };

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadTeamMembers().then(() => {
        loadPendingInvites().then(() => {
          setIsLoadingPage(false);
        });
      });
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = searchedTeamMembers.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      allTeamMembers.forEach((member) => {
        const name = `${member.firstName} ${member.lastName}`;
        if (name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(member);
        }
      });
      setSearchedTeamMembers(results);
      setSearchActive(true);
    } else {
      setSearchedTeamMembers(allTeamMembers);
      setSearchActive(false);
    }
  };

  const addNew = () => {
    setIsInvitingMember(true);
  };

  const onClickRemoveTeamMember = (user) => {
    setTeamMemberToRemove(user);
  };

  const handleRemoveTeamMember = () => {
    setIsRemovingMember(true);
    deleteOrgAccount(AppState.pubOrganization.id.value, teamMemberToRemove.id)
      .then((response) => {
        if (response.status === 200) {
          loadTeamMembers().then(() => {
            setTimeout(() => {
              setTeamMemberToRemove(null);
              setError(null);
              setIsRemovingMember(false);
            }, 2000);
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsRemovingMember(false);
        }
      })
      .catch((err) => {
        setIsRemovingMember(false);
        setError(err.message);
      });
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Team Members"
        primaryAction={{ method: addNew, text: '+ User' }}
        isLoadingPage={isLoadingPage}
      >
        <div ref={pageRef} />

        {pendingInvitations.length > 0
        && (
          <PendingTeamInviteBar loadPendingInvites={loadPendingInvites} invitations={pendingInvitations} />
        )}

        <Fragment>
          <TableControls
            itemName="Team Member"
            tableData={allTeamMembers}
            currentResults={currentResults}
            indexOfFirst={indexOfFirst}
            indexOfLast={indexOfLast}
            showSearch
            searchActive={searchActive}
            searchResults={searchedTeamMembers}
            handleSearch={onSearchResults}
          />
          <Table mb="1em">
            <TableHeader>
              <TableCell widthPercent="48%" pr="0.75em">Name</TableCell>
              <TableCell>{' '}</TableCell>
            </TableHeader>
            {currentResults
              .map((u) => (
                <TableRow>
                  <TableCell widthPercent="48%" pr="0.75em">
                    <TableIllustrationWrapper>
                      <Box mr="1em">
                        <Avatar
                          size="3em"
                          imgURL={u.avatarURL}
                        />
                      </Box>
                      <Box pt="0.25rem">
                        <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">{`${u.firstName} ${u.lastName}`}</Text>
                        <Text mt="0.125em" fontSize="0.688rem;" maxWidth="16em" color="var(--text-secondary)" truncate>
                          Added:
                          {' '}
                          {d(u.createdAt)}
                        </Text>
                      </Box>
                    </TableIllustrationWrapper>
                  </TableCell>
                  <TableCell pr="1em">
                    {AppState.accountId.value !== u.id && (
                      <Box display="flex" justifyContent="flex-end">
                        <Icon size="0.875rem" pointerEvents={isRemovingMember ? 'none' : 'auto'} cursor><TrashIcon onClick={() => onClickRemoveTeamMember(u)} /></Icon>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {(currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Team Members Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
          </Table>
          <TablePagination
            itemsPerPage={itemsPerPage}
            totalItems={searchedTeamMembers.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Fragment>
      </PageCard>

      {teamMemberToRemove
        && (
        <DeleteConfirmationOverlay
          headerLabel="Remove?"
          header={`${teamMemberToRemove.firstName} ${teamMemberToRemove.lastName}`}
          explanation="This user will no longer be able to access this organization."
          closeFunction={() => setTeamMemberToRemove(null)}
          handleDelete={handleRemoveTeamMember}
          error={error}
          isLoading={isRemovingMember}
          confirmationPhrase="Remove User"
          buttonText="Confirm and Remove"
        />
        )}
      {isInvitingMember && <InviteTeamMember loadPendingInvites={loadPendingInvites} closeFunction={() => setIsInvitingMember(false)} />}
    </CorePublisherLayout>
  );
}

PublisherTeam.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherTeam;
