/* eslint-disable no-param-reassign */
import { route } from 'preact-router';
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state';

import loadAccountPublishers from '@actions/loadAccountPublishers';
import loadAccountPublisherPresets from '@actions/loadAccountPublisherPresets';
import loadCompositions from '@actions/loadCompositions';

import useIsoDateFormat from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import CoreLayout from '@layouts/core/';
import PageCard from '@layouts/pageCard';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CompositionIcon from '@assets/illustrations/composition-chip.svg';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import { TableIllustrationWrapper, TableSearchEmptyPrompt } from './CompositionsStyles';

function Compositions() {
  const [isLoadingCompositions, setIsLoadingCompositions] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [compositions, setCompositions] = useState([]);
  const [currentPage, setCurrentPage] = useState(AppState.pagination.artistComposition.value);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const d = useIsoDateFormat;

  useEffect(() => {
    document.title = 'Songwriting';
    if (height < 1080) {
      setItemsPerPage(7);
    }
    loadCompositions().then(() => {
      setIsLoadingCompositions(false);
      const comps = AppState.compositions.value;
      setCompositions(comps);
      setSearchActive(false);
      if (!AppState.artistProfile.criticalChecksComplete.value && comps.length > 0) {
        loadAccountPublishers().then(() => {
          loadAccountPublisherPresets().then(() => {
            if (AppState.compositions.value.length > 0) {
              if (AppState.writer.value) {
                if (AppState.artistProfile.swCollSociety.value.length === 0) {
                  AppState.artistProfile.societyCritical.value = true;
                } else {
                  AppState.artistProfile.societyCritical.value = false;
                }
                if (!AppState.artistProfile.ipi.value) {
                  AppState.artistProfile.ipiCritical.value = true;
                } else {
                  AppState.artistProfile.ipiCritical.value = false;
                }
                if (AppState.publisherPresets.value.filter((preset) => preset.isDefault).length === 0) {
                  AppState.artistProfile.publisherCritical.value = true;
                } else {
                  AppState.artistProfile.publisherCritical.value = false;
                }
              }
              AppState.artistProfile.criticalChecksComplete.value = true;
            }
          });
        });
      }
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = compositions.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    setCurrentPage(1);
    if (query) {
      const results = [];
      AppState.compositions.value.forEach((composition) => {
        if (composition.compositionName.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(composition);
        }
      });
      setCompositions(results);
      setSearchActive(true);
    } else {
      const comps = AppState.compositions.value;
      setCompositions(comps);
      setSearchActive(false);
    }
  };

  const addNew = () => {
    route('/compositions/create');
  };

  const onClickViewCompositionWorkspace = (id, name) => {
    route(`/workspace/${id}`);
    AppState.composition.name.value = name;
    AppState.navigation.backActionPath.value = '/compositions';
    AppState.pagination.artistComposition.value = currentPage;
  };

  return (
    <CoreLayout>
      <PageCard
        title="Songwriting"
        subTitle="Document the songs you write with verifiable copyright data"
        primaryAction={{ method: addNew, text: '+ New' }}
        isLoadingPage={isLoadingCompositions}
      >
        <div ref={pageRef} />
        {AppState.compositions.value.length === 0
          ? (
            <Card mt="1.5em">
              <BaseEmptyState
                title="No Compositions"
                message={COMPOSITION_EXPLANATIONS.list.emptyState}
                action={addNew}
                actionText="Create Composition"
                illustration="composition"
                minHeight="38em"
              />
            </Card>
          )
          : (
            <Fragment>
              <TableControls
                itemName="Composition"
                tableData={AppState.compositions.value}
                currentResults={currentResults}
                indexOfFirst={indexOfFirst}
                indexOfLast={indexOfLast}
                showSearch
                searchActive={searchActive}
                searchResults={compositions}
                handleSearch={onSearchResults}
              />
              <Table mb="1em">
                <TableHeader>
                  <TableCell widthPercent="48%" pr="0.75em">Name</TableCell>
                  <TableCell>Date Written</TableCell>
                  <TableCell>Contract</TableCell>
                </TableHeader>
                {currentResults
                  .map((composition) => (
                    <TableRow onClick={() => { onClickViewCompositionWorkspace(composition.id, composition.compositionName); }} cursor>
                      <TableCell widthPercent="48%" pr="0.75em">
                        <TableIllustrationWrapper>
                          <Icon className="icon" size="3.25em" pt="2px" mr="1em"><CompositionIcon /></Icon>
                          <Box pt="0.25rem">
                            <Text fontSize="0.938rem" color="var(--text-primary)" fontWeight="600" lineHeight="1.2">{composition.compositionName}</Text>
                            <Text mt="0.125em" fontSize="0.688rem;" maxWidth="16em" truncate color="var(--text-secondary)">
                              Writers:
                              {' '}
                              {composition.writerCount}
                            </Text>
                          </Box>
                        </TableIllustrationWrapper>
                      </TableCell>
                      <TableCell mobileLabel="Date Written:">{d(composition.writtenDate)}</TableCell>
                      <TableCell
                        mobileLabel="Contract Status:"
                        color={
                          (composition.contractStatus === 'Signed' && 'var(--green-300)')
                          || (composition.contractStatus === 'Not Created' && 'var(--text-secondary)')
                          || (composition.contractStatus === 'Amending' && 'var(--yellow-300)')
                          || (composition.contractStatus === 'Rejected' && 'var(--red-300)')
                          || (composition.contractStatus === 'Awaiting Signatures' && 'var(--blue-500)')
                          || (composition.contractStatus === 'N/A' && 'var(--text-medium-mute)')
                        }
                      >
                        {composition.contractStatus}
                      </TableCell>
                    </TableRow>
                  ))}
                {(currentResults.length === 0)
              && (
                <TableSearchEmptyPrompt>
                  <Text color="var(--text-muted-panel)">No Compositions Match Your Search</Text>
                </TableSearchEmptyPrompt>
              )}
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                totalItems={compositions.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </Fragment>
          )}

      </PageCard>
    </CoreLayout>
  );
}

export default Compositions;
