import styled from 'styled-components';
import { space } from 'styled-system';

// eslint-disable-next-line import/prefer-default-export
export const UserCardWrapper = styled.div`
    display: flex;
    border: 1px solid ${(props) => (props.selected ? 'var(--active-nav)' : 'var(--border)')};
    border-radius: 0.5rem;
    align-items: center;
    padding: 8px 10px;
    margin-bottom: 0.75rem;
    cursor: pointer;
    ${space};
`;
