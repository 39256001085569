/* eslint-disable react/no-unescaped-entities */
import { route } from 'preact-router';
import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import AppState from '@state/AppState';
import dayjs from 'dayjs';

import loadCompositions from '@actions/loadCompositions';
import loadAccountProfile from '@actions/loadAccountProfile';
import loadWorkspaceAccounts from '@actions/loadWorkspaceAccounts';

import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import Header from '@ui-kit/typography/header';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import TagTypeAhead from '@distinct-components/typeAheadInputs/tagTypeAhead';

import { postWorkspace } from '@api/restricted/workspace-api';
import { putWorkspaceAccountMetadata, patchWorkspaceAccountAlias } from '@api/restricted/workspace-account-api';

import {
  StyledPercentBox,
  StyledPercentageInput,
} from './CreateCompositionStyles';

const d = dayjs().format();
const TODAY_DATE = d.substring(0, 10);

function CreateComposition() {
  const [section, setSection] = useState('compositionDetail');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [error, setError] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(0);
  const [tags, setTags] = useState([]);

  const {
    register, handleSubmit, formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      compositionDate: TODAY_DATE,
    },
  });

  const {
    register: register2,
    formState: { isValid: isValid2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    document.title = 'New Composition';
    if (!AppState.profileLoaded.value) {
      loadAccountProfile().then(() => {
        setIsLoadingProfile(false);
      });
    } else {
      setIsLoadingProfile(false);
    }
  }, []);

  const handleSaveCompositionDetail = async (data) => {
    setError('');
    const selectedDate = data.compositionDate;
    const utcDate = dayjs(selectedDate).format();

    const tagIds = tags.map((tag) => tag.id);

    const cleanedData = {
      compositionName: data.compositionName.trim(),
      workspaceType: 'composition',
    };

    if (!cleanedData.compositionName) {
      setError('Invalid composition name');
    } else {
      setIsLoading(true);
      try {
        const isWriter = true;
        const response = await postWorkspace(cleanedData, tagIds, 'General Usage', utcDate, isWriter);

        if (response.status === 200 || response.status === 202) {
          response.json().then(({ id }) => {
            loadCompositions().then(() => {
              loadWorkspaceAccounts(id).then(() => {
                setWorkspaceId(id);
                setIsLoading(false);
                setSection('writerDetail');
              });
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleSaveWriterDetail = (data) => {
    const { persona, ...metadata } = data;
    if (!metadata.contribution) {
      metadata.contribution = '';
    }
    if (!metadata.share) {
      metadata.share = 0;
    }
    setIsLoading(true);
    putWorkspaceAccountMetadata(
      workspaceId,
      {
        id: AppState.accountId.value,
        hasJoined: true,
      },
      metadata,
      AppState.workspaceAccounts.value[0].hash,
    ).then((response) => {
      if (response.status === 200) {
        if (persona) {
          patchWorkspaceAccountAlias(
            workspaceId,
            persona,
          ).then((response2) => {
            setIsLoading(false);
            if (response2.status === 200) {
              route(`/workspace/${workspaceId}`);
              AppState.navigation.backActionPath.value = '/compositions';
            } else {
              response2.json()
                .then((json2) => setError(json2.error || response2.statusText))
                .catch(() => setError(response2.statusText));
            }
          }).catch((err2) => {
            setIsLoading(false);
            setError(err2.message);
          });
        } else {
          setIsLoading(false);
          route(`/workspace/${workspaceId}`);
          AppState.navigation.backActionPath.value = '/compositions';
        }
      } else {
        response.json()
          .then((json) => setError(json.error || response.statusText))
          .catch(() => setError(response.statusText));
      }
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    });
  };

  const handleBack = () => {
    route('/compositions');
  };

  return (
    <FullScreenModal formWidth="22em" closeFunction={(section === 'invitations' || section === 'writerDetail') ? null : handleBack}>
      {
        {
          compositionDetail:
  <Fragment>
    <Header mb="1em">Create Composition</Header>
    <form id="createCompositionForm" onSubmit={handleSubmit(handleSaveCompositionDetail)} autoComplete="off">
      <InputLabel label="Composition Name" />
      <BaseInput
        {...register('compositionName', { required: true })}
        id="compositionName"
        name="compositionName"
        placeholder="Name of the song written"
        type="text"
        fluid
        mb="1.5em"
      />
      <TagTypeAhead
        InputLabel="How it sounds or may sound in the future (optional)"
        selectedTags={[]}
        handleChange={(data) => setTags(data)}
      />
      <InputLabel label="Date Written" />
      <BaseInput
        {...register('compositionDate', { required: true })}
        id="compositionDate"
        name="compositionDate"
        type="date"
        max={TODAY_DATE}
        fluid
        mb="2.5em"
        required
      />

      {error
        && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Next"
        disabled={!isValid}
        fluid
        isLoading={isLoading}
      />
    </form>
  </Fragment>,
          writerDetail:
  <Fragment>
    <Header mb="1em">Your Writer Details</Header>
    {!isLoadingProfile && (
    <form id="writerDetailForm" onSubmit={handleSubmit2(handleSaveWriterDetail)} autoComplete="off">
      {AppState.aliases.value.length > 0
      && (
        <Fragment>
          <InputLabel label="Use a Persona (Optional)" />
          <BaseSelect
            {...register2('persona', { required: false, valueAsNumber: true })}
            id="persona"
            name="persona"
            fluid
            mb="1.5rem"
          >
            <option value={null} default selected>
              None (
              {AppState.userProfile.firstName}
              {' '}
              {AppState.userProfile.lastName}
              )
            </option>
            {AppState.aliases.value.map((a) => (
              <option value={a.id}>{a.name}</option>
            ))}
          </BaseSelect>
        </Fragment>
      )}
      <InputLabel label="Primary Contribution" />
      <BaseSelect
        {...register2('contribution', { required: false })}
        fluid
        mb="0.25em"
      >
        <option value="Music and Lyrics" default selected>Music and Lyrics</option>
        <option value="Lyrics">Lyrics</option>
        <option value="Music">Music</option>
        <option value="Arrangement">Arrangement</option>
        <option value="Translation">Translation</option>
      </BaseSelect>
      <InputLabel mb="1.5em" color="var(--text-medium-mute)" label="Production / Beats is Considered Music" />

      <InputLabel label="Ownership Share" />
      <Box display="flex" mb="0.25em">
        <StyledPercentageInput
          {...register2('share', { required: false, valueAsNumber: true })}
          id="share"
          name="share"
          placeholder={0.00}
          type="number"
          inputMode="decimal"
          step="0.01"
          max={100}
          min={0.01}
        />
        <StyledPercentBox>%</StyledPercentBox>
      </Box>
      <InputLabel mb="4em" color="var(--text-medium-mute)" label="You can review your share before signing the Split Sheet" />

      {error
      && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}
      <BaseButton
        mb="1.5em"
        type="submit"
        btnText="Next"
        disabled={!isValid2}
        fluid
        isLoading={isLoading}
      />
    </form>
    )}
  </Fragment>,
        }[section]
      }
    </FullScreenModal>
  );
}

export default CreateComposition;
