import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledCreditItem = styled.div`
    display: flex;
    border: 1px solid var(--border);
    border-radius: 0.5rem;
    align-items: center;
    padding: 8px 10px 8px 10px;
    margin-bottom: 0.75rem;
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? '0.75' : '1')};
`;
