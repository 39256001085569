import styled from 'styled-components';
import pointBreaks from '@styles/styleConstants';

export const PlayerContainer = styled.div(({
  isActive,
}) => `
    background: var(--player-bg);
    border: 1px solid var(--player-border);
    padding: 0.875em;
    height: 5.25rem;
    overflow: hidden;
    border-radius: 8px 8px 0px 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    max-width: 1770px;
    margin: 0 auto;
    transform: translateY(${isActive ? '0px' : '130px'});
    transition: all 500ms cubic-bezier(0.14, 0.84, 0.75, 0.97) 0ms;

    @media screen and (max-width: ${pointBreaks.sm}) {
       height: 9.5rem;
       border-top: 1px solid var(--border);
       border-radius: 0px;
       transform: translateY(${isActive ? '0px' : '160px'});
    }
`);

export const StyledPlayerContentRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.875rem;

  @media screen and (max-width: ${pointBreaks.sm}) {
    flex-direction: column;
    position: relative;
  }
`;

export const StyledTrackInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;
  height: 56px;
  padding-right: 0.875rem;
  max-width: 300px;
  padding: 0.25rem;

  @media screen and (max-width: ${pointBreaks.sm}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledWaveAreaWrapper = styled.div`
  position: relative;
  background: var(--player-panel-bg);
  height: 56px;
  overflow: hidden;
  flex-grow: 1;

  @media screen and (max-width: ${pointBreaks.sm}) {
    width: 100%;
  }
`;

export const StyledWaveFormWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 24px;
  left: 8px;
`;

export const StyledTimeWrapper = styled.div`
  display: flex;
  align-items: center;

   @media screen and (max-width: ${pointBreaks.sm}) {
    display: none;
  }
`;

export const StyledPlayerIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: var(--player-panel-bg);
  border-radius: 4px;
  align-self: center;

  @media screen and (max-width: ${pointBreaks.sm}) {
    position: absolute;
    top: 12px;
    right: 8px;
  }
`;

export const StyledErrorPanel = styled.div`
    background: #1d1f27;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0px 24px;
`;
